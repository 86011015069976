/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.');
      self.addEventListener('message', function(evt) {
        console.log('Service worker received message:', evt.data);
      });
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      const s = (self as any);
      if (s.skipWaiting) {
        s.skipWaiting();
      }
      if (navigator && navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((x) => x.unregister())
        .then(function(x) {
         console.log(x ? 'Desactivado con éxito' : 'Falló el refresco');
         if (x) location.reload();
        }) ;
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
