<template>
  <div class="userContainer" v-if="isLoggedIn" :class="{ bottom }">
    <div class="imagen">
      <img :src="userData.image || 'assets/images/PersonaSinFoto.svg'">
    </div>
    <div class="datos">
      <div class="bold">
        {{ userData.fullname }}
      </div>
      <div v-if="userData.cargo!=''">
        {{ userData.cargo }}
      </div>
      <div v-if="ambito!=''">
        {{ ambito }}
      </div>
    </div>
    <div v-if="withLogout" class="logout">
      <ion-button @click="logout" color="medium">
        CERRAR SESIÓN
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts">
import { IonButton } from '@ionic/vue';
import { defineComponent } from "vue";
import store from "@/modules/adaptedStorage";
import { territorios } from '@/modules/cyanRegions';
import logout from '@/modules/cyanLogout';

export default defineComponent({
  name: "UserData",
  components: {
    IonButton
  },
  computed: {
    isLoggedIn() {
      return store.state.isLoggedIn;
    },
    userData() {
      return store.state.userData;
    },
    ambito() {
      if (this.userData.ambito) return (this as any).userData.ambito;
      const udn = (this as any).userData.nivel;
      return (udn in territorios) ? (territorios as any)[udn].nombre : '---'
    }
  },
  methods: {
    logout
  },
  props: {
    withLogout: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style scoped>
.userContainer {
  align-items: center;
  flex: 0 0 auto;
}
.userContainer.bottom {
  align-items: flex-end;
}
.userContainer .imagen {
  flex: 0 0 auto;
}

.userContainer .imagen img {
  max-height: 130px;
  min-height: 50px;
  border-color: var(--ion-color-tertiary);
  border-radius: 5px;
}

.userContainer .datos {
  padding: 0.5em 0 0.5em 1em;
  flex: 1 1 auto;
  color: var(--ion-color-primary);
}

[data-bocaurna="1"] .userContainer .datos {
  color: var(--azul-alternativo);
}

.userContainer .logout {
  padding: 0 0 0 1em;
}

.bold {
  font-weight: bold;
  font-size: 110%;
}

.userContainer.sideMenu {
  flex-direction: column;
  text-align: center;
}
.userContainer.sideMenu .bold {
  color: var(--cyan-dark-grey);
}
.userContainer.sideMenu .datos {
  padding-right: 1em;
}

.userContainer.sideMenu .imagen img {
  height: 90px;
}

.userContainer.large {
  font-size: 130%;
}

.userContainer.large .imagen img {
  height: 120px;
}

.userContainer.large .datos div {
  padding: 0.1em 0;
}
</style>