interface ElementoMapaCyan {
	codigo: string;
	nombre: string;
	nombrePadre?: string;
}

interface DatosDivision {
	id: number;
	largo: number;
	nombre: string;
	plural: string;
	largoPadre: number | null;
	largoHijos: number | null;
}

const mapa: Record<string, string[]> = {

	SV: [ "SV01", "SV02", "SV03", "SV04", "SV05", "SV06", "SV07", "SV08", "SV09", "SV10", "SV11", "SV12", "SV13", "SV14" ],

	SV01: [ "SV0104", "SV0103", "SV0101", "SV0102", "SV0105" ],
	SV02: [ "SV0202", "SV0203", "SV0201", "SV0204" ],
	SV03: [ "SV0302", "SV0301", "SV0303" ],
	SV04: [ "SV0402", "SV0405", "SV0404", "SV0401", "SV0403", "SV0406" ],
	SV05: [ "SV0502", "SV0501", "SV0503" ],
	SV06: [ "SV0602", "SV0603", "SV0601", "SV0604" ],
	SV07: [ "SV0701", "SV0702" ],
	SV08: [ "SV0802", "SV0803", "SV0801" ],
	SV09: [ "SV0902", "SV0901", "SV0903" ],
	SV10: [ "SV1001", "SV1002" ],
	SV11: [ "SV1101", "SV1103", "SV1102" ],
	SV12: [ "SV1201", "SV1202" ],
	SV13: [ "SV1301", "SV1302" ],
	SV14: [ "SV1401", "SV1402" ],

	SV1101: [ "SV110102", "SV110110", "SV110111", "SV110112" ],
	SV1103: [ "SV110303", "SV110306", "SV110307", "SV110309" ],
	SV1102: [ "SV110201", "SV110204", "SV110205", "SV110208" ],
	SV1401: [ "SV140101", "SV140103", "SV140104", "SV140107", "SV140109" ],
	SV1402: [ "SV140202", "SV140205", "SV140206", "SV140208" ],
	SV0902: [ "SV090202", "SV090204", "SV090205", "SV090208", "SV090210", "SV090215", "SV090220", "SV090221", "SV090225", "SV090231" ],
	SV0901: [ "SV090103", "SV090107", "SV090109" ],
	SV0903: [ "SV090301", "SV090306", "SV090311", "SV090312", "SV090313", "SV090314", "SV090316", "SV090317", "SV090318", "SV090319", "SV090322", "SV090323", "SV090324", "SV090326", "SV090327", "SV090328", "SV090329", "SV090330", "SV090332", "SV090333" ],
	SV1001: [ "SV100102", "SV100103", "SV100104", "SV100112", "SV100115" ],
	SV1002: [ "SV100201", "SV100205", "SV100206", "SV100207", "SV100208", "SV100209", "SV100210", "SV100211", "SV100213", "SV100214", "SV100216" ],
	SV0402: [ "SV040203", "SV040204" ],
	SV0405: [ "SV040506", "SV040513", "SV040514", "SV040516", "SV040522" ],
	SV0404: [ "SV040407", "SV040411", "SV040412", "SV040415", "SV040419" ],
	SV0401: [ "SV040102", "SV040109", "SV040121" ],
	SV0403: [ "SV040305", "SV040310", "SV040317", "SV040318", "SV040320" ],
	SV0406: [ "SV040601", "SV040608" ],
	SV0802: [ "SV080202", "SV080206", "SV080209", "SV080211", "SV080213", "SV080214", "SV080215", "SV080218", "SV080219", "SV080220", "SV080221", "SV080222" ],
	SV0803: [ "SV080301", "SV080303", "SV080310" ],
	SV0801: [ "SV080104", "SV080105", "SV080107", "SV080108", "SV080112", "SV080116", "SV080117" ],
	SV0701: [ "SV070102", "SV070103", "SV070105", "SV070108", "SV070109", "SV070111", "SV070112", "SV070113", "SV070114", "SV070116" ],
	SV0702: [ "SV070201", "SV070204", "SV070206", "SV070207", "SV070210", "SV070215", "SV070217", "SV070218" ],
	SV1201: [ "SV120103", "SV120105", "SV120108", "SV120111", "SV120112", "SV120115", "SV120116", "SV120121", "SV120122", "SV120125", "SV120126" ],
	SV1202: [ "SV120201", "SV120202", "SV120204", "SV120206", "SV120207", "SV120209", "SV120210", "SV120213", "SV120214", "SV120217", "SV120218", "SV120219", "SV120220", "SV120223", "SV120224" ],
	SV0302: [ "SV030201", "SV030205", "SV030208", "SV030214", "SV030218", "SV030219" ],
	SV0301: [ "SV030104", "SV030106", "SV030111", "SV030112", "SV030113", "SV030115", "SV030116", "SV030120" ],
	SV0303: [ "SV030302", "SV030303", "SV030307", "SV030309", "SV030310", "SV030317" ],
	SV0104: [ "SV010402", "SV010405", "SV010401", "SV010403", "SV010418" ],
	SV0103: [ "SV010304", "SV010307", "SV010310", "SV010313" ],
	SV0101: [ "SV010112", "SV010116", "SV010117" ],
	SV0102: [ "SV010208", "SV010209" ],
	SV0105: [ "SV010506", "SV010514", "SV010515", "SV010511", "SV010519" ],
	SV1301: [ "SV130103", "SV130104", "SV130105", "SV130106", "SV130107", "SV130108", "SV130111" ],
	SV1302: [ "SV130201", "SV130202", "SV130209", "SV130210", "SV130212", "SV130213" ],
	SV0202: [ "SV020201" ],
	SV0203: [ "SV020304", "SV020305" ],
	SV0201: [ "SV020103", "SV020106", "SV020109", "SV020112" ],
	SV0204: [ "SV020402", "SV020407", "SV020408", "SV020410", "SV020411", "SV020413" ],
	SV0602: [ "SV060201", "SV060208", "SV060209", "SV060210", "SV060216" ],
	SV0603: [ "SV060302", "SV060304", "SV060307", "SV060311", "SV060313", "SV060314" ],
	SV0601: [ "SV060105", "SV060106", "SV060112", "SV060115" ],
	SV0604: [ "SV060403" ],
	SV0502: [ "SV050201", "SV050206", "SV050207", "SV050209", "SV050213", "SV050216", "SV050217", "SV050218", "SV050219", "SV050223" ],
	SV0501: [ "SV050103", "SV050104", "SV050105", "SV050110", "SV050111", "SV050112", "SV050120", "SV050121", "SV050122" ],
	SV0503: [ "SV050302", "SV050308", "SV050314", "SV050315" ],

	SV010112: [ "SV01011201", "SV01011202", "SV01011203", "SV01011204", "SV01011205", "SV01011206" ],
	SV010116: [ "SV01011601", "SV01011602", "SV01011603", "SV01011604", "SV01011605", "SV01011606", "SV01011607", "SV01011608", "SV01011609", "SV01011610", "SV01011611", "SV01011612" ],
	SV010117: [ "SV01011701", "SV01011702", "SV01011703", "SV01011704", "SV01011705" ],
	SV010208: [ "SV01020801", "SV01020802", "SV01020803", "SV01020804", "SV01020805", "SV01020806", "SV01020807" ],
	SV010209: [ "SV01020901", "SV01020902", "SV01020903", "SV01020904" ],
	SV010402: [ "SV01040201", "SV01040202", "SV01040203", "SV01040204", "SV01040205", "SV01040206", "SV01040207", "SV01040208", "SV01040209", "SV01040210", "SV01040211" ],
	SV010304: [ "SV01030401", "SV01030402", "SV01030403", "SV01030404", "SV01030405", "SV01030406", "SV01030407", "SV01030408", "SV01030409", "SV01030410", "SV01030411", "SV01030412", "SV01030413", "SV01030414", "SV01030415", "SV01030416" ],
	SV010405: [ "SV01040501", "SV01040502", "SV01040503" ],
	SV010307: [ "SV01030701", "SV01030702", "SV01030703", "SV01030704" ],
	SV010310: [ "SV01031001", "SV01031002", "SV01031003", "SV01031004", "SV01031005", "SV01031006", "SV01031007", "SV01031008", "SV01031009", "SV01031010", "SV01031011" ],
	SV010313: [ "SV01031301", "SV01031302", "SV01031303" ],
	SV010401: [ "SV01040101", "SV01040102", "SV01040103", "SV01040104", "SV01040105", "SV01040106", "SV01040107", "SV01040108", "SV01040109", "SV01040110", "SV01040111", "SV01040112", "SV01040113", "SV01040114", "SV01040115", "SV01040116", "SV01040117", "SV01040118", "SV01040119", "SV01040120", "SV01040121" ],
	SV010403: [ "SV01040301", "SV01040302", "SV01040303", "SV01040304", "SV01040305", "SV01040306" ],
	SV010506: [ "SV01050601", "SV01050602", "SV01050603", "SV01050604" ],
	SV010514: [ "SV01051401", "SV01051402", "SV01051403", "SV01051404", "SV01051405", "SV01051406", "SV01051407", "SV01051408" ],
	SV010515: [ "SV01051501", "SV01051502", "SV01051503", "SV01051504", "SV01051505", "SV01051506" ],
	SV010418: [ "SV01041801", "SV01041802", "SV01041803", "SV01041804", "SV01041805", "SV01041806" ],
	SV010511: [ "SV01051101", "SV01051102", "SV01051103", "SV01051104", "SV01051105", "SV01051106" ],
	SV010519: [ "SV01051901", "SV01051902", "SV01051903", "SV01051904", "SV01051905", "SV01051906", "SV01051907", "SV01051908" ],
	SV020103: [ "SV02010301", "SV02010302", "SV02010303", "SV02010304", "SV02010305", "SV02010306", "SV02010307", "SV02010308", "SV02010309", "SV02010310", "SV02010311" ],
	SV020106: [ "SV02010601", "SV02010602", "SV02010603", "SV02010604", "SV02010605", "SV02010606", "SV02010607" ],
	SV020109: [ "SV02010901", "SV02010902", "SV02010903", "SV02010904", "SV02010905" ],
	SV020112: [ "SV02011201", "SV02011202", "SV02011203", "SV02011204" ],
	SV020201: [ "SV02020101", "SV02020102", "SV02020103", "SV02020104", "SV02020105", "SV02020106", "SV02020107", "SV02020108", "SV02020109" ],
	SV020304: [ "SV02030401", "SV02030402", "SV02030403", "SV02030404", "SV02030405", "SV02030406", "SV02030407", "SV02030408" ],
	SV020305: [ "SV02030501", "SV02030502", "SV02030503", "SV02030504", "SV02030505", "SV02030506", "SV02030507" ],
	SV020402: [ "SV02040201", "SV02040202", "SV02040203", "SV02040204", "SV02040205", "SV02040206", "SV02040207", "SV02040208", "SV02040209", "SV02040210", "SV02040211", "SV02040212", "SV02040213", "SV02040214", "SV02040215" ],
	SV020407: [ "SV02040701", "SV02040702", "SV02040703" ],
	SV020408: [ "SV02040801", "SV02040802", "SV02040803", "SV02040804", "SV02040805", "SV02040806", "SV02040807" ],
	SV020410: [ "SV02041001", "SV02041002", "SV02041003" ],
	SV020411: [ "SV02041101", "SV02041102", "SV02041103", "SV02041104" ],
	SV020413: [ "SV02041301", "SV02041302", "SV02041303", "SV02041304" ],
	SV030104: [ "SV03010401", "SV03010402", "SV03010403", "SV03010404", "SV03010405", "SV03010406", "SV03010407", "SV03010408", "SV03010409", "SV03010410", "SV03010411", "SV03010412" ],
	SV030106: [ "SV03010601", "SV03010602", "SV03010603", "SV03010604", "SV03010605", "SV03010606" ],
	SV030111: [ "SV03011101", "SV03011102", "SV03011103", "SV03011104", "SV03011105", "SV03011106", "SV03011107" ],
	SV030112: [ "SV03011201", "SV03011202", "SV03011203", "SV03011204" ],
	SV030113: [ "SV03011301", "SV03011302", "SV03011303" ],
	SV030115: [ "SV03011501", "SV03011502", "SV03011503", "SV03011504", "SV03011505" ],
	SV030116: [ "SV03011601", "SV03011602", "SV03011603", "SV03011604", "SV03011605", "SV03011606", "SV03011607", "SV03011608" ],
	SV030120: [ "SV03012001", "SV03012002", "SV03012003" ],
	SV030201: [ "SV03020101", "SV03020102", "SV03020103", "SV03020104", "SV03020105", "SV03020106", "SV03020107", "SV03020108", "SV03020109", "SV03020110", "SV03020111", "SV03020112", "SV03020113", "SV03020114", "SV03020115", "SV03020116" ],
	SV030205: [ "SV03020501", "SV03020502", "SV03020503", "SV03020504" ],
	SV030208: [ "SV03020801", "SV03020802", "SV03020803" ],
	SV030214: [ "SV03021401", "SV03021402", "SV03021403", "SV03021404", "SV03021405" ],
	SV030218: [ "SV03021801", "SV03021802", "SV03021803", "SV03021804" ],
	SV030219: [ "SV03021901", "SV03021902", "SV03021903" ],
	SV030302: [ "SV03030201", "SV03030202", "SV03030203", "SV03030204", "SV03030205", "SV03030206", "SV03030207", "SV03030208" ],
	SV030303: [ "SV03030301", "SV03030302", "SV03030303", "SV03030304", "SV03030305", "SV03030306", "SV03030307" ],
	SV030307: [ "SV03030701", "SV03030702", "SV03030703", "SV03030704", "SV03030705" ],
	SV030309: [ "SV03030901", "SV03030902", "SV03030903", "SV03030904", "SV03030905" ],
	SV030310: [ "SV03031001", "SV03031002", "SV03031003", "SV03031004", "SV03031005" ],
	SV030317: [ "SV03031701", "SV03031702", "SV03031703" ],
	SV040102: [ "SV04010201", "SV04010202", "SV04010203", "SV04010204", "SV04010205", "SV04010206", "SV04010207", "SV04010208" ],
	SV040109: [ "SV04010901", "SV04010902", "SV04010903", "SV04010904", "SV04010905" ],
	SV040121: [ "SV04012101", "SV04012102", "SV04012103" ],
	SV040203: [ "SV04020301", "SV04020302", "SV04020303", "SV04020304", "SV04020305", "SV04020306", "SV04020307", "SV04020308" ],
	SV040204: [ "SV04020401", "SV04020402", "SV04020403", "SV04020404", "SV04020405", "SV04020406", "SV04020407", "SV04020408", "SV04020409", "SV04020410" ],
	SV040305: [ "SV04030501", "SV04030502", "SV04030503", "SV04030504", "SV04030505", "SV04030506", "SV04030507", "SV04030508", "SV04030509", "SV04030510", "SV04030511", "SV04030512", "SV04030513", "SV04030514", "SV04030515", "SV04030516", "SV04030517", "SV04030518" ],
	SV040310: [ "SV04031001", "SV04031002", "SV04031003", "SV04031004" ],
	SV040317: [ "SV04031701", "SV04031702", "SV04031703", "SV04031704" ],
	SV040318: [ "SV04031801", "SV04031802", "SV04031803" ],
	SV040320: [ "SV04032001", "SV04032002" ],
	SV040407: [ "SV04040701", "SV04040702", "SV04040703", "SV04040704", "SV04040705", "SV04040706", "SV04040707" ],
	SV040411: [ "SV04041101", "SV04041102", "SV04041103", "SV04041104", "SV04041105" ],
	SV040412: [ "SV04041201", "SV04041202", "SV04041203" ],
	SV040415: [ "SV04041501", "SV04041502", "SV04041503" ],
	SV040419: [ "SV04041901", "SV04041902", "SV04041903" ],
	SV040506: [ "SV04050601", "SV04050602", "SV04050603", "SV04050604", "SV04050605", "SV04050606", "SV04050607", "SV04050608" ],
	SV040513: [ "SV04051301", "SV04051302", "SV04051303" ],
	SV040514: [ "SV04051401", "SV04051402", "SV04051403" ],
	SV040516: [ "SV04051601", "SV04051602", "SV04051603", "SV04051604", "SV04051605" ],
	SV040522: [ "SV04052201", "SV04052202", "SV04052203" ],
	SV040601: [ "SV04060101", "SV04060102", "SV04060103", "SV04060104", "SV04060105", "SV04060106", "SV04060107", "SV04060108", "SV04060109", "SV04060110", "SV04060111", "SV04060112", "SV04060113", "SV04060114", "SV04060115", "SV04060116", "SV04060117", "SV04060118" ],
	SV040608: [ "SV04060801", "SV04060802", "SV04060803", "SV04060804", "SV04060805" ],
	SV050103: [ "SV05010301", "SV05010302", "SV05010303", "SV05010304", "SV05010305", "SV05010306" ],
	SV050104: [ "SV05010401", "SV05010402", "SV05010403", "SV05010404", "SV05010405" ],
	SV050105: [ "SV05010501", "SV05010502", "SV05010503", "SV05010504" ],
	SV050110: [ "SV05011001", "SV05011002", "SV05011003", "SV05011004", "SV05011005" ],
	SV050111: [ "SV05011101", "SV05011102", "SV05011103", "SV05011104", "SV05011105" ],
	SV050112: [ "SV05011201", "SV05011202", "SV05011203", "SV05011204", "SV05011205" ],
	SV050120: [ "SV05012001", "SV05012002", "SV05012003", "SV05012004", "SV05012005" ],
	SV050121: [ "SV05012101", "SV05012102", "SV05012103" ],
	SV050122: [ "SV05012201", "SV05012202", "SV05012203", "SV05012204" ],
	SV050201: [ "SV05020101", "SV05020102", "SV05020103", "SV05020104", "SV05020105", "SV05020106", "SV05020107", "SV05020108" ],
	SV050206: [ "SV05020601", "SV05020602", "SV05020603", "SV05020604", "SV05020605", "SV05020606", "SV05020607" ],
	SV050207: [ "SV05020701", "SV05020702", "SV05020703", "SV05020704", "SV05020705" ],
	SV050209: [ "SV05020901", "SV05020902", "SV05020903", "SV05020904", "SV05020905", "SV05020906" ],
	SV050213: [ "SV05021301", "SV05021302", "SV05021303", "SV05021304", "SV05021305", "SV05021306" ],
	SV050216: [ "SV05021601", "SV05021602" ],
	SV050217: [ "SV05021701", "SV05021702", "SV05021703" ],
	SV050218: [ "SV05021801", "SV05021802", "SV05021803" ],
	SV050219: [ "SV05021901", "SV05021902", "SV05021903", "SV05021904" ],
	SV050223: [ "SV05022301", "SV05022302", "SV05022303" ],
	SV050302: [ "SV05030201", "SV05030202", "SV05030203", "SV05030204", "SV05030205", "SV05030206", "SV05030207", "SV05030208", "SV05030209", "SV05030210", "SV05030211", "SV05030212", "SV05030213", "SV05030214" ],
	SV050308: [ "SV05030801", "SV05030802" ],
	SV050314: [ "SV05031401", "SV05031402", "SV05031403", "SV05031404" ],
	SV050315: [ "SV05031501", "SV05031502", "SV05031503", "SV05031504" ],
	SV060105: [ "SV06010501", "SV06010502", "SV06010503", "SV06010504" ],
	SV060106: [ "SV06010601", "SV06010602", "SV06010603", "SV06010604", "SV06010605" ],
	SV060112: [ "SV06011201", "SV06011202", "SV06011203" ],
	SV060115: [ "SV06011501", "SV06011502" ],
	SV060201: [ "SV06020101", "SV06020102", "SV06020103", "SV06020104", "SV06020105", "SV06020106", "SV06020107" ],
	SV060208: [ "SV06020801", "SV06020802", "SV06020803", "SV06020804" ],
	SV060209: [ "SV06020901", "SV06020902", "SV06020903" ],
	SV060210: [ "SV06021001", "SV06021002", "SV06021003", "SV06021004", "SV06021005" ],
	SV060216: [ "SV06021601", "SV06021602", "SV06021603" ],
	SV060302: [ "SV06030201", "SV06030202", "SV06030203", "SV06030204", "SV06030205" ],
	SV060304: [ "SV06030401", "SV06030402", "SV06030403", "SV06030404" ],
	SV060307: [ "SV06030701", "SV06030702", "SV06030703", "SV06030704" ],
	SV060311: [ "SV06031101", "SV06031102", "SV06031103", "SV06031104" ],
	SV060313: [ "SV06031301", "SV06031302", "SV06031303", "SV06031304" ],
	SV060314: [ "SV06031401", "SV06031402", "SV06031403" ],
	SV060403: [ "SV06040301", "SV06040302", "SV06040303", "SV06040304", "SV06040305", "SV06040306", "SV06040307", "SV06040308", "SV06040309", "SV06040310" ],
	SV070102: [ "SV07010201", "SV07010202", "SV07010203", "SV07010204", "SV07010205", "SV07010206", "SV07010207", "SV07010208", "SV07010209" ],
	SV070103: [ "SV07010301", "SV07010302", "SV07010303", "SV07010304", "SV07010305", "SV07010306", "SV07010307", "SV07010308", "SV07010309" ],
	SV070105: [ "SV07010501", "SV07010502", "SV07010503", "SV07010504", "SV07010505", "SV07010506", "SV07010507" ],
	SV070108: [ "SV07010801", "SV07010802", "SV07010803", "SV07010804", "SV07010805" ],
	SV070109: [ "SV07010901", "SV07010902", "SV07010903", "SV07010904", "SV07010905" ],
	SV070111: [ "SV07011101", "SV07011102", "SV07011103", "SV07011104", "SV07011105" ],
	SV070112: [ "SV07011201", "SV07011202", "SV07011203" ],
	SV070113: [ "SV07011301", "SV07011302", "SV07011303" ],
	SV070114: [ "SV07011401", "SV07011402", "SV07011403", "SV07011404", "SV07011405" ],
	SV070116: [ "SV07011601" ],
	SV070201: [ "SV07020101", "SV07020102", "SV07020103", "SV07020104", "SV07020105", "SV07020106", "SV07020107", "SV07020108", "SV07020109", "SV07020110", "SV07020111", "SV07020112", "SV07020113", "SV07020114", "SV07020115" ],
	SV070204: [ "SV07020401", "SV07020402", "SV07020403", "SV07020404", "SV07020405", "SV07020406", "SV07020407", "SV07020408", "SV07020409" ],
	SV070206: [ "SV07020601", "SV07020602", "SV07020603", "SV07020604", "SV07020605", "SV07020606" ],
	SV070207: [ "SV07020701", "SV07020702", "SV07020703", "SV07020704", "SV07020705", "SV07020706", "SV07020707", "SV07020708", "SV07020709", "SV07020710", "SV07020711" ],
	SV070210: [ "SV07021001", "SV07021002", "SV07021003" ],
	SV070215: [ "SV07021501", "SV07021502", "SV07021503" ],
	SV070217: [ "SV07021701", "SV07021702", "SV07021703" ],
	SV070218: [ "SV07021801", "SV07021802", "SV07021803" ],
	SV080104: [ "SV08010401", "SV08010402", "SV08010403", "SV08010404", "SV08010405", "SV08010406" ],
	SV080105: [ "SV08010501", "SV08010502", "SV08010503", "SV08010504", "SV08010505", "SV08010506" ],
	SV080107: [ "SV08010701", "SV08010702", "SV08010703", "SV08010704", "SV08010705", "SV08010706" ],
	SV080108: [ "SV08010801", "SV08010802", "SV08010803", "SV08010804", "SV08010805" ],
	SV080112: [ "SV08011201", "SV08011202", "SV08011203", "SV08011204", "SV08011205", "SV08011206" ],
	SV080116: [ "SV08011601", "SV08011602", "SV08011603" ],
	SV080117: [ "SV08011701", "SV08011702", "SV08011703", "SV08011704", "SV08011705" ],
	SV080202: [ "SV08020201", "SV08020202", "SV08020203", "SV08020204", "SV08020205", "SV08020206", "SV08020207" ],
	SV080206: [ "SV08020601", "SV08020602", "SV08020603", "SV08020604" ],
	SV080209: [ "SV08020901", "SV08020902", "SV08020903", "SV08020904", "SV08020905" ],
	SV080211: [ "SV08021101", "SV08021102", "SV08021103" ],
	SV080213: [ "SV08021301", "SV08021302", "SV08021303", "SV08021304" ],
	SV080214: [ "SV08021401", "SV08021402", "SV08021403" ],
	SV080215: [ "SV08021501", "SV08021502", "SV08021503", "SV08021504" ],
	SV080218: [ "SV08021801", "SV08021802", "SV08021803", "SV08021804" ],
	SV080219: [ "SV08021901", "SV08021902", "SV08021903" ],
	SV080220: [ "SV08022001", "SV08022002", "SV08022003" ],
	SV080221: [ "SV08022101", "SV08022102" ],
	SV080222: [ "SV08022201", "SV08022202", "SV08022203", "SV08022204", "SV08022205", "SV08022206", "SV08022207", "SV08022208", "SV08022209" ],
	SV080301: [ "SV08030101", "SV08030102", "SV08030103", "SV08030104", "SV08030105", "SV08030106", "SV08030107", "SV08030108", "SV08030109" ],
	SV080303: [ "SV08030301", "SV08030302", "SV08030303", "SV08030304", "SV08030305", "SV08030306" ],
	SV080310: [ "SV08031001", "SV08031002", "SV08031003", "SV08031004" ],
	SV090103: [ "SV09010301", "SV09010302", "SV09010303", "SV09010304", "SV09010305", "SV09010306", "SV09010307", "SV09010308" ],
	SV090107: [ "SV09010701", "SV09010702", "SV09010703", "SV09010704", "SV09010705", "SV09010706" ],
	SV090109: [ "SV09010901", "SV09010902", "SV09010903" ],
	SV090202: [ "SV09020201", "SV09020202", "SV09020203", "SV09020204", "SV09020205", "SV09020206", "SV09020207", "SV09020208", "SV09020209", "SV09020210" ],
	SV090204: [ "SV09020401", "SV09020402", "SV09020403", "SV09020404", "SV09020405", "SV09020406", "SV09020407", "SV09020408" ],
	SV090205: [ "SV09020501", "SV09020502", "SV09020503" ],
	SV090208: [ "SV09020801", "SV09020802" ],
	SV090210: [ "SV09021001", "SV09021002", "SV09021003", "SV09021004", "SV09021005", "SV09021006" ],
	SV090215: [ "SV09021501", "SV09021502", "SV09021503" ],
	SV090220: [ "SV09022001", "SV09022002" ],
	SV090221: [ "SV09022101", "SV09022102" ],
	SV090225: [ "SV09022501", "SV09022502", "SV09022503" ],
	SV090231: [ "SV09023101", "SV09023102", "SV09023103", "SV09023104" ],
	SV090301: [ "SV09030101", "SV09030102", "SV09030103", "SV09030104", "SV09030105", "SV09030106", "SV09030107", "SV09030108", "SV09030109" ],
	SV090306: [ "SV09030601", "SV09030602", "SV09030603" ],
	SV090311: [ "SV09031101", "SV09031102", "SV09031103" ],
	SV090312: [ "SV09031201" ],
	SV090313: [ "SV09031301" ],
	SV090314: [ "SV09031401", "SV09031402", "SV09031403" ],
	SV090316: [ "SV09031601", "SV09031602" ],
	SV090317: [ "SV09031701", "SV09031702" ],
	SV090318: [ "SV09031801", "SV09031802", "SV09031803" ],
	SV090319: [ "SV09031901" ],
	SV090322: [ "SV09032201", "SV09032202", "SV09032203" ],
	SV090323: [ "SV09032301", "SV09032302" ],
	SV090324: [ "SV09032401", "SV09032402" ],
	SV090326: [ "SV09032601", "SV09032602", "SV09032603" ],
	SV090327: [ "SV09032701" ],
	SV090328: [ "SV09032801" ],
	SV090329: [ "SV09032901" ],
	SV090330: [ "SV09033001", "SV09033002", "SV09033003" ],
	SV090332: [ "SV09033201", "SV09033202" ],
	SV090333: [ "SV09033301", "SV09033302" ],
	SV100102: [ "SV10010201", "SV10010202", "SV10010203", "SV10010204", "SV10010205", "SV10010206", "SV10010207", "SV10010208", "SV10010209" ],
	SV100103: [ "SV10010301", "SV10010302", "SV10010303", "SV10010304", "SV10010305" ],
	SV100104: [ "SV10010401", "SV10010402", "SV10010403", "SV10010404", "SV10010405", "SV10010406" ],
	SV100112: [ "SV10011201", "SV10011202", "SV10011203" ],
	SV100115: [ "SV10011501", "SV10011502", "SV10011503" ],
	SV100201: [ "SV10020101", "SV10020102", "SV10020103", "SV10020104", "SV10020105", "SV10020106", "SV10020107" ],
	SV100205: [ "SV10020501", "SV10020502", "SV10020503" ],
	SV100206: [ "SV10020601", "SV10020602", "SV10020603", "SV10020604", "SV10020605", "SV10020606", "SV10020607" ],
	SV100207: [ "SV10020701", "SV10020702", "SV10020703", "SV10020704" ],
	SV100208: [ "SV10020801", "SV10020802", "SV10020803", "SV10020804" ],
	SV100209: [ "SV10020901", "SV10020902", "SV10020903", "SV10020904", "SV10020905" ],
	SV100210: [ "SV10021001", "SV10021002", "SV10021003", "SV10021004", "SV10021005" ],
	SV100211: [ "SV10021101", "SV10021102", "SV10021103", "SV10021104", "SV10021105", "SV10021106", "SV10021107" ],
	SV100213: [ "SV10021301", "SV10021302", "SV10021303", "SV10021304", "SV10021305" ],
	SV100214: [ "SV10021401", "SV10021402", "SV10021403" ],
	SV100216: [ "SV10021601", "SV10021602", "SV10021603" ],
	SV110102: [ "SV11010201", "SV11010202", "SV11010203", "SV11010204", "SV11010205", "SV11010206" ],
	SV110110: [ "SV11011001", "SV11011002", "SV11011003", "SV11011004", "SV11011005", "SV11011006", "SV11011007" ],
	SV110111: [ "SV11011101", "SV11011102", "SV11011103" ],
	SV110112: [ "SV11011201", "SV11011202", "SV11011203", "SV11011204" ],
	SV110201: [ "SV11020101", "SV11020102", "SV11020103", "SV11020104", "SV11020105", "SV11020106", "SV11020107", "SV11020108", "SV11020109", "SV11020110", "SV11020111", "SV11020112", "SV11020113", "SV11020114", "SV11020115", "SV11020116", "SV11020117", "SV11020118", "SV11020119", "SV11020120", "SV11020121", "SV11020122", "SV11020123", "SV11020124", "SV11020125", "SV11020126", "SV11020127", "SV11020128", "SV11020129", "SV11020130" ],
	SV110204: [ "SV11020401", "SV11020402", "SV11020403", "SV11020404", "SV11020405", "SV11020406", "SV11020407", "SV11020408" ],
	SV110205: [ "SV11020501", "SV11020502", "SV11020503", "SV11020504" ],
	SV110208: [ "SV11020801", "SV11020802", "SV11020803", "SV11020804", "SV11020805" ],
	SV110303: [ "SV11030301", "SV11030302", "SV11030303", "SV11030304", "SV11030305", "SV11030306", "SV11030307", "SV11030308", "SV11030309" ],
	SV110306: [ "SV11030601", "SV11030602", "SV11030603", "SV11030604", "SV11030605", "SV11030606", "SV11030607", "SV11030608" ],
	SV110307: [ "SV11030701", "SV11030702", "SV11030703", "SV11030704", "SV11030705", "SV11030706" ],
	SV110309: [ "SV11030901", "SV11030902", "SV11030903", "SV11030904" ],
	SV120103: [ "SV12010301", "SV12010302", "SV12010303", "SV12010304", "SV12010305", "SV12010306", "SV12010307" ],
	SV120105: [ "SV12010501", "SV12010502", "SV12010503", "SV12010504", "SV12010505", "SV12010506", "SV12010507", "SV12010508", "SV12010509", "SV12010510" ],
	SV120108: [ "SV12010801", "SV12010802", "SV12010803", "SV12010804", "SV12010805", "SV12010806" ],
	SV120111: [ "SV12011101", "SV12011102", "SV12011103", "SV12011104" ],
	SV120112: [ "SV12011201", "SV12011202", "SV12011203", "SV12011204", "SV12011205" ],
	SV120115: [ "SV12011501", "SV12011502", "SV12011503", "SV12011504" ],
	SV120116: [ "SV12011601", "SV12011602" ],
	SV120121: [ "SV12012101", "SV12012102" ],
	SV120122: [ "SV12012201", "SV12012202" ],
	SV120125: [ "SV12012501", "SV12012502" ],
	SV120126: [ "SV12012601", "SV12012602", "SV12012603" ],
	SV120201: [ "SV12020101", "SV12020102", "SV12020103", "SV12020104", "SV12020105", "SV12020106", "SV12020107" ],
	SV120202: [ "SV12020201", "SV12020202", "SV12020203", "SV12020204", "SV12020205", "SV12020206", "SV12020207", "SV12020208" ],
	SV120204: [ "SV12020401", "SV12020402", "SV12020403", "SV12020404", "SV12020405", "SV12020406", "SV12020407", "SV12020408", "SV12020409" ],
	SV120206: [ "SV12020601", "SV12020602", "SV12020603", "SV12020604", "SV12020605", "SV12020606" ],
	SV120207: [ "SV12020701", "SV12020702", "SV12020703", "SV12020704", "SV12020705", "SV12020706", "SV12020707", "SV12020708" ],
	SV120209: [ "SV12020901", "SV12020902", "SV12020903", "SV12020904", "SV12020905", "SV12020906", "SV12020907" ],
	SV120210: [ "SV12021001", "SV12021002", "SV12021003", "SV12021004", "SV12021005" ],
	SV120213: [ "SV12021301", "SV12021302", "SV12021303", "SV12021304" ],
	SV120214: [ "SV12021401", "SV12021402", "SV12021403" ],
	SV120217: [ "SV12021701", "SV12021702", "SV12021703", "SV12021704", "SV12021705", "SV12021706" ],
	SV120218: [ "SV12021801", "SV12021802", "SV12021803" ],
	SV120219: [ "SV12021901", "SV12021902" ],
	SV120220: [ "SV12022001", "SV12022002", "SV12022003", "SV12022004", "SV12022005" ],
	SV120223: [ "SV12022301", "SV12022302", "SV12022303" ],
	SV120224: [ "SV12022401", "SV12022402", "SV12022403" ],
	SV130103: [ "SV13010301", "SV13010302", "SV13010303", "SV13010304", "SV13010305" ],
	SV130104: [ "SV13010401", "SV13010402", "SV13010403", "SV13010404", "SV13010405", "SV13010406", "SV13010407", "SV13010408", "SV13010409" ],
	SV130105: [ "SV13010501", "SV13010502", "SV13010503" ],
	SV130106: [ "SV13010601", "SV13010602", "SV13010603", "SV13010604", "SV13010605" ],
	SV130107: [ "SV13010701", "SV13010702", "SV13010703", "SV13010704", "SV13010705", "SV13010706", "SV13010707" ],
	SV130108: [ "SV13010801", "SV13010802", "SV13010803", "SV13010804" ],
	SV130111: [ "SV13011101", "SV13011102", "SV13011103", "SV13011104", "SV13011105" ],
	SV130201: [ "SV13020101", "SV13020102", "SV13020103", "SV13020104", "SV13020105", "SV13020106", "SV13020107" ],
	SV130202: [ "SV13020201", "SV13020202", "SV13020203", "SV13020204", "SV13020205", "SV13020206", "SV13020207", "SV13020208", "SV13020209", "SV13020210" ],
	SV130209: [ "SV13020901", "SV13020902", "SV13020903" ],
	SV130210: [ "SV13021001", "SV13021002", "SV13021003", "SV13021004", "SV13021005" ],
	SV130212: [ "SV13021201", "SV13021202" ],
	SV130213: [ "SV13021301", "SV13021302", "SV13021303", "SV13021304" ],
	SV140101: [ "SV14010101", "SV14010102", "SV14010103", "SV14010104", "SV14010105", "SV14010106", "SV14010107", "SV14010108", "SV14010109" ],
	SV140103: [ "SV14010301", "SV14010302", "SV14010303", "SV14010304", "SV14010305" ],
	SV140104: [ "SV14010401", "SV14010402", "SV14010403" ],
	SV140107: [ "SV14010701", "SV14010702", "SV14010703", "SV14010704" ],
	SV140109: [ "SV14010901", "SV14010902", "SV14010903" ],
	SV140202: [ "SV14020201", "SV14020202", "SV14020203", "SV14020204", "SV14020205", "SV14020206", "SV14020207", "SV14020208", "SV14020209", "SV14020210" ],
	SV140205: [ "SV14020501", "SV14020502", "SV14020503", "SV14020504" ],
	SV140206: [ "SV14020601", "SV14020602" ],
	SV140208: [ "SV14020801" ],

};

const territorios: Record<string, ElementoMapaCyan> = {
	SV: {"codigo":"SV","nombre":"El Salvador","nombrePadre":""},
	SV01: {"codigo":"SV01","nombre":"San Salvador","nombrePadre":"El Salvador"},
	SV02: {"codigo":"SV02","nombre":"Santa Ana","nombrePadre":"El Salvador"},
	SV03: {"codigo":"SV03","nombre":"San Miguel","nombrePadre":"El Salvador"},
	SV04: {"codigo":"SV04","nombre":"La Libertad","nombrePadre":"El Salvador"},
	SV05: {"codigo":"SV05","nombre":"Usulut\u00e1n","nombrePadre":"El Salvador"},
	SV06: {"codigo":"SV06","nombre":"Sonsonate","nombrePadre":"El Salvador"},
	SV07: {"codigo":"SV07","nombre":"La Uni\u00f3n","nombrePadre":"El Salvador"},
	SV08: {"codigo":"SV08","nombre":"La Paz","nombrePadre":"El Salvador"},
	SV09: {"codigo":"SV09","nombre":"Chalatenango","nombrePadre":"El Salvador"},
	SV10: {"codigo":"SV10","nombre":"Cuscatl\u00e1n","nombrePadre":"El Salvador"},
	SV11: {"codigo":"SV11","nombre":"Ahuachap\u00e1n","nombrePadre":"El Salvador"},
	SV12: {"codigo":"SV12","nombre":"Moraz\u00e1n","nombrePadre":"El Salvador"},
	SV13: {"codigo":"SV13","nombre":"San Vicente","nombrePadre":"El Salvador"},
	SV14: {"codigo":"SV14","nombre":"Caba\u00f1as","nombrePadre":"El Salvador"},
	SV1101: {"codigo":"SV1101","nombre":"Ahuachap\u00e1n Norte","nombrePadre":"Ahuachap\u00e1n"},
	SV1103: {"codigo":"SV1103","nombre":"Ahuachap\u00e1n Sur","nombrePadre":"Ahuachap\u00e1n"},
	SV1102: {"codigo":"SV1102","nombre":"Ahucahap\u00e1n Centro","nombrePadre":"Ahuachap\u00e1n"},
	SV1401: {"codigo":"SV1401","nombre":"Caba\u00f1as Este","nombrePadre":"Caba\u00f1as"},
	SV1402: {"codigo":"SV1402","nombre":"Caba\u00f1as Oeste","nombrePadre":"Caba\u00f1as"},
	SV0902: {"codigo":"SV0902","nombre":"Chalatenango Centro","nombrePadre":"Chalatenango"},
	SV0901: {"codigo":"SV0901","nombre":"Chalatenango Norte","nombrePadre":"Chalatenango"},
	SV0903: {"codigo":"SV0903","nombre":"Chalatenango Sur","nombrePadre":"Chalatenango"},
	SV1001: {"codigo":"SV1001","nombre":"Cuscatl\u00e1n Norte","nombrePadre":"Cuscatl\u00e1n"},
	SV1002: {"codigo":"SV1002","nombre":"Cuscatl\u00e1n Sur","nombrePadre":"Cuscatl\u00e1n"},
	SV0402: {"codigo":"SV0402","nombre":"La Libertad Centro","nombrePadre":"La Libertad"},
	SV0405: {"codigo":"SV0405","nombre":"La Libertad Costa","nombrePadre":"La Libertad"},
	SV0404: {"codigo":"SV0404","nombre":"La Libertad Este","nombrePadre":"La Libertad"},
	SV0401: {"codigo":"SV0401","nombre":"La Libertad Norte","nombrePadre":"La Libertad"},
	SV0403: {"codigo":"SV0403","nombre":"La Libertad Oeste","nombrePadre":"La Libertad"},
	SV0406: {"codigo":"SV0406","nombre":"La Libertad Sur","nombrePadre":"La Libertad"},
	SV0802: {"codigo":"SV0802","nombre":"La Paz Centro","nombrePadre":"La Paz"},
	SV0803: {"codigo":"SV0803","nombre":"La Paz Este","nombrePadre":"La Paz"},
	SV0801: {"codigo":"SV0801","nombre":"La Paz Oeste","nombrePadre":"La Paz"},
	SV0701: {"codigo":"SV0701","nombre":"La Uni\u00f3n Norte","nombrePadre":"La Uni\u00f3n"},
	SV0702: {"codigo":"SV0702","nombre":"La Uni\u00f3n Sur","nombrePadre":"La Uni\u00f3n"},
	SV1201: {"codigo":"SV1201","nombre":"Moraz\u00e1n Norte","nombrePadre":"Moraz\u00e1n"},
	SV1202: {"codigo":"SV1202","nombre":"Moraz\u00e1n Sur","nombrePadre":"Moraz\u00e1n"},
	SV0302: {"codigo":"SV0302","nombre":"San Miguel Centro","nombrePadre":"San Miguel"},
	SV0301: {"codigo":"SV0301","nombre":"San Miguel Norte","nombrePadre":"San Miguel"},
	SV0303: {"codigo":"SV0303","nombre":"San Miguel Oeste","nombrePadre":"San Miguel"},
	SV0104: {"codigo":"SV0104","nombre":"San Salvador Centro","nombrePadre":"San Salvador"},
	SV0103: {"codigo":"SV0103","nombre":"San Salvador Este","nombrePadre":"San Salvador"},
	SV0101: {"codigo":"SV0101","nombre":"San Salvador Norte","nombrePadre":"San Salvador"},
	SV0102: {"codigo":"SV0102","nombre":"San Salvador Oeste","nombrePadre":"San Salvador"},
	SV0105: {"codigo":"SV0105","nombre":"San Salvador Sur","nombrePadre":"San Salvador"},
	SV1301: {"codigo":"SV1301","nombre":"San Vicente Norte","nombrePadre":"San Vicente"},
	SV1302: {"codigo":"SV1302","nombre":"San Vicente Sur","nombrePadre":"San Vicente"},
	SV0202: {"codigo":"SV0202","nombre":"Santa Ana Centro","nombrePadre":"Santa Ana"},
	SV0203: {"codigo":"SV0203","nombre":"Santa Ana Este","nombrePadre":"Santa Ana"},
	SV0201: {"codigo":"SV0201","nombre":"Santa Ana Norte","nombrePadre":"Santa Ana"},
	SV0204: {"codigo":"SV0204","nombre":"Santa Ana Oeste","nombrePadre":"Santa Ana"},
	SV0602: {"codigo":"SV0602","nombre":"Sonsonate Centro","nombrePadre":"Sonsonate"},
	SV0603: {"codigo":"SV0603","nombre":"Sonsonate Este","nombrePadre":"Sonsonate"},
	SV0601: {"codigo":"SV0601","nombre":"Sonsonate Norte","nombrePadre":"Sonsonate"},
	SV0604: {"codigo":"SV0604","nombre":"Sonsonate Oeste","nombrePadre":"Sonsonate"},
	SV0502: {"codigo":"SV0502","nombre":"Usulut\u00e1n Este","nombrePadre":"Usulut\u00e1n"},
	SV0501: {"codigo":"SV0501","nombre":"Usulut\u00e1n Norte","nombrePadre":"Usulut\u00e1n"},
	SV0503: {"codigo":"SV0503","nombre":"Usulut\u00e1n Oeste","nombrePadre":"Usulut\u00e1n"},
	SV100209: {"codigo":"SV100209","nombre":"Monte San Juan","nombrePadre":"Cuscatl\u00e1n"},
	SV100210: {"codigo":"SV100210","nombre":"San Crist\u00f3bal","nombrePadre":"Cuscatl\u00e1n"},
	SV100211: {"codigo":"SV100211","nombre":"Santa Cruz Michapa","nombrePadre":"Cuscatl\u00e1n"},
	SV100213: {"codigo":"SV100213","nombre":"San Ram\u00f3n","nombrePadre":"Cuscatl\u00e1n"},
	SV100214: {"codigo":"SV100214","nombre":"El Rosario","nombrePadre":"Cuscatl\u00e1n"},
	SV100216: {"codigo":"SV100216","nombre":"Santa Cruz Analquito","nombrePadre":"Cuscatl\u00e1n"},
	SV110102: {"codigo":"SV110102","nombre":"Atiquizaya","nombrePadre":"Ahuachap\u00e1n"},
	SV110110: {"codigo":"SV110110","nombre":"San Lorenzo","nombrePadre":"Ahuachap\u00e1n"},
	SV110111: {"codigo":"SV110111","nombre":"Tur\u00edn","nombrePadre":"Ahuachap\u00e1n"},
	SV110112: {"codigo":"SV110112","nombre":"El Refugio","nombrePadre":"Ahuachap\u00e1n"},
	SV110201: {"codigo":"SV110201","nombre":"Ahuachap\u00e1n","nombrePadre":"Ahuachap\u00e1n"},
	SV110204: {"codigo":"SV110204","nombre":"Tacuba","nombrePadre":"Ahuachap\u00e1n"},
	SV110205: {"codigo":"SV110205","nombre":"Concepci\u00f3n de Ataco","nombrePadre":"Ahuachap\u00e1n"},
	SV110208: {"codigo":"SV110208","nombre":"Apaneca","nombrePadre":"Ahuachap\u00e1n"},
	SV110303: {"codigo":"SV110303","nombre":"San Francisco Men\u00e9ndez","nombrePadre":"Ahuachap\u00e1n"},
	SV110306: {"codigo":"SV110306","nombre":"Jujutla","nombrePadre":"Ahuachap\u00e1n"},
	SV110307: {"codigo":"SV110307","nombre":"Guaymango","nombrePadre":"Ahuachap\u00e1n"},
	SV110309: {"codigo":"SV110309","nombre":"San Pedro Puxtla","nombrePadre":"Ahuachap\u00e1n"},
	SV120103: {"codigo":"SV120103","nombre":"Corinto","nombrePadre":"Moraz\u00e1n"},
	SV120105: {"codigo":"SV120105","nombre":"Cacaopera","nombrePadre":"Moraz\u00e1n"},
	SV120108: {"codigo":"SV120108","nombre":"Jocoaitique","nombrePadre":"Moraz\u00e1n"},
	SV120111: {"codigo":"SV120111","nombre":"Meanguera","nombrePadre":"Moraz\u00e1n"},
	SV120112: {"codigo":"SV120112","nombre":"Torola","nombrePadre":"Moraz\u00e1n"},
	SV120115: {"codigo":"SV120115","nombre":"Joateca","nombrePadre":"Moraz\u00e1n"},
	SV120116: {"codigo":"SV120116","nombre":"Arambala","nombrePadre":"Moraz\u00e1n"},
	SV120121: {"codigo":"SV120121","nombre":"El Rosario","nombrePadre":"Moraz\u00e1n"},
	SV120122: {"codigo":"SV120122","nombre":"Perqu\u00edn","nombrePadre":"Moraz\u00e1n"},
	SV120125: {"codigo":"SV120125","nombre":"San Fernando","nombrePadre":"Moraz\u00e1n"},
	SV120126: {"codigo":"SV120126","nombre":"San Isidro","nombrePadre":"Moraz\u00e1n"},
	SV120201: {"codigo":"SV120201","nombre":"San Francisco Gotera","nombrePadre":"Moraz\u00e1n"},
	SV120202: {"codigo":"SV120202","nombre":"Jocoro","nombrePadre":"Moraz\u00e1n"},
	SV120204: {"codigo":"SV120204","nombre":"Sociedad","nombrePadre":"Moraz\u00e1n"},
	SV120206: {"codigo":"SV120206","nombre":"Guatajiagua","nombrePadre":"Moraz\u00e1n"},
	SV120207: {"codigo":"SV120207","nombre":"El Divisadero","nombrePadre":"Moraz\u00e1n"},
	SV120209: {"codigo":"SV120209","nombre":"Osicala","nombrePadre":"Moraz\u00e1n"},
	SV120210: {"codigo":"SV120210","nombre":"Chilanga","nombrePadre":"Moraz\u00e1n"},
	SV120213: {"codigo":"SV120213","nombre":"San Sim\u00f3n","nombrePadre":"Moraz\u00e1n"},
	SV120214: {"codigo":"SV120214","nombre":"Delicias de Concepci\u00f3n","nombrePadre":"Moraz\u00e1n"},
	SV120217: {"codigo":"SV120217","nombre":"Lolotiquillo","nombrePadre":"Moraz\u00e1n"},
	SV120218: {"codigo":"SV120218","nombre":"Yamabal","nombrePadre":"Moraz\u00e1n"},
	SV120219: {"codigo":"SV120219","nombre":"Yoloaiqu\u00edn","nombrePadre":"Moraz\u00e1n"},
	SV120220: {"codigo":"SV120220","nombre":"San Carlos","nombrePadre":"Moraz\u00e1n"},
	SV120223: {"codigo":"SV120223","nombre":"Sensembra","nombrePadre":"Moraz\u00e1n"},
	SV120224: {"codigo":"SV120224","nombre":"Gualococti","nombrePadre":"Moraz\u00e1n"},
	SV130103: {"codigo":"SV130103","nombre":"San Sebasti\u00e1n","nombrePadre":"San Vicente"},
	SV130104: {"codigo":"SV130104","nombre":"Apastepeque","nombrePadre":"San Vicente"},
	SV130105: {"codigo":"SV130105","nombre":"San Esteban Catarina","nombrePadre":"San Vicente"},
	SV130106: {"codigo":"SV130106","nombre":"San Ildefonso","nombrePadre":"San Vicente"},
	SV130107: {"codigo":"SV130107","nombre":"Santa Clara","nombrePadre":"San Vicente"},
	SV130108: {"codigo":"SV130108","nombre":"San Lorenzo","nombrePadre":"San Vicente"},
	SV130111: {"codigo":"SV130111","nombre":"Santo Domingo","nombrePadre":"San Vicente"},
	SV130201: {"codigo":"SV130201","nombre":"San Vicente","nombrePadre":"San Vicente"},
	SV130202: {"codigo":"SV130202","nombre":"Tecoluca","nombrePadre":"San Vicente"},
	SV130209: {"codigo":"SV130209","nombre":"Verapaz","nombrePadre":"San Vicente"},
	SV130210: {"codigo":"SV130210","nombre":"Guadalupe","nombrePadre":"San Vicente"},
	SV130212: {"codigo":"SV130212","nombre":"San Cayetano Istepeque","nombrePadre":"San Vicente"},
	SV130213: {"codigo":"SV130213","nombre":"Tepetit\u00e1n","nombrePadre":"San Vicente"},
	SV140101: {"codigo":"SV140101","nombre":"Sensuntepeque","nombrePadre":"Caba\u00f1as"},
	SV140103: {"codigo":"SV140103","nombre":"Victoria","nombrePadre":"Caba\u00f1as"},
	SV140104: {"codigo":"SV140104","nombre":"San Isidro","nombrePadre":"Caba\u00f1as"},
	SV010112: {"codigo":"SV010112","nombre":"Aguilares","nombrePadre":"San Salvador"},
	SV140107: {"codigo":"SV140107","nombre":"Dolores","nombrePadre":"Caba\u00f1as"},
	SV010116: {"codigo":"SV010116","nombre":"El Paisnal","nombrePadre":"San Salvador"},
	SV140109: {"codigo":"SV140109","nombre":"Guacotecti","nombrePadre":"Caba\u00f1as"},
	SV010117: {"codigo":"SV010117","nombre":"Guazapa","nombrePadre":"San Salvador"},
	SV140202: {"codigo":"SV140202","nombre":"Ilobasco","nombrePadre":"Caba\u00f1as"},
	SV010208: {"codigo":"SV010208","nombre":"Nejapa","nombrePadre":"San Salvador"},
	SV140205: {"codigo":"SV140205","nombre":"Jutiapa","nombrePadre":"Caba\u00f1as"},
	SV010209: {"codigo":"SV010209","nombre":"Apopa","nombrePadre":"San Salvador"},
	SV140206: {"codigo":"SV140206","nombre":"Tejutepeque","nombrePadre":"Caba\u00f1as"},
	SV010402: {"codigo":"SV010402","nombre":"Ciudad Delgado","nombrePadre":"San Salvador"},
	SV140208: {"codigo":"SV140208","nombre":"Cinquera","nombrePadre":"Caba\u00f1as"},
	SV010304: {"codigo":"SV010304","nombre":"Soyapango","nombrePadre":"San Salvador"},
	SV010405: {"codigo":"SV010405","nombre":"Cuscatancingo","nombrePadre":"San Salvador"},
	SV010307: {"codigo":"SV010307","nombre":"Ilopango","nombrePadre":"San Salvador"},
	SV010310: {"codigo":"SV010310","nombre":"San Mart\u00edn","nombrePadre":"San Salvador"},
	SV010313: {"codigo":"SV010313","nombre":"Tonacatepeque","nombrePadre":"San Salvador"},
	SV010401: {"codigo":"SV010401","nombre":"San Salvador","nombrePadre":"San Salvador"},
	SV010403: {"codigo":"SV010403","nombre":"Mejicanos","nombrePadre":"San Salvador"},
	SV010506: {"codigo":"SV010506","nombre":"San Marcos","nombrePadre":"San Salvador"},
	SV010514: {"codigo":"SV010514","nombre":"Santo Tom\u00e1s","nombrePadre":"San Salvador"},
	SV010515: {"codigo":"SV010515","nombre":"Santiago Texacuangos","nombrePadre":"San Salvador"},
	SV010418: {"codigo":"SV010418","nombre":"Ayutuxtepeque","nombrePadre":"San Salvador"},
	SV010511: {"codigo":"SV010511","nombre":"Panchimalco","nombrePadre":"San Salvador"},
	SV010519: {"codigo":"SV010519","nombre":"Rosario de Mora","nombrePadre":"San Salvador"},
	SV020103: {"codigo":"SV020103","nombre":"Metap\u00e1n","nombrePadre":"Santa Ana"},
	SV020106: {"codigo":"SV020106","nombre":"Texistepeque","nombrePadre":"Santa Ana"},
	SV020109: {"codigo":"SV020109","nombre":"Santa Rosa Guachipil\u00edn","nombrePadre":"Santa Ana"},
	SV020112: {"codigo":"SV020112","nombre":"Masahuat","nombrePadre":"Santa Ana"},
	SV020201: {"codigo":"SV020201","nombre":"Santa Ana","nombrePadre":"Santa Ana"},
	SV020304: {"codigo":"SV020304","nombre":"Coatepeque","nombrePadre":"Santa Ana"},
	SV020305: {"codigo":"SV020305","nombre":"El Congo","nombrePadre":"Santa Ana"},
	SV020402: {"codigo":"SV020402","nombre":"Chalchuapa","nombrePadre":"Santa Ana"},
	SV020407: {"codigo":"SV020407","nombre":"Candelaria de la Frontera","nombrePadre":"Santa Ana"},
	SV020408: {"codigo":"SV020408","nombre":"San Sebasti\u00e1n Salitrillo","nombrePadre":"Santa Ana"},
	SV020410: {"codigo":"SV020410","nombre":"Santiago de la Frontera","nombrePadre":"Santa Ana"},
	SV020411: {"codigo":"SV020411","nombre":"El Porvenir","nombrePadre":"Santa Ana"},
	SV020413: {"codigo":"SV020413","nombre":"San Antonio Pajonal","nombrePadre":"Santa Ana"},
	SV030104: {"codigo":"SV030104","nombre":"Ciudad Barrios","nombrePadre":"San Miguel"},
	SV030106: {"codigo":"SV030106","nombre":"Sesori","nombrePadre":"San Miguel"},
	SV030111: {"codigo":"SV030111","nombre":"Chapeltique","nombrePadre":"San Miguel"},
	SV030112: {"codigo":"SV030112","nombre":"San Gerardo","nombrePadre":"San Miguel"},
	SV030113: {"codigo":"SV030113","nombre":"Carolina","nombrePadre":"San Miguel"},
	SV030115: {"codigo":"SV030115","nombre":"San Luis de la Reina","nombrePadre":"San Miguel"},
	SV030116: {"codigo":"SV030116","nombre":"Nuevo Ed\u00e9n de San Juan","nombrePadre":"San Miguel"},
	SV030120: {"codigo":"SV030120","nombre":"San Antonio del Mosco","nombrePadre":"San Miguel"},
	SV030201: {"codigo":"SV030201","nombre":"San Miguel","nombrePadre":"San Miguel"},
	SV030205: {"codigo":"SV030205","nombre":"Chirilagua","nombrePadre":"San Miguel"},
	SV030208: {"codigo":"SV030208","nombre":"Moncagua","nombrePadre":"San Miguel"},
	SV030214: {"codigo":"SV030214","nombre":"Quelepa","nombrePadre":"San Miguel"},
	SV030218: {"codigo":"SV030218","nombre":"Uluazapa","nombrePadre":"San Miguel"},
	SV030219: {"codigo":"SV030219","nombre":"Comacar\u00e1n","nombrePadre":"San Miguel"},
	SV030302: {"codigo":"SV030302","nombre":"Chinameca","nombrePadre":"San Miguel"},
	SV030303: {"codigo":"SV030303","nombre":"El Tr\u00e1nsito","nombrePadre":"San Miguel"},
	SV030307: {"codigo":"SV030307","nombre":"San Rafael Oriente","nombrePadre":"San Miguel"},
	SV030309: {"codigo":"SV030309","nombre":"Lolotique","nombrePadre":"San Miguel"},
	SV030310: {"codigo":"SV030310","nombre":"San Jorge","nombrePadre":"San Miguel"},
	SV030317: {"codigo":"SV030317","nombre":"Nueva Guadalupe","nombrePadre":"San Miguel"},
	SV040102: {"codigo":"SV040102","nombre":"Quezaltepeque","nombrePadre":"La Libertad"},
	SV040109: {"codigo":"SV040109","nombre":"San Pablo Tacachico","nombrePadre":"La Libertad"},
	SV040121: {"codigo":"SV040121","nombre":"San Mat\u00edas","nombrePadre":"La Libertad"},
	SV040203: {"codigo":"SV040203","nombre":"Ciudad Arce","nombrePadre":"La Libertad"},
	SV040204: {"codigo":"SV040204","nombre":"San Juan Opico","nombrePadre":"La Libertad"},
	SV040305: {"codigo":"SV040305","nombre":"Col\u00f3n","nombrePadre":"La Libertad"},
	SV040310: {"codigo":"SV040310","nombre":"Jayaque","nombrePadre":"La Libertad"},
	SV040317: {"codigo":"SV040317","nombre":"Sacacoyo","nombrePadre":"La Libertad"},
	SV040318: {"codigo":"SV040318","nombre":"Tepecoyo","nombrePadre":"La Libertad"},
	SV040320: {"codigo":"SV040320","nombre":"Talnique","nombrePadre":"La Libertad"},
	SV040407: {"codigo":"SV040407","nombre":"Antiguo Cuscatl\u00e1n","nombrePadre":"La Libertad"},
	SV040411: {"codigo":"SV040411","nombre":"Huiz\u00facar","nombrePadre":"La Libertad"},
	SV040412: {"codigo":"SV040412","nombre":"San Jos\u00e9 Villanueva","nombrePadre":"La Libertad"},
	SV040415: {"codigo":"SV040415","nombre":"Nuevo Cuscatl\u00e1n","nombrePadre":"La Libertad"},
	SV040419: {"codigo":"SV040419","nombre":"Zaragoza","nombrePadre":"La Libertad"},
	SV040506: {"codigo":"SV040506","nombre":"La Libertad","nombrePadre":"La Libertad"},
	SV040513: {"codigo":"SV040513","nombre":"Teotepeque","nombrePadre":"La Libertad"},
	SV040514: {"codigo":"SV040514","nombre":"Chiltiup\u00e1n","nombrePadre":"La Libertad"},
	SV040516: {"codigo":"SV040516","nombre":"Tamanique","nombrePadre":"La Libertad"},
	SV040522: {"codigo":"SV040522","nombre":"Jicalapa","nombrePadre":"La Libertad"},
	SV040601: {"codigo":"SV040601","nombre":"Santa Tecla","nombrePadre":"La Libertad"},
	SV040608: {"codigo":"SV040608","nombre":"Comasagua","nombrePadre":"La Libertad"},
	SV050103: {"codigo":"SV050103","nombre":"Berl\u00edn","nombrePadre":"Usulut\u00e1n"},
	SV050104: {"codigo":"SV050104","nombre":"Santiago de Mar\u00eda","nombrePadre":"Usulut\u00e1n"},
	SV050105: {"codigo":"SV050105","nombre":"Jucuapa","nombrePadre":"Usulut\u00e1n"},
	SV050110: {"codigo":"SV050110","nombre":"Estanzuelas","nombrePadre":"Usulut\u00e1n"},
	SV050111: {"codigo":"SV050111","nombre":"Mercedes Uma\u00f1a","nombrePadre":"Usulut\u00e1n"},
	SV050112: {"codigo":"SV050112","nombre":"Alegr\u00eda","nombrePadre":"Usulut\u00e1n"},
	SV050120: {"codigo":"SV050120","nombre":"Nueva Granada","nombrePadre":"Usulut\u00e1n"},
	SV050121: {"codigo":"SV050121","nombre":"El Triunfo","nombrePadre":"Usulut\u00e1n"},
	SV050122: {"codigo":"SV050122","nombre":"San Buenaventura","nombrePadre":"Usulut\u00e1n"},
	SV050201: {"codigo":"SV050201","nombre":"Usulut\u00e1n","nombrePadre":"Usulut\u00e1n"},
	SV050206: {"codigo":"SV050206","nombre":"Santa Elena","nombrePadre":"Usulut\u00e1n"},
	SV050207: {"codigo":"SV050207","nombre":"Jucuar\u00e1n","nombrePadre":"Usulut\u00e1n"},
	SV050209: {"codigo":"SV050209","nombre":"Ozatl\u00e1n","nombrePadre":"Usulut\u00e1n"},
	SV050213: {"codigo":"SV050213","nombre":"Concepci\u00f3n Batres","nombrePadre":"Usulut\u00e1n"},
	SV050216: {"codigo":"SV050216","nombre":"Tecap\u00e1n","nombrePadre":"Usulut\u00e1n"},
	SV050217: {"codigo":"SV050217","nombre":"San Dionisio","nombrePadre":"Usulut\u00e1n"},
	SV050218: {"codigo":"SV050218","nombre":"Ereguayqu\u00edn","nombrePadre":"Usulut\u00e1n"},
	SV050219: {"codigo":"SV050219","nombre":"Santa Mar\u00eda","nombrePadre":"Usulut\u00e1n"},
	SV050223: {"codigo":"SV050223","nombre":"California","nombrePadre":"Usulut\u00e1n"},
	SV050302: {"codigo":"SV050302","nombre":"Jiquilisco","nombrePadre":"Usulut\u00e1n"},
	SV050308: {"codigo":"SV050308","nombre":"San Agust\u00edn","nombrePadre":"Usulut\u00e1n"},
	SV050314: {"codigo":"SV050314","nombre":"San Francisco Javier","nombrePadre":"Usulut\u00e1n"},
	SV050315: {"codigo":"SV050315","nombre":"Puerto El Triunfo","nombrePadre":"Usulut\u00e1n"},
	SV060105: {"codigo":"SV060105","nombre":"Nahuizalco","nombrePadre":"Sonsonate"},
	SV060106: {"codigo":"SV060106","nombre":"Juay\u00faa","nombrePadre":"Sonsonate"},
	SV060112: {"codigo":"SV060112","nombre":"Santa Catarina Masahuat","nombrePadre":"Sonsonate"},
	SV060115: {"codigo":"SV060115","nombre":"Salcoatit\u00e1n","nombrePadre":"Sonsonate"},
	SV060201: {"codigo":"SV060201","nombre":"Sonsonate","nombrePadre":"Sonsonate"},
	SV060208: {"codigo":"SV060208","nombre":"Sonzacate","nombrePadre":"Sonsonate"},
	SV060209: {"codigo":"SV060209","nombre":"San Antonio del Monte","nombrePadre":"Sonsonate"},
	SV060210: {"codigo":"SV060210","nombre":"Nahulingo","nombrePadre":"Sonsonate"},
	SV060216: {"codigo":"SV060216","nombre":"Santo Domingo de Guzm\u00e1n","nombrePadre":"Sonsonate"},
	SV060302: {"codigo":"SV060302","nombre":"Izalco","nombrePadre":"Sonsonate"},
	SV060304: {"codigo":"SV060304","nombre":"Armenia","nombrePadre":"Sonsonate"},
	SV060307: {"codigo":"SV060307","nombre":"San Juli\u00e1n","nombrePadre":"Sonsonate"},
	SV060311: {"codigo":"SV060311","nombre":"Cuisnahuat","nombrePadre":"Sonsonate"},
	SV060313: {"codigo":"SV060313","nombre":"Caluco","nombrePadre":"Sonsonate"},
	SV060314: {"codigo":"SV060314","nombre":"Santa Isabel Ishuat\u00e1n","nombrePadre":"Sonsonate"},
	SV060403: {"codigo":"SV060403","nombre":"Acajutla","nombrePadre":"Sonsonate"},
	SV070102: {"codigo":"SV070102","nombre":"Santa Rosa de Lima","nombrePadre":"La Uni\u00f3n"},
	SV070103: {"codigo":"SV070103","nombre":"Pasaquina","nombrePadre":"La Uni\u00f3n"},
	SV070105: {"codigo":"SV070105","nombre":"Anamor\u00f3s","nombrePadre":"La Uni\u00f3n"},
	SV070108: {"codigo":"SV070108","nombre":"El Sauce","nombrePadre":"La Uni\u00f3n"},
	SV070109: {"codigo":"SV070109","nombre":"Lislique","nombrePadre":"La Uni\u00f3n"},
	SV070111: {"codigo":"SV070111","nombre":"Nueva Esparta","nombrePadre":"La Uni\u00f3n"},
	SV070112: {"codigo":"SV070112","nombre":"Polor\u00f3s","nombrePadre":"La Uni\u00f3n"},
	SV070113: {"codigo":"SV070113","nombre":"Bol\u00edvar","nombrePadre":"La Uni\u00f3n"},
	SV070114: {"codigo":"SV070114","nombre":"Concepci\u00f3n de Oriente","nombrePadre":"La Uni\u00f3n"},
	SV070116: {"codigo":"SV070116","nombre":"San Jos\u00e9 Las Fuentes","nombrePadre":"La Uni\u00f3n"},
	SV070201: {"codigo":"SV070201","nombre":"La Uni\u00f3n","nombrePadre":"La Uni\u00f3n"},
	SV070204: {"codigo":"SV070204","nombre":"San Alejo","nombrePadre":"La Uni\u00f3n"},
	SV070206: {"codigo":"SV070206","nombre":"El Carmen","nombrePadre":"La Uni\u00f3n"},
	SV070207: {"codigo":"SV070207","nombre":"Conchagua","nombrePadre":"La Uni\u00f3n"},
	SV070210: {"codigo":"SV070210","nombre":"Yucuaiqu\u00edn","nombrePadre":"La Uni\u00f3n"},
	SV070215: {"codigo":"SV070215","nombre":"Intipuc\u00e1","nombrePadre":"La Uni\u00f3n"},
	SV070217: {"codigo":"SV070217","nombre":"Yayantique","nombrePadre":"La Uni\u00f3n"},
	SV070218: {"codigo":"SV070218","nombre":"Meanguera del Golfo","nombrePadre":"La Uni\u00f3n"},
	SV080104: {"codigo":"SV080104","nombre":"San Pedro Masahuat","nombrePadre":"La Paz"},
	SV080105: {"codigo":"SV080105","nombre":"Olocuilta","nombrePadre":"La Paz"},
	SV080107: {"codigo":"SV080107","nombre":"San Francisco Chinameca","nombrePadre":"La Paz"},
	SV080108: {"codigo":"SV080108","nombre":"San Juan Talpa","nombrePadre":"La Paz"},
	SV080112: {"codigo":"SV080112","nombre":"San Luis Talpa","nombrePadre":"La Paz"},
	SV080116: {"codigo":"SV080116","nombre":"Tapalhuaca","nombrePadre":"La Paz"},
	SV080117: {"codigo":"SV080117","nombre":"Cuyultit\u00e1n","nombrePadre":"La Paz"},
	SV080202: {"codigo":"SV080202","nombre":"Santiago Nonualco","nombrePadre":"La Paz"},
	SV080206: {"codigo":"SV080206","nombre":"San Pedro Nonualco","nombrePadre":"La Paz"},
	SV080209: {"codigo":"SV080209","nombre":"El Rosario","nombrePadre":"La Paz"},
	SV080211: {"codigo":"SV080211","nombre":"Santa Mar\u00eda Ostuma","nombrePadre":"La Paz"},
	SV080213: {"codigo":"SV080213","nombre":"San Antonio Masahuat","nombrePadre":"La Paz"},
	SV080214: {"codigo":"SV080214","nombre":"San Miguel Tepezontes","nombrePadre":"La Paz"},
	SV080215: {"codigo":"SV080215","nombre":"San Juan Tepezontes","nombrePadre":"La Paz"},
	SV080218: {"codigo":"SV080218","nombre":"Para\u00edso de Osorio","nombrePadre":"La Paz"},
	SV080219: {"codigo":"SV080219","nombre":"San Emigdio","nombrePadre":"La Paz"},
	SV080220: {"codigo":"SV080220","nombre":"Jerusal\u00e9n","nombrePadre":"La Paz"},
	SV080221: {"codigo":"SV080221","nombre":"Mercedes La Ceiba","nombrePadre":"La Paz"},
	SV080222: {"codigo":"SV080222","nombre":"San Luis La Herradura","nombrePadre":"La Paz"},
	SV080301: {"codigo":"SV080301","nombre":"Zacatecoluca","nombrePadre":"La Paz"},
	SV080303: {"codigo":"SV080303","nombre":"San Juan Nonualco","nombrePadre":"La Paz"},
	SV080310: {"codigo":"SV080310","nombre":"San Rafael Obrajuelo","nombrePadre":"La Paz"},
	SV090103: {"codigo":"SV090103","nombre":"La Palma","nombrePadre":"Chalatenango"},
	SV090107: {"codigo":"SV090107","nombre":"San Ignacio","nombrePadre":"Chalatenango"},
	SV090109: {"codigo":"SV090109","nombre":"Cital\u00e1","nombrePadre":"Chalatenango"},
	SV090202: {"codigo":"SV090202","nombre":"Nueva Concepci\u00f3n","nombrePadre":"Chalatenango"},
	SV090204: {"codigo":"SV090204","nombre":"Tejutla","nombrePadre":"Chalatenango"},
	SV090205: {"codigo":"SV090205","nombre":"La Reina","nombrePadre":"Chalatenango"},
	SV090208: {"codigo":"SV090208","nombre":"Dulce Nombre de Mar\u00eda","nombrePadre":"Chalatenango"},
	SV090210: {"codigo":"SV090210","nombre":"Agua Caliente","nombrePadre":"Chalatenango"},
	SV090215: {"codigo":"SV090215","nombre":"San Rafael","nombrePadre":"Chalatenango"},
	SV090220: {"codigo":"SV090220","nombre":"San Francisco Moraz\u00e1n","nombrePadre":"Chalatenango"},
	SV090221: {"codigo":"SV090221","nombre":"Santa Rita","nombrePadre":"Chalatenango"},
	SV090225: {"codigo":"SV090225","nombre":"El Para\u00edso","nombrePadre":"Chalatenango"},
	SV090231: {"codigo":"SV090231","nombre":"San Fernando","nombrePadre":"Chalatenango"},
	SV090301: {"codigo":"SV090301","nombre":"Chalatenango","nombrePadre":"Chalatenango"},
	SV090306: {"codigo":"SV090306","nombre":"Arcatao","nombrePadre":"Chalatenango"},
	SV090311: {"codigo":"SV090311","nombre":"Concepci\u00f3n Quezaltepeque","nombrePadre":"Chalatenango"},
	SV090312: {"codigo":"SV090312","nombre":"Nueva Trinidad","nombrePadre":"Chalatenango"},
	SV090313: {"codigo":"SV090313","nombre":"Las Vueltas","nombrePadre":"Chalatenango"},
	SV090314: {"codigo":"SV090314","nombre":"Comalapa","nombrePadre":"Chalatenango"},
	SV090316: {"codigo":"SV090316","nombre":"Las Flores","nombrePadre":"Chalatenango"},
	SV090317: {"codigo":"SV090317","nombre":"Ojos de Agua","nombrePadre":"Chalatenango"},
	SV090318: {"codigo":"SV090318","nombre":"Nombre de Jes\u00fas","nombrePadre":"Chalatenango"},
	SV090319: {"codigo":"SV090319","nombre":"Potonico","nombrePadre":"Chalatenango"},
	SV090322: {"codigo":"SV090322","nombre":"La Laguna","nombrePadre":"Chalatenango"},
	SV090323: {"codigo":"SV090323","nombre":"San Isidro Labrador","nombrePadre":"Chalatenango"},
	SV090324: {"codigo":"SV090324","nombre":"San Antonio de la Cruz","nombrePadre":"Chalatenango"},
	SV090326: {"codigo":"SV090326","nombre":"San Miguel de Mercedes","nombrePadre":"Chalatenango"},
	SV090327: {"codigo":"SV090327","nombre":"San Luis del Carmen","nombrePadre":"Chalatenango"},
	SV090328: {"codigo":"SV090328","nombre":"San Jos\u00e9 Cancasque","nombrePadre":"Chalatenango"},
	SV090329: {"codigo":"SV090329","nombre":"San Antonio Los Ranchos","nombrePadre":"Chalatenango"},
	SV090330: {"codigo":"SV090330","nombre":"El Carrizal","nombrePadre":"Chalatenango"},
	SV090332: {"codigo":"SV090332","nombre":"Azacualpa","nombrePadre":"Chalatenango"},
	SV090333: {"codigo":"SV090333","nombre":"San Francisco Lempa","nombrePadre":"Chalatenango"},
	SV100102: {"codigo":"SV100102","nombre":"Suchitoto","nombrePadre":"Cuscatl\u00e1n"},
	SV100103: {"codigo":"SV100103","nombre":"San Pedro Perulap\u00e1n","nombrePadre":"Cuscatl\u00e1n"},
	SV100104: {"codigo":"SV100104","nombre":"San Jos\u00e9 Guayabal","nombrePadre":"Cuscatl\u00e1n"},
	SV100112: {"codigo":"SV100112","nombre":"San Bartolom\u00e9 Perulap\u00eda","nombrePadre":"Cuscatl\u00e1n"},
	SV100115: {"codigo":"SV100115","nombre":"Oratorio de Concepci\u00f3n","nombrePadre":"Cuscatl\u00e1n"},
	SV100201: {"codigo":"SV100201","nombre":"Cojutepeque","nombrePadre":"Cuscatl\u00e1n"},
	SV100205: {"codigo":"SV100205","nombre":"Tenancingo","nombrePadre":"Cuscatl\u00e1n"},
	SV100206: {"codigo":"SV100206","nombre":"San Rafael Cedros","nombrePadre":"Cuscatl\u00e1n"},
	SV100207: {"codigo":"SV100207","nombre":"Candelaria","nombrePadre":"Cuscatl\u00e1n"},
	SV100208: {"codigo":"SV100208","nombre":"El Carmen","nombrePadre":"Cuscatl\u00e1n"},
	SV09010702: {"codigo":"SV09010702","nombre":"Zona 02 (San Ignacio)","nombrePadre":"Chalatenango"},
	SV04060112: {"codigo":"SV04060112","nombre":"Zona 12 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12012501: {"codigo":"SV12012501","nombre":"Zona 01 (San Fernando)","nombrePadre":"Moraz\u00e1n"},
	SV01040201: {"codigo":"SV01040201","nombre":"Zona 01 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06021005: {"codigo":"SV06021005","nombre":"Zona 05 (Nahulingo)","nombrePadre":"Sonsonate"},
	SV09010703: {"codigo":"SV09010703","nombre":"Zona 03 (San Ignacio)","nombrePadre":"Chalatenango"},
	SV04060113: {"codigo":"SV04060113","nombre":"Zona 13 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12012502: {"codigo":"SV12012502","nombre":"Zona 02 (San Fernando)","nombrePadre":"Moraz\u00e1n"},
	SV01040202: {"codigo":"SV01040202","nombre":"Zona 02 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06020901: {"codigo":"SV06020901","nombre":"Zona 01 (San Antonio del Monte)","nombrePadre":"Sonsonate"},
	SV09010704: {"codigo":"SV09010704","nombre":"Zona 04 (San Ignacio)","nombrePadre":"Chalatenango"},
	SV04060114: {"codigo":"SV04060114","nombre":"Zona 14 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12012601: {"codigo":"SV12012601","nombre":"Zona 01 (San Isidro)","nombrePadre":"Moraz\u00e1n"},
	SV01040203: {"codigo":"SV01040203","nombre":"Zona 03 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06020902: {"codigo":"SV06020902","nombre":"Zona 02 (San Antonio del Monte)","nombrePadre":"Sonsonate"},
	SV09010705: {"codigo":"SV09010705","nombre":"Zona 05 (San Ignacio)","nombrePadre":"Chalatenango"},
	SV04060115: {"codigo":"SV04060115","nombre":"Zona 15 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12012602: {"codigo":"SV12012602","nombre":"Zona 02 (San Isidro)","nombrePadre":"Moraz\u00e1n"},
	SV01040204: {"codigo":"SV01040204","nombre":"Zona 04 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06020903: {"codigo":"SV06020903","nombre":"Zona 03 (San Antonio del Monte)","nombrePadre":"Sonsonate"},
	SV09010706: {"codigo":"SV09010706","nombre":"Zona 06 (San Ignacio)","nombrePadre":"Chalatenango"},
	SV04060116: {"codigo":"SV04060116","nombre":"Zona 16 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12012603: {"codigo":"SV12012603","nombre":"Zona 03 (San Isidro)","nombrePadre":"Moraz\u00e1n"},
	SV01040205: {"codigo":"SV01040205","nombre":"Zona 05 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06021601: {"codigo":"SV06021601","nombre":"Zona 01 (Santo Domingo de Guzm\u00e1n)","nombrePadre":"Sonsonate"},
	SV09030601: {"codigo":"SV09030601","nombre":"Zona 01 (Arcatao)","nombrePadre":"Chalatenango"},
	SV04060117: {"codigo":"SV04060117","nombre":"Zona 17 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12011201: {"codigo":"SV12011201","nombre":"Zona 01 (Torola)","nombrePadre":"Moraz\u00e1n"},
	SV01040206: {"codigo":"SV01040206","nombre":"Zona 06 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06021602: {"codigo":"SV06021602","nombre":"Zona 02 (Santo Domingo de Guzm\u00e1n)","nombrePadre":"Sonsonate"},
	SV09030602: {"codigo":"SV09030602","nombre":"Zona 02 (Arcatao)","nombrePadre":"Chalatenango"},
	SV04060118: {"codigo":"SV04060118","nombre":"Zona 18 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12011202: {"codigo":"SV12011202","nombre":"Zona 02 (Torola)","nombrePadre":"Moraz\u00e1n"},
	SV01040207: {"codigo":"SV01040207","nombre":"Zona 07 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06021603: {"codigo":"SV06021603","nombre":"Zona 03 (Santo Domingo de Guzm\u00e1n)","nombrePadre":"Sonsonate"},
	SV09030603: {"codigo":"SV09030603","nombre":"Zona 03 (Arcatao)","nombrePadre":"Chalatenango"},
	SV08020901: {"codigo":"SV08020901","nombre":"Zona 01 (El Rosario)","nombrePadre":"La Paz"},
	SV12011203: {"codigo":"SV12011203","nombre":"Zona 03 (Torola)","nombrePadre":"Moraz\u00e1n"},
	SV01040208: {"codigo":"SV01040208","nombre":"Zona 08 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06020101: {"codigo":"SV06020101","nombre":"Zona 01 (Sonsonate)","nombrePadre":"Sonsonate"},
	SV09033201: {"codigo":"SV09033201","nombre":"Zona 01 (Azacualpa)","nombrePadre":"Chalatenango"},
	SV08020902: {"codigo":"SV08020902","nombre":"Zona 02 (El Rosario)","nombrePadre":"La Paz"},
	SV12011204: {"codigo":"SV12011204","nombre":"Zona 04 (Torola)","nombrePadre":"Moraz\u00e1n"},
	SV01040209: {"codigo":"SV01040209","nombre":"Zona 09 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06020102: {"codigo":"SV06020102","nombre":"Zona 02 (Sonsonate)","nombrePadre":"Sonsonate"},
	SV09033202: {"codigo":"SV09033202","nombre":"Zona 02 (Azacualpa)","nombrePadre":"Chalatenango"},
	SV08020903: {"codigo":"SV08020903","nombre":"Zona 03 (El Rosario)","nombrePadre":"La Paz"},
	SV12011205: {"codigo":"SV12011205","nombre":"Zona 05 (Torola)","nombrePadre":"Moraz\u00e1n"},
	SV01040210: {"codigo":"SV01040210","nombre":"Zona 10 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06020103: {"codigo":"SV06020103","nombre":"Zona 03 (Sonsonate)","nombrePadre":"Sonsonate"},
	SV09030101: {"codigo":"SV09030101","nombre":"Zona 01 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08020904: {"codigo":"SV08020904","nombre":"Zona 04 (El Rosario)","nombrePadre":"La Paz"},
	SV12021001: {"codigo":"SV12021001","nombre":"Zona 01 (Chilanga)","nombrePadre":"Moraz\u00e1n"},
	SV01040211: {"codigo":"SV01040211","nombre":"Zona 11 (Ciudad Delgado)","nombrePadre":"San Salvador"},
	SV06020104: {"codigo":"SV06020104","nombre":"Zona 04 (Sonsonate)","nombrePadre":"Sonsonate"},
	SV09030102: {"codigo":"SV09030102","nombre":"Zona 02 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08020905: {"codigo":"SV08020905","nombre":"Zona 05 (El Rosario)","nombrePadre":"La Paz"},
	SV12021002: {"codigo":"SV12021002","nombre":"Zona 02 (Chilanga)","nombrePadre":"Moraz\u00e1n"},
	SV01040501: {"codigo":"SV01040501","nombre":"Zona 01 (Cuscatancingo)","nombrePadre":"San Salvador"},
	SV06020105: {"codigo":"SV06020105","nombre":"Zona 05 (Sonsonate)","nombrePadre":"Sonsonate"},
	SV09030103: {"codigo":"SV09030103","nombre":"Zona 03 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08022001: {"codigo":"SV08022001","nombre":"Zona 01 (Jerusal\u00e9n)","nombrePadre":"La Paz"},
	SV12021003: {"codigo":"SV12021003","nombre":"Zona 03 (Chilanga)","nombrePadre":"Moraz\u00e1n"},
	SV01040502: {"codigo":"SV01040502","nombre":"Zona 02 (Cuscatancingo)","nombrePadre":"San Salvador"},
	SV06020106: {"codigo":"SV06020106","nombre":"Zona 06 (Sonsonate)","nombrePadre":"Sonsonate"},
	SV09030104: {"codigo":"SV09030104","nombre":"Zona 04 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08022002: {"codigo":"SV08022002","nombre":"Zona 02 (Jerusal\u00e9n)","nombrePadre":"La Paz"},
	SV12021004: {"codigo":"SV12021004","nombre":"Zona 04 (Chilanga)","nombrePadre":"Moraz\u00e1n"},
	SV01040503: {"codigo":"SV01040503","nombre":"Zona 03 (Cuscatancingo)","nombrePadre":"San Salvador"},
	SV06020107: {"codigo":"SV06020107","nombre":"Zona 07 (Sonsonate)","nombrePadre":"Sonsonate"},
	SV09030105: {"codigo":"SV09030105","nombre":"Zona 05 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08022003: {"codigo":"SV08022003","nombre":"Zona 03 (Jerusal\u00e9n)","nombrePadre":"La Paz"},
	SV12021005: {"codigo":"SV12021005","nombre":"Zona 05 (Chilanga)","nombrePadre":"Moraz\u00e1n"},
	SV01030701: {"codigo":"SV01030701","nombre":"Zona 01 (Ilopango)","nombrePadre":"San Salvador"},
	SV06020801: {"codigo":"SV06020801","nombre":"Zona 01 (Sonzacate)","nombrePadre":"Sonsonate"},
	SV09030106: {"codigo":"SV09030106","nombre":"Zona 06 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08022101: {"codigo":"SV08022101","nombre":"Zona 01 (Mercedes La Ceiba)","nombrePadre":"La Paz"},
	SV12021401: {"codigo":"SV12021401","nombre":"Zona 01 (Delicias de Concepci\u00f3n)","nombrePadre":"Moraz\u00e1n"},
	SV01030702: {"codigo":"SV01030702","nombre":"Zona 02 (Ilopango)","nombrePadre":"San Salvador"},
	SV06020802: {"codigo":"SV06020802","nombre":"Zona 02 (Sonzacate)","nombrePadre":"Sonsonate"},
	SV09030107: {"codigo":"SV09030107","nombre":"Zona 07 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08022102: {"codigo":"SV08022102","nombre":"Zona 02 (Mercedes La Ceiba)","nombrePadre":"La Paz"},
	SV12021402: {"codigo":"SV12021402","nombre":"Zona 02 (Delicias de Concepci\u00f3n)","nombrePadre":"Moraz\u00e1n"},
	SV01030703: {"codigo":"SV01030703","nombre":"Zona 03 (Ilopango)","nombrePadre":"San Salvador"},
	SV06020803: {"codigo":"SV06020803","nombre":"Zona 03 (Sonzacate)","nombrePadre":"Sonsonate"},
	SV09030108: {"codigo":"SV09030108","nombre":"Zona 08 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08021801: {"codigo":"SV08021801","nombre":"Zona 01 (Para\u00edso de Osorio)","nombrePadre":"La Paz"},
	SV12021403: {"codigo":"SV12021403","nombre":"Zona 03 (Delicias de Concepci\u00f3n)","nombrePadre":"Moraz\u00e1n"},
	SV01030704: {"codigo":"SV01030704","nombre":"Zona 04 (Ilopango)","nombrePadre":"San Salvador"},
	SV06020804: {"codigo":"SV06020804","nombre":"Zona 04 (Sonzacate)","nombrePadre":"Sonsonate"},
	SV09030109: {"codigo":"SV09030109","nombre":"Zona 09 (Chalatenango)","nombrePadre":"Chalatenango"},
	SV08021802: {"codigo":"SV08021802","nombre":"Zona 02 (Para\u00edso de Osorio)","nombrePadre":"La Paz"},
	SV12020701: {"codigo":"SV12020701","nombre":"Zona 01 (El Divisadero)","nombrePadre":"Moraz\u00e1n"},
	SV01031001: {"codigo":"SV01031001","nombre":"Zona 01 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06030401: {"codigo":"SV06030401","nombre":"Zona 01 (Armenia)","nombrePadre":"Sonsonate"},
	SV09031401: {"codigo":"SV09031401","nombre":"Zona 01 (Comalapa)","nombrePadre":"Chalatenango"},
	SV08021803: {"codigo":"SV08021803","nombre":"Zona 03 (Para\u00edso de Osorio)","nombrePadre":"La Paz"},
	SV12020702: {"codigo":"SV12020702","nombre":"Zona 02 (El Divisadero)","nombrePadre":"Moraz\u00e1n"},
	SV01031002: {"codigo":"SV01031002","nombre":"Zona 02 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06030402: {"codigo":"SV06030402","nombre":"Zona 02 (Armenia)","nombrePadre":"Sonsonate"},
	SV09031402: {"codigo":"SV09031402","nombre":"Zona 02 (Comalapa)","nombrePadre":"Chalatenango"},
	SV08021804: {"codigo":"SV08021804","nombre":"Zona 04 (Para\u00edso de Osorio)","nombrePadre":"La Paz"},
	SV12020703: {"codigo":"SV12020703","nombre":"Zona 03 (El Divisadero)","nombrePadre":"Moraz\u00e1n"},
	SV01031003: {"codigo":"SV01031003","nombre":"Zona 03 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06030403: {"codigo":"SV06030403","nombre":"Zona 03 (Armenia)","nombrePadre":"Sonsonate"},
	SV09031403: {"codigo":"SV09031403","nombre":"Zona 03 (Comalapa)","nombrePadre":"Chalatenango"},
	SV08021301: {"codigo":"SV08021301","nombre":"Zona 01 (San Antonio Masahuat)","nombrePadre":"La Paz"},
	SV12020704: {"codigo":"SV12020704","nombre":"Zona 04 (El Divisadero)","nombrePadre":"Moraz\u00e1n"},
	SV01031004: {"codigo":"SV01031004","nombre":"Zona 04 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06030404: {"codigo":"SV06030404","nombre":"Zona 04 (Armenia)","nombrePadre":"Sonsonate"},
	SV09031101: {"codigo":"SV09031101","nombre":"Zona 01 (Concepci\u00f3n Quezaltepeque)","nombrePadre":"Chalatenango"},
	SV08021302: {"codigo":"SV08021302","nombre":"Zona 02 (San Antonio Masahuat)","nombrePadre":"La Paz"},
	SV12020705: {"codigo":"SV12020705","nombre":"Zona 05 (El Divisadero)","nombrePadre":"Moraz\u00e1n"},
	SV01031005: {"codigo":"SV01031005","nombre":"Zona 05 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06031301: {"codigo":"SV06031301","nombre":"Zona 01 (Caluco)","nombrePadre":"Sonsonate"},
	SV09031102: {"codigo":"SV09031102","nombre":"Zona 02 (Concepci\u00f3n Quezaltepeque)","nombrePadre":"Chalatenango"},
	SV08021303: {"codigo":"SV08021303","nombre":"Zona 03 (San Antonio Masahuat)","nombrePadre":"La Paz"},
	SV12020706: {"codigo":"SV12020706","nombre":"Zona 06 (El Divisadero)","nombrePadre":"Moraz\u00e1n"},
	SV01031006: {"codigo":"SV01031006","nombre":"Zona 06 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06031302: {"codigo":"SV06031302","nombre":"Zona 02 (Caluco)","nombrePadre":"Sonsonate"},
	SV09031103: {"codigo":"SV09031103","nombre":"Zona 03 (Concepci\u00f3n Quezaltepeque)","nombrePadre":"Chalatenango"},
	SV08021304: {"codigo":"SV08021304","nombre":"Zona 04 (San Antonio Masahuat)","nombrePadre":"La Paz"},
	SV12020707: {"codigo":"SV12020707","nombre":"Zona 07 (El Divisadero)","nombrePadre":"Moraz\u00e1n"},
	SV01031007: {"codigo":"SV01031007","nombre":"Zona 07 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06031303: {"codigo":"SV06031303","nombre":"Zona 03 (Caluco)","nombrePadre":"Sonsonate"},
	SV09033001: {"codigo":"SV09033001","nombre":"Zona 01 (El Carrizal)","nombrePadre":"Chalatenango"},
	SV08021901: {"codigo":"SV08021901","nombre":"Zona 01 (San Emigdio)","nombrePadre":"La Paz"},
	SV12020708: {"codigo":"SV12020708","nombre":"Zona 08 (El Divisadero)","nombrePadre":"Moraz\u00e1n"},
	SV01031008: {"codigo":"SV01031008","nombre":"Zona 08 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06031304: {"codigo":"SV06031304","nombre":"Zona 04 (Caluco)","nombrePadre":"Sonsonate"},
	SV09033002: {"codigo":"SV09033002","nombre":"Zona 02 (El Carrizal)","nombrePadre":"Chalatenango"},
	SV08021902: {"codigo":"SV08021902","nombre":"Zona 02 (San Emigdio)","nombrePadre":"La Paz"},
	SV12022401: {"codigo":"SV12022401","nombre":"Zona 01 (Gualococti)","nombrePadre":"Moraz\u00e1n"},
	SV01031009: {"codigo":"SV01031009","nombre":"Zona 09 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06031101: {"codigo":"SV06031101","nombre":"Zona 01 (Cuisnahuat)","nombrePadre":"Sonsonate"},
	SV09033003: {"codigo":"SV09033003","nombre":"Zona 03 (El Carrizal)","nombrePadre":"Chalatenango"},
	SV08021903: {"codigo":"SV08021903","nombre":"Zona 03 (San Emigdio)","nombrePadre":"La Paz"},
	SV12022402: {"codigo":"SV12022402","nombre":"Zona 02 (Gualococti)","nombrePadre":"Moraz\u00e1n"},
	SV01031010: {"codigo":"SV01031010","nombre":"Zona 10 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06031102: {"codigo":"SV06031102","nombre":"Zona 02 (Cuisnahuat)","nombrePadre":"Sonsonate"},
	SV09032201: {"codigo":"SV09032201","nombre":"Zona 01 (La Laguna)","nombrePadre":"Chalatenango"},
	SV08021501: {"codigo":"SV08021501","nombre":"Zona 01 (San Juan Tepezontes)","nombrePadre":"La Paz"},
	SV12022403: {"codigo":"SV12022403","nombre":"Zona 03 (Gualococti)","nombrePadre":"Moraz\u00e1n"},
	SV01031011: {"codigo":"SV01031011","nombre":"Zona 11 (San Mart\u00edn)","nombrePadre":"San Salvador"},
	SV06031103: {"codigo":"SV06031103","nombre":"Zona 03 (Cuisnahuat)","nombrePadre":"Sonsonate"},
	SV09032202: {"codigo":"SV09032202","nombre":"Zona 02 (La Laguna)","nombrePadre":"Chalatenango"},
	SV08021502: {"codigo":"SV08021502","nombre":"Zona 02 (San Juan Tepezontes)","nombrePadre":"La Paz"},
	SV12020601: {"codigo":"SV12020601","nombre":"Zona 01 (Guatajiagua)","nombrePadre":"Moraz\u00e1n"},
	SV01030401: {"codigo":"SV01030401","nombre":"Zona 01 (Soyapango)","nombrePadre":"San Salvador"},
	SV06031104: {"codigo":"SV06031104","nombre":"Zona 04 (Cuisnahuat)","nombrePadre":"Sonsonate"},
	SV09032203: {"codigo":"SV09032203","nombre":"Zona 03 (La Laguna)","nombrePadre":"Chalatenango"},
	SV08021503: {"codigo":"SV08021503","nombre":"Zona 03 (San Juan Tepezontes)","nombrePadre":"La Paz"},
	SV12020602: {"codigo":"SV12020602","nombre":"Zona 02 (Guatajiagua)","nombrePadre":"Moraz\u00e1n"},
	SV01030402: {"codigo":"SV01030402","nombre":"Zona 02 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030201: {"codigo":"SV06030201","nombre":"Zona 01 (Izalco)","nombrePadre":"Sonsonate"},
	SV09031601: {"codigo":"SV09031601","nombre":"Zona 01 (Las Flores)","nombrePadre":"Chalatenango"},
	SV08021504: {"codigo":"SV08021504","nombre":"Zona 04 (San Juan Tepezontes)","nombrePadre":"La Paz"},
	SV12020603: {"codigo":"SV12020603","nombre":"Zona 03 (Guatajiagua)","nombrePadre":"Moraz\u00e1n"},
	SV01030403: {"codigo":"SV01030403","nombre":"Zona 03 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030202: {"codigo":"SV06030202","nombre":"Zona 02 (Izalco)","nombrePadre":"Sonsonate"},
	SV09031602: {"codigo":"SV09031602","nombre":"Zona 02 (Las Flores)","nombrePadre":"Chalatenango"},
	SV08022201: {"codigo":"SV08022201","nombre":"Zona 01 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020604: {"codigo":"SV12020604","nombre":"Zona 04 (Guatajiagua)","nombrePadre":"Moraz\u00e1n"},
	SV01030404: {"codigo":"SV01030404","nombre":"Zona 04 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030203: {"codigo":"SV06030203","nombre":"Zona 03 (Izalco)","nombrePadre":"Sonsonate"},
	SV09031301: {"codigo":"SV09031301","nombre":"Zona 01 (Las Vueltas)","nombrePadre":"Chalatenango"},
	SV08022202: {"codigo":"SV08022202","nombre":"Zona 02 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020605: {"codigo":"SV12020605","nombre":"Zona 05 (Guatajiagua)","nombrePadre":"Moraz\u00e1n"},
	SV01030405: {"codigo":"SV01030405","nombre":"Zona 05 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030204: {"codigo":"SV06030204","nombre":"Zona 04 (Izalco)","nombrePadre":"Sonsonate"},
	SV09031801: {"codigo":"SV09031801","nombre":"Zona 01 (Nombre de Jes\u00fas)","nombrePadre":"Chalatenango"},
	SV08022203: {"codigo":"SV08022203","nombre":"Zona 03 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020606: {"codigo":"SV12020606","nombre":"Zona 06 (Guatajiagua)","nombrePadre":"Moraz\u00e1n"},
	SV01030406: {"codigo":"SV01030406","nombre":"Zona 06 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030205: {"codigo":"SV06030205","nombre":"Zona 05 (Izalco)","nombrePadre":"Sonsonate"},
	SV09031802: {"codigo":"SV09031802","nombre":"Zona 02 (Nombre de Jes\u00fas)","nombrePadre":"Chalatenango"},
	SV08022204: {"codigo":"SV08022204","nombre":"Zona 04 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020201: {"codigo":"SV12020201","nombre":"Zona 01 (Jocoro)","nombrePadre":"Moraz\u00e1n"},
	SV01030407: {"codigo":"SV01030407","nombre":"Zona 07 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030701: {"codigo":"SV06030701","nombre":"Zona 01 (San Juli\u00e1n)","nombrePadre":"Sonsonate"},
	SV09031803: {"codigo":"SV09031803","nombre":"Zona 03 (Nombre de Jes\u00fas)","nombrePadre":"Chalatenango"},
	SV08022205: {"codigo":"SV08022205","nombre":"Zona 05 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020202: {"codigo":"SV12020202","nombre":"Zona 02 (Jocoro)","nombrePadre":"Moraz\u00e1n"},
	SV01030408: {"codigo":"SV01030408","nombre":"Zona 08 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030702: {"codigo":"SV06030702","nombre":"Zona 02 (San Juli\u00e1n)","nombrePadre":"Sonsonate"},
	SV09031201: {"codigo":"SV09031201","nombre":"Zona 01 (Nueva Trinidad)","nombrePadre":"Chalatenango"},
	SV08022206: {"codigo":"SV08022206","nombre":"Zona 06 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020203: {"codigo":"SV12020203","nombre":"Zona 03 (Jocoro)","nombrePadre":"Moraz\u00e1n"},
	SV01030409: {"codigo":"SV01030409","nombre":"Zona 09 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030703: {"codigo":"SV06030703","nombre":"Zona 03 (San Juli\u00e1n)","nombrePadre":"Sonsonate"},
	SV09031701: {"codigo":"SV09031701","nombre":"Zona 01 (Ojos de Agua)","nombrePadre":"Chalatenango"},
	SV08022207: {"codigo":"SV08022207","nombre":"Zona 07 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020204: {"codigo":"SV12020204","nombre":"Zona 04 (Jocoro)","nombrePadre":"Moraz\u00e1n"},
	SV01030410: {"codigo":"SV01030410","nombre":"Zona 10 (Soyapango)","nombrePadre":"San Salvador"},
	SV06030704: {"codigo":"SV06030704","nombre":"Zona 04 (San Juli\u00e1n)","nombrePadre":"Sonsonate"},
	SV09031702: {"codigo":"SV09031702","nombre":"Zona 02 (Ojos de Agua)","nombrePadre":"Chalatenango"},
	SV08022208: {"codigo":"SV08022208","nombre":"Zona 08 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020205: {"codigo":"SV12020205","nombre":"Zona 05 (Jocoro)","nombrePadre":"Moraz\u00e1n"},
	SV01030411: {"codigo":"SV01030411","nombre":"Zona 11 (Soyapango)","nombrePadre":"San Salvador"},
	SV06031401: {"codigo":"SV06031401","nombre":"Zona 01 (Santa Isabel Ishuat\u00e1n)","nombrePadre":"Sonsonate"},
	SV09031901: {"codigo":"SV09031901","nombre":"Zona 01 (Potonico)","nombrePadre":"Chalatenango"},
	SV08022209: {"codigo":"SV08022209","nombre":"Zona 09 (San Luis La Herradura)","nombrePadre":"La Paz"},
	SV12020206: {"codigo":"SV12020206","nombre":"Zona 06 (Jocoro)","nombrePadre":"Moraz\u00e1n"},
	SV01030412: {"codigo":"SV01030412","nombre":"Zona 12 (Soyapango)","nombrePadre":"San Salvador"},
	SV06031402: {"codigo":"SV06031402","nombre":"Zona 02 (Santa Isabel Ishuat\u00e1n)","nombrePadre":"Sonsonate"},
	SV09032401: {"codigo":"SV09032401","nombre":"Zona 01 (San Antonio de la Cruz)","nombrePadre":"Chalatenango"},
	SV08021401: {"codigo":"SV08021401","nombre":"Zona 01 (San Miguel Tepezontes)","nombrePadre":"La Paz"},
	SV12020207: {"codigo":"SV12020207","nombre":"Zona 07 (Jocoro)","nombrePadre":"Moraz\u00e1n"},
	SV01030413: {"codigo":"SV01030413","nombre":"Zona 13 (Soyapango)","nombrePadre":"San Salvador"},
	SV06031403: {"codigo":"SV06031403","nombre":"Zona 03 (Santa Isabel Ishuat\u00e1n)","nombrePadre":"Sonsonate"},
	SV09032402: {"codigo":"SV09032402","nombre":"Zona 02 (San Antonio de la Cruz)","nombrePadre":"Chalatenango"},
	SV08021402: {"codigo":"SV08021402","nombre":"Zona 02 (San Miguel Tepezontes)","nombrePadre":"La Paz"},
	SV12020208: {"codigo":"SV12020208","nombre":"Zona 08 (Jocoro)","nombrePadre":"Moraz\u00e1n"},
	SV01030414: {"codigo":"SV01030414","nombre":"Zona 14 (Soyapango)","nombrePadre":"San Salvador"},
	SV06010601: {"codigo":"SV06010601","nombre":"Zona 01 (Juay\u00faa)","nombrePadre":"Sonsonate"},
	SV09032901: {"codigo":"SV09032901","nombre":"Zona 01 (San Antonio Los Ranchos)","nombrePadre":"Chalatenango"},
	SV08021403: {"codigo":"SV08021403","nombre":"Zona 03 (San Miguel Tepezontes)","nombrePadre":"La Paz"},
	SV12021701: {"codigo":"SV12021701","nombre":"Zona 01 (Lolotiquillo)","nombrePadre":"Moraz\u00e1n"},
	SV01030415: {"codigo":"SV01030415","nombre":"Zona 15 (Soyapango)","nombrePadre":"San Salvador"},
	SV06010602: {"codigo":"SV06010602","nombre":"Zona 02 (Juay\u00faa)","nombrePadre":"Sonsonate"},
	SV09033301: {"codigo":"SV09033301","nombre":"Zona 01 (San Francisco Lempa)","nombrePadre":"Chalatenango"},
	SV08020601: {"codigo":"SV08020601","nombre":"Zona 01 (San Pedro Nonualco)","nombrePadre":"La Paz"},
	SV12021702: {"codigo":"SV12021702","nombre":"Zona 02 (Lolotiquillo)","nombrePadre":"Moraz\u00e1n"},
	SV01030416: {"codigo":"SV01030416","nombre":"Zona 16 (Soyapango)","nombrePadre":"San Salvador"},
	SV06010603: {"codigo":"SV06010603","nombre":"Zona 03 (Juay\u00faa)","nombrePadre":"Sonsonate"},
	SV09033302: {"codigo":"SV09033302","nombre":"Zona 02 (San Francisco Lempa)","nombrePadre":"Chalatenango"},
	SV08020602: {"codigo":"SV08020602","nombre":"Zona 02 (San Pedro Nonualco)","nombrePadre":"La Paz"},
	SV12021703: {"codigo":"SV12021703","nombre":"Zona 03 (Lolotiquillo)","nombrePadre":"Moraz\u00e1n"},
	SV01031301: {"codigo":"SV01031301","nombre":"Zona 01 (Tonacatepeque)","nombrePadre":"San Salvador"},
	SV06010604: {"codigo":"SV06010604","nombre":"Zona 04 (Juay\u00faa)","nombrePadre":"Sonsonate"},
	SV09032301: {"codigo":"SV09032301","nombre":"Zona 01 (San Isidro Labrador)","nombrePadre":"Chalatenango"},
	SV08020603: {"codigo":"SV08020603","nombre":"Zona 03 (San Pedro Nonualco)","nombrePadre":"La Paz"},
	SV12021704: {"codigo":"SV12021704","nombre":"Zona 04 (Lolotiquillo)","nombrePadre":"Moraz\u00e1n"},
	SV01031302: {"codigo":"SV01031302","nombre":"Zona 02 (Tonacatepeque)","nombrePadre":"San Salvador"},
	SV06010605: {"codigo":"SV06010605","nombre":"Zona 05 (Juay\u00faa)","nombrePadre":"Sonsonate"},
	SV09032302: {"codigo":"SV09032302","nombre":"Zona 02 (San Isidro Labrador)","nombrePadre":"Chalatenango"},
	SV08020604: {"codigo":"SV08020604","nombre":"Zona 04 (San Pedro Nonualco)","nombrePadre":"La Paz"},
	SV12021705: {"codigo":"SV12021705","nombre":"Zona 05 (Lolotiquillo)","nombrePadre":"Moraz\u00e1n"},
	SV01031303: {"codigo":"SV01031303","nombre":"Zona 03 (Tonacatepeque)","nombrePadre":"San Salvador"},
	SV06010501: {"codigo":"SV06010501","nombre":"Zona 01 (Nahuizalco)","nombrePadre":"Sonsonate"},
	SV09032801: {"codigo":"SV09032801","nombre":"Zona 01 (San Jos\u00e9 Cancasque)","nombrePadre":"Chalatenango"},
	SV08021101: {"codigo":"SV08021101","nombre":"Zona 01 (Santa Mar\u00eda Ostuma)","nombrePadre":"La Paz"},
	SV12021706: {"codigo":"SV12021706","nombre":"Zona 06 (Lolotiquillo)","nombrePadre":"Moraz\u00e1n"},
	SV01011201: {"codigo":"SV01011201","nombre":"Zona 01 (Aguilares)","nombrePadre":"San Salvador"},
	SV06010502: {"codigo":"SV06010502","nombre":"Zona 02 (Nahuizalco)","nombrePadre":"Sonsonate"},
	SV09032701: {"codigo":"SV09032701","nombre":"Zona 01 (San Luis del Carmen)","nombrePadre":"Chalatenango"},
	SV08021102: {"codigo":"SV08021102","nombre":"Zona 02 (Santa Mar\u00eda Ostuma)","nombrePadre":"La Paz"},
	SV12020901: {"codigo":"SV12020901","nombre":"Zona 01 (Osicala)","nombrePadre":"Moraz\u00e1n"},
	SV01011202: {"codigo":"SV01011202","nombre":"Zona 02 (Aguilares)","nombrePadre":"San Salvador"},
	SV06010503: {"codigo":"SV06010503","nombre":"Zona 03 (Nahuizalco)","nombrePadre":"Sonsonate"},
	SV09032601: {"codigo":"SV09032601","nombre":"Zona 01 (San Miguel de Mercedes)","nombrePadre":"Chalatenango"},
	SV08021103: {"codigo":"SV08021103","nombre":"Zona 03 (Santa Mar\u00eda Ostuma)","nombrePadre":"La Paz"},
	SV12020902: {"codigo":"SV12020902","nombre":"Zona 02 (Osicala)","nombrePadre":"Moraz\u00e1n"},
	SV01011203: {"codigo":"SV01011203","nombre":"Zona 03 (Aguilares)","nombrePadre":"San Salvador"},
	SV06010504: {"codigo":"SV06010504","nombre":"Zona 04 (Nahuizalco)","nombrePadre":"Sonsonate"},
	SV09032602: {"codigo":"SV09032602","nombre":"Zona 02 (San Miguel de Mercedes)","nombrePadre":"Chalatenango"},
	SV08020201: {"codigo":"SV08020201","nombre":"Zona 01 (Santiago Nonualco)","nombrePadre":"La Paz"},
	SV12020903: {"codigo":"SV12020903","nombre":"Zona 03 (Osicala)","nombrePadre":"Moraz\u00e1n"},
	SV01011204: {"codigo":"SV01011204","nombre":"Zona 04 (Aguilares)","nombrePadre":"San Salvador"},
	SV06011501: {"codigo":"SV06011501","nombre":"Zona 01 (Salcoatit\u00e1n)","nombrePadre":"Sonsonate"},
	SV09032603: {"codigo":"SV09032603","nombre":"Zona 03 (San Miguel de Mercedes)","nombrePadre":"Chalatenango"},
	SV08020202: {"codigo":"SV08020202","nombre":"Zona 02 (Santiago Nonualco)","nombrePadre":"La Paz"},
	SV12020904: {"codigo":"SV12020904","nombre":"Zona 04 (Osicala)","nombrePadre":"Moraz\u00e1n"},
	SV01011205: {"codigo":"SV01011205","nombre":"Zona 05 (Aguilares)","nombrePadre":"San Salvador"},
	SV06011502: {"codigo":"SV06011502","nombre":"Zona 02 (Salcoatit\u00e1n)","nombrePadre":"Sonsonate"},
	SV10011501: {"codigo":"SV10011501","nombre":"Zona 01 (Oratorio de Concepci\u00f3n)","nombrePadre":"Cuscatl\u00e1n"},
	SV08020203: {"codigo":"SV08020203","nombre":"Zona 03 (Santiago Nonualco)","nombrePadre":"La Paz"},
	SV12020905: {"codigo":"SV12020905","nombre":"Zona 05 (Osicala)","nombrePadre":"Moraz\u00e1n"},
	SV01011206: {"codigo":"SV01011206","nombre":"Zona 06 (Aguilares)","nombrePadre":"San Salvador"},
	SV06011201: {"codigo":"SV06011201","nombre":"Zona 01 (Santa Catarina Masahuat)","nombrePadre":"Sonsonate"},
	SV10011502: {"codigo":"SV10011502","nombre":"Zona 02 (Oratorio de Concepci\u00f3n)","nombrePadre":"Cuscatl\u00e1n"},
	SV08020204: {"codigo":"SV08020204","nombre":"Zona 04 (Santiago Nonualco)","nombrePadre":"La Paz"},
	SV12020906: {"codigo":"SV12020906","nombre":"Zona 06 (Osicala)","nombrePadre":"Moraz\u00e1n"},
	SV01011601: {"codigo":"SV01011601","nombre":"Zona 01 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06011202: {"codigo":"SV06011202","nombre":"Zona 02 (Santa Catarina Masahuat)","nombrePadre":"Sonsonate"},
	SV10011503: {"codigo":"SV10011503","nombre":"Zona 03 (Oratorio de Concepci\u00f3n)","nombrePadre":"Cuscatl\u00e1n"},
	SV08020205: {"codigo":"SV08020205","nombre":"Zona 05 (Santiago Nonualco)","nombrePadre":"La Paz"},
	SV12020907: {"codigo":"SV12020907","nombre":"Zona 07 (Osicala)","nombrePadre":"Moraz\u00e1n"},
	SV01011602: {"codigo":"SV01011602","nombre":"Zona 02 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06011203: {"codigo":"SV06011203","nombre":"Zona 03 (Santa Catarina Masahuat)","nombrePadre":"Sonsonate"},
	SV10011201: {"codigo":"SV10011201","nombre":"Zona 01 (San Bartolom\u00e9 Perulap\u00eda)","nombrePadre":"Cuscatl\u00e1n"},
	SV08020206: {"codigo":"SV08020206","nombre":"Zona 06 (Santiago Nonualco)","nombrePadre":"La Paz"},
	SV12022001: {"codigo":"SV12022001","nombre":"Zona 01 (San Carlos)","nombrePadre":"Moraz\u00e1n"},
	SV01011603: {"codigo":"SV01011603","nombre":"Zona 03 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040301: {"codigo":"SV06040301","nombre":"Zona 01 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10011202: {"codigo":"SV10011202","nombre":"Zona 02 (San Bartolom\u00e9 Perulap\u00eda)","nombrePadre":"Cuscatl\u00e1n"},
	SV08020207: {"codigo":"SV08020207","nombre":"Zona 07 (Santiago Nonualco)","nombrePadre":"La Paz"},
	SV12022002: {"codigo":"SV12022002","nombre":"Zona 02 (San Carlos)","nombrePadre":"Moraz\u00e1n"},
	SV01011604: {"codigo":"SV01011604","nombre":"Zona 04 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040302: {"codigo":"SV06040302","nombre":"Zona 02 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10011203: {"codigo":"SV10011203","nombre":"Zona 03 (San Bartolom\u00e9 Perulap\u00eda)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030301: {"codigo":"SV08030301","nombre":"Zona 01 (San Juan Nonualco)","nombrePadre":"La Paz"},
	SV12022003: {"codigo":"SV12022003","nombre":"Zona 03 (San Carlos)","nombrePadre":"Moraz\u00e1n"},
	SV01011605: {"codigo":"SV01011605","nombre":"Zona 05 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040303: {"codigo":"SV06040303","nombre":"Zona 03 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10010401: {"codigo":"SV10010401","nombre":"Zona 01 (San Jos\u00e9 Guayabal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030302: {"codigo":"SV08030302","nombre":"Zona 02 (San Juan Nonualco)","nombrePadre":"La Paz"},
	SV12022004: {"codigo":"SV12022004","nombre":"Zona 04 (San Carlos)","nombrePadre":"Moraz\u00e1n"},
	SV01011606: {"codigo":"SV01011606","nombre":"Zona 06 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040304: {"codigo":"SV06040304","nombre":"Zona 04 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10010402: {"codigo":"SV10010402","nombre":"Zona 02 (San Jos\u00e9 Guayabal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030303: {"codigo":"SV08030303","nombre":"Zona 03 (San Juan Nonualco)","nombrePadre":"La Paz"},
	SV12022005: {"codigo":"SV12022005","nombre":"Zona 05 (San Carlos)","nombrePadre":"Moraz\u00e1n"},
	SV01011607: {"codigo":"SV01011607","nombre":"Zona 07 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040305: {"codigo":"SV06040305","nombre":"Zona 05 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10010403: {"codigo":"SV10010403","nombre":"Zona 03 (San Jos\u00e9 Guayabal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030304: {"codigo":"SV08030304","nombre":"Zona 04 (San Juan Nonualco)","nombrePadre":"La Paz"},
	SV12020101: {"codigo":"SV12020101","nombre":"Zona 01 (San Francisco Gotera)","nombrePadre":"Moraz\u00e1n"},
	SV01011608: {"codigo":"SV01011608","nombre":"Zona 08 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040306: {"codigo":"SV06040306","nombre":"Zona 06 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10010404: {"codigo":"SV10010404","nombre":"Zona 04 (San Jos\u00e9 Guayabal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030305: {"codigo":"SV08030305","nombre":"Zona 05 (San Juan Nonualco)","nombrePadre":"La Paz"},
	SV12020102: {"codigo":"SV12020102","nombre":"Zona 02 (San Francisco Gotera)","nombrePadre":"Moraz\u00e1n"},
	SV01011609: {"codigo":"SV01011609","nombre":"Zona 09 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040307: {"codigo":"SV06040307","nombre":"Zona 07 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10010405: {"codigo":"SV10010405","nombre":"Zona 05 (San Jos\u00e9 Guayabal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030306: {"codigo":"SV08030306","nombre":"Zona 06 (San Juan Nonualco)","nombrePadre":"La Paz"},
	SV12020103: {"codigo":"SV12020103","nombre":"Zona 03 (San Francisco Gotera)","nombrePadre":"Moraz\u00e1n"},
	SV01011610: {"codigo":"SV01011610","nombre":"Zona 10 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040308: {"codigo":"SV06040308","nombre":"Zona 08 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10010406: {"codigo":"SV10010406","nombre":"Zona 06 (San Jos\u00e9 Guayabal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08031001: {"codigo":"SV08031001","nombre":"Zona 01 (San Rafael Obrajuelo)","nombrePadre":"La Paz"},
	SV12020104: {"codigo":"SV12020104","nombre":"Zona 04 (San Francisco Gotera)","nombrePadre":"Moraz\u00e1n"},
	SV01011611: {"codigo":"SV01011611","nombre":"Zona 11 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040309: {"codigo":"SV06040309","nombre":"Zona 09 (Acajutla)","nombrePadre":"Sonsonate"},
	SV10010301: {"codigo":"SV10010301","nombre":"Zona 01 (San Pedro Perulap\u00e1n)","nombrePadre":"Cuscatl\u00e1n"},
	SV08031002: {"codigo":"SV08031002","nombre":"Zona 02 (San Rafael Obrajuelo)","nombrePadre":"La Paz"},
	SV12020105: {"codigo":"SV12020105","nombre":"Zona 05 (San Francisco Gotera)","nombrePadre":"Moraz\u00e1n"},
	SV01011612: {"codigo":"SV01011612","nombre":"Zona 12 (El Paisnal)","nombrePadre":"San Salvador"},
	SV06040310: {"codigo":"SV06040310","nombre":"Zona 10 (Acajutla)","nombrePadre":"Sonsonate"},
	SV11010201: {"codigo":"SV11010201","nombre":"Zona 01 (Atiquizaya)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010302: {"codigo":"SV10010302","nombre":"Zona 02 (San Pedro Perulap\u00e1n)","nombrePadre":"Cuscatl\u00e1n"},
	SV08031003: {"codigo":"SV08031003","nombre":"Zona 03 (San Rafael Obrajuelo)","nombrePadre":"La Paz"},
	SV12020106: {"codigo":"SV12020106","nombre":"Zona 06 (San Francisco Gotera)","nombrePadre":"Moraz\u00e1n"},
	SV01011701: {"codigo":"SV01011701","nombre":"Zona 01 (Guazapa)","nombrePadre":"San Salvador"},
	SV05022301: {"codigo":"SV05022301","nombre":"Zona 01 (California)","nombrePadre":"Usulut\u00e1n"},
	SV11010202: {"codigo":"SV11010202","nombre":"Zona 02 (Atiquizaya)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010303: {"codigo":"SV10010303","nombre":"Zona 03 (San Pedro Perulap\u00e1n)","nombrePadre":"Cuscatl\u00e1n"},
	SV08031004: {"codigo":"SV08031004","nombre":"Zona 04 (San Rafael Obrajuelo)","nombrePadre":"La Paz"},
	SV12020107: {"codigo":"SV12020107","nombre":"Zona 07 (San Francisco Gotera)","nombrePadre":"Moraz\u00e1n"},
	SV01011702: {"codigo":"SV01011702","nombre":"Zona 02 (Guazapa)","nombrePadre":"San Salvador"},
	SV05022302: {"codigo":"SV05022302","nombre":"Zona 02 (California)","nombrePadre":"Usulut\u00e1n"},
	SV11010203: {"codigo":"SV11010203","nombre":"Zona 03 (Atiquizaya)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010304: {"codigo":"SV10010304","nombre":"Zona 04 (San Pedro Perulap\u00e1n)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030101: {"codigo":"SV08030101","nombre":"Zona 01 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12021301: {"codigo":"SV12021301","nombre":"Zona 01 (San Sim\u00f3n)","nombrePadre":"Moraz\u00e1n"},
	SV01011703: {"codigo":"SV01011703","nombre":"Zona 03 (Guazapa)","nombrePadre":"San Salvador"},
	SV05022303: {"codigo":"SV05022303","nombre":"Zona 03 (California)","nombrePadre":"Usulut\u00e1n"},
	SV11010204: {"codigo":"SV11010204","nombre":"Zona 04 (Atiquizaya)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010305: {"codigo":"SV10010305","nombre":"Zona 05 (San Pedro Perulap\u00e1n)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030102: {"codigo":"SV08030102","nombre":"Zona 02 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12021302: {"codigo":"SV12021302","nombre":"Zona 02 (San Sim\u00f3n)","nombrePadre":"Moraz\u00e1n"},
	SV01011704: {"codigo":"SV01011704","nombre":"Zona 04 (Guazapa)","nombrePadre":"San Salvador"},
	SV05021301: {"codigo":"SV05021301","nombre":"Zona 01 (Concepci\u00f3n Batres)","nombrePadre":"Usulut\u00e1n"},
	SV11010205: {"codigo":"SV11010205","nombre":"Zona 05 (Atiquizaya)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010201: {"codigo":"SV10010201","nombre":"Zona 01 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030103: {"codigo":"SV08030103","nombre":"Zona 03 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12021303: {"codigo":"SV12021303","nombre":"Zona 03 (San Sim\u00f3n)","nombrePadre":"Moraz\u00e1n"},
	SV01011705: {"codigo":"SV01011705","nombre":"Zona 05 (Guazapa)","nombrePadre":"San Salvador"},
	SV05021302: {"codigo":"SV05021302","nombre":"Zona 02 (Concepci\u00f3n Batres)","nombrePadre":"Usulut\u00e1n"},
	SV11010206: {"codigo":"SV11010206","nombre":"Zona 06 (Atiquizaya)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010202: {"codigo":"SV10010202","nombre":"Zona 02 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030104: {"codigo":"SV08030104","nombre":"Zona 04 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12021304: {"codigo":"SV12021304","nombre":"Zona 04 (San Sim\u00f3n)","nombrePadre":"Moraz\u00e1n"},
	SV01020901: {"codigo":"SV01020901","nombre":"Zona 01 (Apopa)","nombrePadre":"San Salvador"},
	SV05021303: {"codigo":"SV05021303","nombre":"Zona 03 (Concepci\u00f3n Batres)","nombrePadre":"Usulut\u00e1n"},
	SV11011201: {"codigo":"SV11011201","nombre":"Zona 01 (El Refugio)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010203: {"codigo":"SV10010203","nombre":"Zona 03 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030105: {"codigo":"SV08030105","nombre":"Zona 05 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12022301: {"codigo":"SV12022301","nombre":"Zona 01 (Sensembra)","nombrePadre":"Moraz\u00e1n"},
	SV01020902: {"codigo":"SV01020902","nombre":"Zona 02 (Apopa)","nombrePadre":"San Salvador"},
	SV05021304: {"codigo":"SV05021304","nombre":"Zona 04 (Concepci\u00f3n Batres)","nombrePadre":"Usulut\u00e1n"},
	SV11011202: {"codigo":"SV11011202","nombre":"Zona 02 (El Refugio)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010204: {"codigo":"SV10010204","nombre":"Zona 04 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030106: {"codigo":"SV08030106","nombre":"Zona 06 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12022302: {"codigo":"SV12022302","nombre":"Zona 02 (Sensembra)","nombrePadre":"Moraz\u00e1n"},
	SV01020903: {"codigo":"SV01020903","nombre":"Zona 03 (Apopa)","nombrePadre":"San Salvador"},
	SV05021305: {"codigo":"SV05021305","nombre":"Zona 05 (Concepci\u00f3n Batres)","nombrePadre":"Usulut\u00e1n"},
	SV11011203: {"codigo":"SV11011203","nombre":"Zona 03 (El Refugio)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010205: {"codigo":"SV10010205","nombre":"Zona 05 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030107: {"codigo":"SV08030107","nombre":"Zona 07 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12022303: {"codigo":"SV12022303","nombre":"Zona 03 (Sensembra)","nombrePadre":"Moraz\u00e1n"},
	SV01020904: {"codigo":"SV01020904","nombre":"Zona 04 (Apopa)","nombrePadre":"San Salvador"},
	SV05021306: {"codigo":"SV05021306","nombre":"Zona 06 (Concepci\u00f3n Batres)","nombrePadre":"Usulut\u00e1n"},
	SV11011204: {"codigo":"SV11011204","nombre":"Zona 04 (El Refugio)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010206: {"codigo":"SV10010206","nombre":"Zona 06 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030108: {"codigo":"SV08030108","nombre":"Zona 08 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12020401: {"codigo":"SV12020401","nombre":"Zona 01 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01020801: {"codigo":"SV01020801","nombre":"Zona 01 (Nejapa)","nombrePadre":"San Salvador"},
	SV05021801: {"codigo":"SV05021801","nombre":"Zona 01 (Ereguayqu\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11011001: {"codigo":"SV11011001","nombre":"Zona 01 (San Lorenzo)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010207: {"codigo":"SV10010207","nombre":"Zona 07 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08030109: {"codigo":"SV08030109","nombre":"Zona 09 (Zacatecoluca)","nombrePadre":"La Paz"},
	SV12020402: {"codigo":"SV12020402","nombre":"Zona 02 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01020802: {"codigo":"SV01020802","nombre":"Zona 02 (Nejapa)","nombrePadre":"San Salvador"},
	SV05021802: {"codigo":"SV05021802","nombre":"Zona 02 (Ereguayqu\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11011002: {"codigo":"SV11011002","nombre":"Zona 02 (San Lorenzo)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010208: {"codigo":"SV10010208","nombre":"Zona 08 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011701: {"codigo":"SV08011701","nombre":"Zona 01 (Cuyultit\u00e1n)","nombrePadre":"La Paz"},
	SV12020403: {"codigo":"SV12020403","nombre":"Zona 03 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01020803: {"codigo":"SV01020803","nombre":"Zona 03 (Nejapa)","nombrePadre":"San Salvador"},
	SV05021803: {"codigo":"SV05021803","nombre":"Zona 03 (Ereguayqu\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11011003: {"codigo":"SV11011003","nombre":"Zona 03 (San Lorenzo)","nombrePadre":"Ahuachap\u00e1n"},
	SV10010209: {"codigo":"SV10010209","nombre":"Zona 09 (Suchitoto)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011702: {"codigo":"SV08011702","nombre":"Zona 02 (Cuyultit\u00e1n)","nombrePadre":"La Paz"},
	SV12020404: {"codigo":"SV12020404","nombre":"Zona 04 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01020804: {"codigo":"SV01020804","nombre":"Zona 04 (Nejapa)","nombrePadre":"San Salvador"},
	SV05020701: {"codigo":"SV05020701","nombre":"Zona 01 (Jucuar\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11011004: {"codigo":"SV11011004","nombre":"Zona 04 (San Lorenzo)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020701: {"codigo":"SV10020701","nombre":"Zona 01 (Candelaria)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011703: {"codigo":"SV08011703","nombre":"Zona 03 (Cuyultit\u00e1n)","nombrePadre":"La Paz"},
	SV12020405: {"codigo":"SV12020405","nombre":"Zona 05 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01020805: {"codigo":"SV01020805","nombre":"Zona 05 (Nejapa)","nombrePadre":"San Salvador"},
	SV05020702: {"codigo":"SV05020702","nombre":"Zona 02 (Jucuar\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11011005: {"codigo":"SV11011005","nombre":"Zona 05 (San Lorenzo)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020702: {"codigo":"SV10020702","nombre":"Zona 02 (Candelaria)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011704: {"codigo":"SV08011704","nombre":"Zona 04 (Cuyultit\u00e1n)","nombrePadre":"La Paz"},
	SV12020406: {"codigo":"SV12020406","nombre":"Zona 06 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01020806: {"codigo":"SV01020806","nombre":"Zona 06 (Nejapa)","nombrePadre":"San Salvador"},
	SV05020703: {"codigo":"SV05020703","nombre":"Zona 03 (Jucuar\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11011006: {"codigo":"SV11011006","nombre":"Zona 06 (San Lorenzo)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020703: {"codigo":"SV10020703","nombre":"Zona 03 (Candelaria)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011705: {"codigo":"SV08011705","nombre":"Zona 05 (Cuyultit\u00e1n)","nombrePadre":"La Paz"},
	SV12020407: {"codigo":"SV12020407","nombre":"Zona 07 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01020807: {"codigo":"SV01020807","nombre":"Zona 07 (Nejapa)","nombrePadre":"San Salvador"},
	SV05020704: {"codigo":"SV05020704","nombre":"Zona 04 (Jucuar\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11011007: {"codigo":"SV11011007","nombre":"Zona 07 (San Lorenzo)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020704: {"codigo":"SV10020704","nombre":"Zona 04 (Candelaria)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010501: {"codigo":"SV08010501","nombre":"Zona 01 (Olocuilta)","nombrePadre":"La Paz"},
	SV12020408: {"codigo":"SV12020408","nombre":"Zona 08 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01051101: {"codigo":"SV01051101","nombre":"Zona 01 (Panchimalco)","nombrePadre":"San Salvador"},
	SV05020705: {"codigo":"SV05020705","nombre":"Zona 05 (Jucuar\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11011101: {"codigo":"SV11011101","nombre":"Zona 01 (Tur\u00edn)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020101: {"codigo":"SV10020101","nombre":"Zona 01 (Cojutepeque)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010502: {"codigo":"SV08010502","nombre":"Zona 02 (Olocuilta)","nombrePadre":"La Paz"},
	SV12020409: {"codigo":"SV12020409","nombre":"Zona 09 (Sociedad)","nombrePadre":"Moraz\u00e1n"},
	SV01051102: {"codigo":"SV01051102","nombre":"Zona 02 (Panchimalco)","nombrePadre":"San Salvador"},
	SV05020901: {"codigo":"SV05020901","nombre":"Zona 01 (Ozatl\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11011102: {"codigo":"SV11011102","nombre":"Zona 02 (Tur\u00edn)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020102: {"codigo":"SV10020102","nombre":"Zona 02 (Cojutepeque)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010503: {"codigo":"SV08010503","nombre":"Zona 03 (Olocuilta)","nombrePadre":"La Paz"},
	SV12021801: {"codigo":"SV12021801","nombre":"Zona 01 (Yamabal)","nombrePadre":"Moraz\u00e1n"},
	SV01051103: {"codigo":"SV01051103","nombre":"Zona 03 (Panchimalco)","nombrePadre":"San Salvador"},
	SV05020902: {"codigo":"SV05020902","nombre":"Zona 02 (Ozatl\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11011103: {"codigo":"SV11011103","nombre":"Zona 03 (Tur\u00edn)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020103: {"codigo":"SV10020103","nombre":"Zona 03 (Cojutepeque)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010504: {"codigo":"SV08010504","nombre":"Zona 04 (Olocuilta)","nombrePadre":"La Paz"},
	SV12021802: {"codigo":"SV12021802","nombre":"Zona 02 (Yamabal)","nombrePadre":"Moraz\u00e1n"},
	SV01051104: {"codigo":"SV01051104","nombre":"Zona 04 (Panchimalco)","nombrePadre":"San Salvador"},
	SV05020903: {"codigo":"SV05020903","nombre":"Zona 03 (Ozatl\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030701: {"codigo":"SV11030701","nombre":"Zona 01 (Guaymango)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020104: {"codigo":"SV10020104","nombre":"Zona 04 (Cojutepeque)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010505: {"codigo":"SV08010505","nombre":"Zona 05 (Olocuilta)","nombrePadre":"La Paz"},
	SV12021803: {"codigo":"SV12021803","nombre":"Zona 03 (Yamabal)","nombrePadre":"Moraz\u00e1n"},
	SV01051105: {"codigo":"SV01051105","nombre":"Zona 05 (Panchimalco)","nombrePadre":"San Salvador"},
	SV05020904: {"codigo":"SV05020904","nombre":"Zona 04 (Ozatl\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030702: {"codigo":"SV11030702","nombre":"Zona 02 (Guaymango)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020105: {"codigo":"SV10020105","nombre":"Zona 05 (Cojutepeque)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010506: {"codigo":"SV08010506","nombre":"Zona 06 (Olocuilta)","nombrePadre":"La Paz"},
	SV12021901: {"codigo":"SV12021901","nombre":"Zona 01 (Yoloaiqu\u00edn)","nombrePadre":"Moraz\u00e1n"},
	SV01051106: {"codigo":"SV01051106","nombre":"Zona 06 (Panchimalco)","nombrePadre":"San Salvador"},
	SV05020905: {"codigo":"SV05020905","nombre":"Zona 05 (Ozatl\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030703: {"codigo":"SV11030703","nombre":"Zona 03 (Guaymango)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020106: {"codigo":"SV10020106","nombre":"Zona 06 (Cojutepeque)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010701: {"codigo":"SV08010701","nombre":"Zona 01 (San Francisco Chinameca)","nombrePadre":"La Paz"},
	SV12021902: {"codigo":"SV12021902","nombre":"Zona 02 (Yoloaiqu\u00edn)","nombrePadre":"Moraz\u00e1n"},
	SV01051901: {"codigo":"SV01051901","nombre":"Zona 01 (Rosario de Mora)","nombrePadre":"San Salvador"},
	SV05020906: {"codigo":"SV05020906","nombre":"Zona 06 (Ozatl\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030704: {"codigo":"SV11030704","nombre":"Zona 04 (Guaymango)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020107: {"codigo":"SV10020107","nombre":"Zona 07 (Cojutepeque)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010702: {"codigo":"SV08010702","nombre":"Zona 02 (San Francisco Chinameca)","nombrePadre":"La Paz"},
	SV03020501: {"codigo":"SV03020501","nombre":"Zona 01 (Chirilagua)","nombrePadre":"San Miguel"},
	SV01051902: {"codigo":"SV01051902","nombre":"Zona 02 (Rosario de Mora)","nombrePadre":"San Salvador"},
	SV05021701: {"codigo":"SV05021701","nombre":"Zona 01 (San Dionisio)","nombrePadre":"Usulut\u00e1n"},
	SV11030705: {"codigo":"SV11030705","nombre":"Zona 05 (Guaymango)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020801: {"codigo":"SV10020801","nombre":"Zona 01 (El Carmen)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010703: {"codigo":"SV08010703","nombre":"Zona 03 (San Francisco Chinameca)","nombrePadre":"La Paz"},
	SV03020502: {"codigo":"SV03020502","nombre":"Zona 02 (Chirilagua)","nombrePadre":"San Miguel"},
	SV01051903: {"codigo":"SV01051903","nombre":"Zona 03 (Rosario de Mora)","nombrePadre":"San Salvador"},
	SV05021702: {"codigo":"SV05021702","nombre":"Zona 02 (San Dionisio)","nombrePadre":"Usulut\u00e1n"},
	SV11030706: {"codigo":"SV11030706","nombre":"Zona 06 (Guaymango)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020802: {"codigo":"SV10020802","nombre":"Zona 02 (El Carmen)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010704: {"codigo":"SV08010704","nombre":"Zona 04 (San Francisco Chinameca)","nombrePadre":"La Paz"},
	SV03020503: {"codigo":"SV03020503","nombre":"Zona 03 (Chirilagua)","nombrePadre":"San Miguel"},
	SV01051904: {"codigo":"SV01051904","nombre":"Zona 04 (Rosario de Mora)","nombrePadre":"San Salvador"},
	SV05021703: {"codigo":"SV05021703","nombre":"Zona 03 (San Dionisio)","nombrePadre":"Usulut\u00e1n"},
	SV11030601: {"codigo":"SV11030601","nombre":"Zona 01 (Jujutla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020803: {"codigo":"SV10020803","nombre":"Zona 03 (El Carmen)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010705: {"codigo":"SV08010705","nombre":"Zona 05 (San Francisco Chinameca)","nombrePadre":"La Paz"},
	SV03020504: {"codigo":"SV03020504","nombre":"Zona 04 (Chirilagua)","nombrePadre":"San Miguel"},
	SV01051905: {"codigo":"SV01051905","nombre":"Zona 05 (Rosario de Mora)","nombrePadre":"San Salvador"},
	SV05020601: {"codigo":"SV05020601","nombre":"Zona 01 (Santa Elena)","nombrePadre":"Usulut\u00e1n"},
	SV11030602: {"codigo":"SV11030602","nombre":"Zona 02 (Jujutla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020804: {"codigo":"SV10020804","nombre":"Zona 04 (El Carmen)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010706: {"codigo":"SV08010706","nombre":"Zona 06 (San Francisco Chinameca)","nombrePadre":"La Paz"},
	SV03021901: {"codigo":"SV03021901","nombre":"Zona 01 (Comacar\u00e1n)","nombrePadre":"San Miguel"},
	SV01051906: {"codigo":"SV01051906","nombre":"Zona 06 (Rosario de Mora)","nombrePadre":"San Salvador"},
	SV05020602: {"codigo":"SV05020602","nombre":"Zona 02 (Santa Elena)","nombrePadre":"Usulut\u00e1n"},
	SV11030603: {"codigo":"SV11030603","nombre":"Zona 03 (Jujutla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021401: {"codigo":"SV10021401","nombre":"Zona 01 (El Rosario)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010801: {"codigo":"SV08010801","nombre":"Zona 01 (San Juan Talpa)","nombrePadre":"La Paz"},
	SV03021902: {"codigo":"SV03021902","nombre":"Zona 02 (Comacar\u00e1n)","nombrePadre":"San Miguel"},
	SV01051907: {"codigo":"SV01051907","nombre":"Zona 07 (Rosario de Mora)","nombrePadre":"San Salvador"},
	SV05020603: {"codigo":"SV05020603","nombre":"Zona 03 (Santa Elena)","nombrePadre":"Usulut\u00e1n"},
	SV11030604: {"codigo":"SV11030604","nombre":"Zona 04 (Jujutla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021402: {"codigo":"SV10021402","nombre":"Zona 02 (El Rosario)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010802: {"codigo":"SV08010802","nombre":"Zona 02 (San Juan Talpa)","nombrePadre":"La Paz"},
	SV03021903: {"codigo":"SV03021903","nombre":"Zona 03 (Comacar\u00e1n)","nombrePadre":"San Miguel"},
	SV01051908: {"codigo":"SV01051908","nombre":"Zona 08 (Rosario de Mora)","nombrePadre":"San Salvador"},
	SV05020604: {"codigo":"SV05020604","nombre":"Zona 04 (Santa Elena)","nombrePadre":"Usulut\u00e1n"},
	SV11030605: {"codigo":"SV11030605","nombre":"Zona 05 (Jujutla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021403: {"codigo":"SV10021403","nombre":"Zona 03 (El Rosario)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010803: {"codigo":"SV08010803","nombre":"Zona 03 (San Juan Talpa)","nombrePadre":"La Paz"},
	SV03020801: {"codigo":"SV03020801","nombre":"Zona 01 (Moncagua)","nombrePadre":"San Miguel"},
	SV13010401: {"codigo":"SV13010401","nombre":"Zona 01 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05020605: {"codigo":"SV05020605","nombre":"Zona 05 (Santa Elena)","nombrePadre":"Usulut\u00e1n"},
	SV11030606: {"codigo":"SV11030606","nombre":"Zona 06 (Jujutla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020901: {"codigo":"SV10020901","nombre":"Zona 01 (Monte San Juan)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010804: {"codigo":"SV08010804","nombre":"Zona 04 (San Juan Talpa)","nombrePadre":"La Paz"},
	SV03020802: {"codigo":"SV03020802","nombre":"Zona 02 (Moncagua)","nombrePadre":"San Miguel"},
	SV13010402: {"codigo":"SV13010402","nombre":"Zona 02 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05020606: {"codigo":"SV05020606","nombre":"Zona 06 (Santa Elena)","nombrePadre":"Usulut\u00e1n"},
	SV11030607: {"codigo":"SV11030607","nombre":"Zona 07 (Jujutla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020902: {"codigo":"SV10020902","nombre":"Zona 02 (Monte San Juan)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010805: {"codigo":"SV08010805","nombre":"Zona 05 (San Juan Talpa)","nombrePadre":"La Paz"},
	SV03020803: {"codigo":"SV03020803","nombre":"Zona 03 (Moncagua)","nombrePadre":"San Miguel"},
	SV13010403: {"codigo":"SV13010403","nombre":"Zona 03 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05020607: {"codigo":"SV05020607","nombre":"Zona 07 (Santa Elena)","nombrePadre":"Usulut\u00e1n"},
	SV11030608: {"codigo":"SV11030608","nombre":"Zona 08 (Jujutla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020903: {"codigo":"SV10020903","nombre":"Zona 03 (Monte San Juan)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011201: {"codigo":"SV08011201","nombre":"Zona 01 (San Luis Talpa)","nombrePadre":"La Paz"},
	SV03021401: {"codigo":"SV03021401","nombre":"Zona 01 (Quelepa)","nombrePadre":"San Miguel"},
	SV13010404: {"codigo":"SV13010404","nombre":"Zona 04 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05021901: {"codigo":"SV05021901","nombre":"Zona 01 (Santa Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11030301: {"codigo":"SV11030301","nombre":"Zona 01 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020904: {"codigo":"SV10020904","nombre":"Zona 04 (Monte San Juan)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011202: {"codigo":"SV08011202","nombre":"Zona 02 (San Luis Talpa)","nombrePadre":"La Paz"},
	SV03021402: {"codigo":"SV03021402","nombre":"Zona 02 (Quelepa)","nombrePadre":"San Miguel"},
	SV13010405: {"codigo":"SV13010405","nombre":"Zona 05 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05021902: {"codigo":"SV05021902","nombre":"Zona 02 (Santa Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11030302: {"codigo":"SV11030302","nombre":"Zona 02 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020905: {"codigo":"SV10020905","nombre":"Zona 05 (Monte San Juan)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011203: {"codigo":"SV08011203","nombre":"Zona 03 (San Luis Talpa)","nombrePadre":"La Paz"},
	SV03021403: {"codigo":"SV03021403","nombre":"Zona 03 (Quelepa)","nombrePadre":"San Miguel"},
	SV13010406: {"codigo":"SV13010406","nombre":"Zona 06 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05021903: {"codigo":"SV05021903","nombre":"Zona 03 (Santa Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11030303: {"codigo":"SV11030303","nombre":"Zona 03 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021001: {"codigo":"SV10021001","nombre":"Zona 01 (San Crist\u00f3bal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011204: {"codigo":"SV08011204","nombre":"Zona 04 (San Luis Talpa)","nombrePadre":"La Paz"},
	SV03021404: {"codigo":"SV03021404","nombre":"Zona 04 (Quelepa)","nombrePadre":"San Miguel"},
	SV13010407: {"codigo":"SV13010407","nombre":"Zona 07 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05021904: {"codigo":"SV05021904","nombre":"Zona 04 (Santa Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11030304: {"codigo":"SV11030304","nombre":"Zona 04 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021002: {"codigo":"SV10021002","nombre":"Zona 02 (San Crist\u00f3bal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011205: {"codigo":"SV08011205","nombre":"Zona 05 (San Luis Talpa)","nombrePadre":"La Paz"},
	SV03021405: {"codigo":"SV03021405","nombre":"Zona 05 (Quelepa)","nombrePadre":"San Miguel"},
	SV13010408: {"codigo":"SV13010408","nombre":"Zona 08 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05021601: {"codigo":"SV05021601","nombre":"Zona 01 (Tecap\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030305: {"codigo":"SV11030305","nombre":"Zona 05 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021003: {"codigo":"SV10021003","nombre":"Zona 03 (San Crist\u00f3bal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011206: {"codigo":"SV08011206","nombre":"Zona 06 (San Luis Talpa)","nombrePadre":"La Paz"},
	SV03020101: {"codigo":"SV03020101","nombre":"Zona 01 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010409: {"codigo":"SV13010409","nombre":"Zona 09 (Apastepeque)","nombrePadre":"San Vicente"},
	SV05021602: {"codigo":"SV05021602","nombre":"Zona 02 (Tecap\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030306: {"codigo":"SV11030306","nombre":"Zona 06 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021004: {"codigo":"SV10021004","nombre":"Zona 04 (San Crist\u00f3bal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010401: {"codigo":"SV08010401","nombre":"Zona 01 (San Pedro Masahuat)","nombrePadre":"La Paz"},
	SV03020102: {"codigo":"SV03020102","nombre":"Zona 02 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010501: {"codigo":"SV13010501","nombre":"Zona 01 (San Esteban Catarina)","nombrePadre":"San Vicente"},
	SV05020101: {"codigo":"SV05020101","nombre":"Zona 01 (Usulut\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030307: {"codigo":"SV11030307","nombre":"Zona 07 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021005: {"codigo":"SV10021005","nombre":"Zona 05 (San Crist\u00f3bal)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010402: {"codigo":"SV08010402","nombre":"Zona 02 (San Pedro Masahuat)","nombrePadre":"La Paz"},
	SV03020103: {"codigo":"SV03020103","nombre":"Zona 03 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010502: {"codigo":"SV13010502","nombre":"Zona 02 (San Esteban Catarina)","nombrePadre":"San Vicente"},
	SV05020102: {"codigo":"SV05020102","nombre":"Zona 02 (Usulut\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030308: {"codigo":"SV11030308","nombre":"Zona 08 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020601: {"codigo":"SV10020601","nombre":"Zona 01 (San Rafael Cedros)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010403: {"codigo":"SV08010403","nombre":"Zona 03 (San Pedro Masahuat)","nombrePadre":"La Paz"},
	SV03020104: {"codigo":"SV03020104","nombre":"Zona 04 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010503: {"codigo":"SV13010503","nombre":"Zona 03 (San Esteban Catarina)","nombrePadre":"San Vicente"},
	SV05020103: {"codigo":"SV05020103","nombre":"Zona 03 (Usulut\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030309: {"codigo":"SV11030309","nombre":"Zona 09 (San Francisco Men\u00e9ndez)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020602: {"codigo":"SV10020602","nombre":"Zona 02 (San Rafael Cedros)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010404: {"codigo":"SV08010404","nombre":"Zona 04 (San Pedro Masahuat)","nombrePadre":"La Paz"},
	SV03020105: {"codigo":"SV03020105","nombre":"Zona 05 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010601: {"codigo":"SV13010601","nombre":"Zona 01 (San Ildefonso)","nombrePadre":"San Vicente"},
	SV05020104: {"codigo":"SV05020104","nombre":"Zona 04 (Usulut\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030901: {"codigo":"SV11030901","nombre":"Zona 01 (San Pedro Puxtla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020603: {"codigo":"SV10020603","nombre":"Zona 03 (San Rafael Cedros)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010405: {"codigo":"SV08010405","nombre":"Zona 05 (San Pedro Masahuat)","nombrePadre":"La Paz"},
	SV03020106: {"codigo":"SV03020106","nombre":"Zona 06 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010602: {"codigo":"SV13010602","nombre":"Zona 02 (San Ildefonso)","nombrePadre":"San Vicente"},
	SV05020105: {"codigo":"SV05020105","nombre":"Zona 05 (Usulut\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030902: {"codigo":"SV11030902","nombre":"Zona 02 (San Pedro Puxtla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020604: {"codigo":"SV10020604","nombre":"Zona 04 (San Rafael Cedros)","nombrePadre":"Cuscatl\u00e1n"},
	SV08010406: {"codigo":"SV08010406","nombre":"Zona 06 (San Pedro Masahuat)","nombrePadre":"La Paz"},
	SV03020107: {"codigo":"SV03020107","nombre":"Zona 07 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010603: {"codigo":"SV13010603","nombre":"Zona 03 (San Ildefonso)","nombrePadre":"San Vicente"},
	SV05020106: {"codigo":"SV05020106","nombre":"Zona 06 (Usulut\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030903: {"codigo":"SV11030903","nombre":"Zona 03 (San Pedro Puxtla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020605: {"codigo":"SV10020605","nombre":"Zona 05 (San Rafael Cedros)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011601: {"codigo":"SV08011601","nombre":"Zona 01 (Tapalhuaca)","nombrePadre":"La Paz"},
	SV03020108: {"codigo":"SV03020108","nombre":"Zona 08 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010604: {"codigo":"SV13010604","nombre":"Zona 04 (San Ildefonso)","nombrePadre":"San Vicente"},
	SV05020107: {"codigo":"SV05020107","nombre":"Zona 07 (Usulut\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11030904: {"codigo":"SV11030904","nombre":"Zona 04 (San Pedro Puxtla)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020606: {"codigo":"SV10020606","nombre":"Zona 06 (San Rafael Cedros)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011602: {"codigo":"SV08011602","nombre":"Zona 02 (Tapalhuaca)","nombrePadre":"La Paz"},
	SV03020109: {"codigo":"SV03020109","nombre":"Zona 09 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010605: {"codigo":"SV13010605","nombre":"Zona 05 (San Ildefonso)","nombrePadre":"San Vicente"},
	SV05020108: {"codigo":"SV05020108","nombre":"Zona 08 (Usulut\u00e1n)","nombrePadre":"Usulut\u00e1n"},
	SV11020101: {"codigo":"SV11020101","nombre":"Zona 01 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020607: {"codigo":"SV10020607","nombre":"Zona 07 (San Rafael Cedros)","nombrePadre":"Cuscatl\u00e1n"},
	SV08011603: {"codigo":"SV08011603","nombre":"Zona 03 (Tapalhuaca)","nombrePadre":"La Paz"},
	SV03020110: {"codigo":"SV03020110","nombre":"Zona 10 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010801: {"codigo":"SV13010801","nombre":"Zona 01 (San Lorenzo)","nombrePadre":"San Vicente"},
	SV05011201: {"codigo":"SV05011201","nombre":"Zona 01 (Alegr\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020102: {"codigo":"SV11020102","nombre":"Zona 02 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021301: {"codigo":"SV10021301","nombre":"Zona 01 (San Ram\u00f3n)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010501: {"codigo":"SV07010501","nombre":"Zona 01 (Anamor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03020111: {"codigo":"SV03020111","nombre":"Zona 11 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010802: {"codigo":"SV13010802","nombre":"Zona 02 (San Lorenzo)","nombrePadre":"San Vicente"},
	SV05011202: {"codigo":"SV05011202","nombre":"Zona 02 (Alegr\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020103: {"codigo":"SV11020103","nombre":"Zona 03 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021302: {"codigo":"SV10021302","nombre":"Zona 02 (San Ram\u00f3n)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010502: {"codigo":"SV07010502","nombre":"Zona 02 (Anamor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03020112: {"codigo":"SV03020112","nombre":"Zona 12 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010803: {"codigo":"SV13010803","nombre":"Zona 03 (San Lorenzo)","nombrePadre":"San Vicente"},
	SV05011203: {"codigo":"SV05011203","nombre":"Zona 03 (Alegr\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020104: {"codigo":"SV11020104","nombre":"Zona 04 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021303: {"codigo":"SV10021303","nombre":"Zona 03 (San Ram\u00f3n)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010503: {"codigo":"SV07010503","nombre":"Zona 03 (Anamor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03020113: {"codigo":"SV03020113","nombre":"Zona 13 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010804: {"codigo":"SV13010804","nombre":"Zona 04 (San Lorenzo)","nombrePadre":"San Vicente"},
	SV05011204: {"codigo":"SV05011204","nombre":"Zona 04 (Alegr\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020105: {"codigo":"SV11020105","nombre":"Zona 05 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021304: {"codigo":"SV10021304","nombre":"Zona 04 (San Ram\u00f3n)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010504: {"codigo":"SV07010504","nombre":"Zona 04 (Anamor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03020114: {"codigo":"SV03020114","nombre":"Zona 14 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010301: {"codigo":"SV13010301","nombre":"Zona 01 (San Sebasti\u00e1n)","nombrePadre":"San Vicente"},
	SV05011205: {"codigo":"SV05011205","nombre":"Zona 05 (Alegr\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020106: {"codigo":"SV11020106","nombre":"Zona 06 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021305: {"codigo":"SV10021305","nombre":"Zona 05 (San Ram\u00f3n)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010505: {"codigo":"SV07010505","nombre":"Zona 05 (Anamor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03020115: {"codigo":"SV03020115","nombre":"Zona 15 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010302: {"codigo":"SV13010302","nombre":"Zona 02 (San Sebasti\u00e1n)","nombrePadre":"San Vicente"},
	SV05010301: {"codigo":"SV05010301","nombre":"Zona 01 (Berl\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11020107: {"codigo":"SV11020107","nombre":"Zona 07 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021601: {"codigo":"SV10021601","nombre":"Zona 01 (Santa Cruz Analquito)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010506: {"codigo":"SV07010506","nombre":"Zona 06 (Anamor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03020116: {"codigo":"SV03020116","nombre":"Zona 16 (San Miguel)","nombrePadre":"San Miguel"},
	SV13010303: {"codigo":"SV13010303","nombre":"Zona 03 (San Sebasti\u00e1n)","nombrePadre":"San Vicente"},
	SV05010302: {"codigo":"SV05010302","nombre":"Zona 02 (Berl\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11020108: {"codigo":"SV11020108","nombre":"Zona 08 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021602: {"codigo":"SV10021602","nombre":"Zona 02 (Santa Cruz Analquito)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010507: {"codigo":"SV07010507","nombre":"Zona 07 (Anamor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03021801: {"codigo":"SV03021801","nombre":"Zona 01 (Uluazapa)","nombrePadre":"San Miguel"},
	SV13010304: {"codigo":"SV13010304","nombre":"Zona 04 (San Sebasti\u00e1n)","nombrePadre":"San Vicente"},
	SV05010303: {"codigo":"SV05010303","nombre":"Zona 03 (Berl\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11020109: {"codigo":"SV11020109","nombre":"Zona 09 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021603: {"codigo":"SV10021603","nombre":"Zona 03 (Santa Cruz Analquito)","nombrePadre":"Cuscatl\u00e1n"},
	SV07011301: {"codigo":"SV07011301","nombre":"Zona 01 (Bol\u00edvar)","nombrePadre":"La Uni\u00f3n"},
	SV03021802: {"codigo":"SV03021802","nombre":"Zona 02 (Uluazapa)","nombrePadre":"San Miguel"},
	SV13010305: {"codigo":"SV13010305","nombre":"Zona 05 (San Sebasti\u00e1n)","nombrePadre":"San Vicente"},
	SV05010304: {"codigo":"SV05010304","nombre":"Zona 04 (Berl\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11020110: {"codigo":"SV11020110","nombre":"Zona 10 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021101: {"codigo":"SV10021101","nombre":"Zona 01 (Santa Cruz Michapa)","nombrePadre":"Cuscatl\u00e1n"},
	SV07011302: {"codigo":"SV07011302","nombre":"Zona 02 (Bol\u00edvar)","nombrePadre":"La Uni\u00f3n"},
	SV03021803: {"codigo":"SV03021803","nombre":"Zona 03 (Uluazapa)","nombrePadre":"San Miguel"},
	SV13010701: {"codigo":"SV13010701","nombre":"Zona 01 (Santa Clara)","nombrePadre":"San Vicente"},
	SV05010305: {"codigo":"SV05010305","nombre":"Zona 05 (Berl\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11020111: {"codigo":"SV11020111","nombre":"Zona 11 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021102: {"codigo":"SV10021102","nombre":"Zona 02 (Santa Cruz Michapa)","nombrePadre":"Cuscatl\u00e1n"},
	SV07011303: {"codigo":"SV07011303","nombre":"Zona 03 (Bol\u00edvar)","nombrePadre":"La Uni\u00f3n"},
	SV03021804: {"codigo":"SV03021804","nombre":"Zona 04 (Uluazapa)","nombrePadre":"San Miguel"},
	SV13010702: {"codigo":"SV13010702","nombre":"Zona 02 (Santa Clara)","nombrePadre":"San Vicente"},
	SV05010306: {"codigo":"SV05010306","nombre":"Zona 06 (Berl\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV11020112: {"codigo":"SV11020112","nombre":"Zona 12 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021103: {"codigo":"SV10021103","nombre":"Zona 03 (Santa Cruz Michapa)","nombrePadre":"Cuscatl\u00e1n"},
	SV07011401: {"codigo":"SV07011401","nombre":"Zona 01 (Concepci\u00f3n de Oriente)","nombrePadre":"La Uni\u00f3n"},
	SV03011301: {"codigo":"SV03011301","nombre":"Zona 01 (Carolina)","nombrePadre":"San Miguel"},
	SV13010703: {"codigo":"SV13010703","nombre":"Zona 03 (Santa Clara)","nombrePadre":"San Vicente"},
	SV05012101: {"codigo":"SV05012101","nombre":"Zona 01 (El Triunfo)","nombrePadre":"Usulut\u00e1n"},
	SV11020113: {"codigo":"SV11020113","nombre":"Zona 13 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021104: {"codigo":"SV10021104","nombre":"Zona 04 (Santa Cruz Michapa)","nombrePadre":"Cuscatl\u00e1n"},
	SV07011402: {"codigo":"SV07011402","nombre":"Zona 02 (Concepci\u00f3n de Oriente)","nombrePadre":"La Uni\u00f3n"},
	SV03011302: {"codigo":"SV03011302","nombre":"Zona 02 (Carolina)","nombrePadre":"San Miguel"},
	SV13010704: {"codigo":"SV13010704","nombre":"Zona 04 (Santa Clara)","nombrePadre":"San Vicente"},
	SV05012102: {"codigo":"SV05012102","nombre":"Zona 02 (El Triunfo)","nombrePadre":"Usulut\u00e1n"},
	SV11020114: {"codigo":"SV11020114","nombre":"Zona 14 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021105: {"codigo":"SV10021105","nombre":"Zona 05 (Santa Cruz Michapa)","nombrePadre":"Cuscatl\u00e1n"},
	SV07011403: {"codigo":"SV07011403","nombre":"Zona 03 (Concepci\u00f3n de Oriente)","nombrePadre":"La Uni\u00f3n"},
	SV03011303: {"codigo":"SV03011303","nombre":"Zona 03 (Carolina)","nombrePadre":"San Miguel"},
	SV13010705: {"codigo":"SV13010705","nombre":"Zona 05 (Santa Clara)","nombrePadre":"San Vicente"},
	SV05012103: {"codigo":"SV05012103","nombre":"Zona 03 (El Triunfo)","nombrePadre":"Usulut\u00e1n"},
	SV11020115: {"codigo":"SV11020115","nombre":"Zona 15 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021106: {"codigo":"SV10021106","nombre":"Zona 06 (Santa Cruz Michapa)","nombrePadre":"Cuscatl\u00e1n"},
	SV07011404: {"codigo":"SV07011404","nombre":"Zona 04 (Concepci\u00f3n de Oriente)","nombrePadre":"La Uni\u00f3n"},
	SV03011101: {"codigo":"SV03011101","nombre":"Zona 01 (Chapeltique)","nombrePadre":"San Miguel"},
	SV13010706: {"codigo":"SV13010706","nombre":"Zona 06 (Santa Clara)","nombrePadre":"San Vicente"},
	SV05011001: {"codigo":"SV05011001","nombre":"Zona 01 (Estanzuelas)","nombrePadre":"Usulut\u00e1n"},
	SV11020116: {"codigo":"SV11020116","nombre":"Zona 16 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10021107: {"codigo":"SV10021107","nombre":"Zona 07 (Santa Cruz Michapa)","nombrePadre":"Cuscatl\u00e1n"},
	SV07011405: {"codigo":"SV07011405","nombre":"Zona 05 (Concepci\u00f3n de Oriente)","nombrePadre":"La Uni\u00f3n"},
	SV03011102: {"codigo":"SV03011102","nombre":"Zona 02 (Chapeltique)","nombrePadre":"San Miguel"},
	SV13010707: {"codigo":"SV13010707","nombre":"Zona 07 (Santa Clara)","nombrePadre":"San Vicente"},
	SV05011002: {"codigo":"SV05011002","nombre":"Zona 02 (Estanzuelas)","nombrePadre":"Usulut\u00e1n"},
	SV11020117: {"codigo":"SV11020117","nombre":"Zona 17 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020501: {"codigo":"SV10020501","nombre":"Zona 01 (Tenancingo)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010801: {"codigo":"SV07010801","nombre":"Zona 01 (El Sauce)","nombrePadre":"La Uni\u00f3n"},
	SV03011103: {"codigo":"SV03011103","nombre":"Zona 03 (Chapeltique)","nombrePadre":"San Miguel"},
	SV13011101: {"codigo":"SV13011101","nombre":"Zona 01 (Santo Domingo)","nombrePadre":"San Vicente"},
	SV05011003: {"codigo":"SV05011003","nombre":"Zona 03 (Estanzuelas)","nombrePadre":"Usulut\u00e1n"},
	SV11020118: {"codigo":"SV11020118","nombre":"Zona 18 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020502: {"codigo":"SV10020502","nombre":"Zona 02 (Tenancingo)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010802: {"codigo":"SV07010802","nombre":"Zona 02 (El Sauce)","nombrePadre":"La Uni\u00f3n"},
	SV03011104: {"codigo":"SV03011104","nombre":"Zona 04 (Chapeltique)","nombrePadre":"San Miguel"},
	SV13011102: {"codigo":"SV13011102","nombre":"Zona 02 (Santo Domingo)","nombrePadre":"San Vicente"},
	SV05011004: {"codigo":"SV05011004","nombre":"Zona 04 (Estanzuelas)","nombrePadre":"Usulut\u00e1n"},
	SV11020119: {"codigo":"SV11020119","nombre":"Zona 19 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV10020503: {"codigo":"SV10020503","nombre":"Zona 03 (Tenancingo)","nombrePadre":"Cuscatl\u00e1n"},
	SV07010803: {"codigo":"SV07010803","nombre":"Zona 03 (El Sauce)","nombrePadre":"La Uni\u00f3n"},
	SV03011105: {"codigo":"SV03011105","nombre":"Zona 05 (Chapeltique)","nombrePadre":"San Miguel"},
	SV13011103: {"codigo":"SV13011103","nombre":"Zona 03 (Santo Domingo)","nombrePadre":"San Vicente"},
	SV05011005: {"codigo":"SV05011005","nombre":"Zona 05 (Estanzuelas)","nombrePadre":"Usulut\u00e1n"},
	SV11020120: {"codigo":"SV11020120","nombre":"Zona 20 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020301: {"codigo":"SV04020301","nombre":"Zona 01 (Ciudad Arce)","nombrePadre":"La Libertad"},
	SV07010804: {"codigo":"SV07010804","nombre":"Zona 04 (El Sauce)","nombrePadre":"La Uni\u00f3n"},
	SV03011106: {"codigo":"SV03011106","nombre":"Zona 06 (Chapeltique)","nombrePadre":"San Miguel"},
	SV13011104: {"codigo":"SV13011104","nombre":"Zona 04 (Santo Domingo)","nombrePadre":"San Vicente"},
	SV05010501: {"codigo":"SV05010501","nombre":"Zona 01 (Jucuapa)","nombrePadre":"Usulut\u00e1n"},
	SV11020121: {"codigo":"SV11020121","nombre":"Zona 21 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020302: {"codigo":"SV04020302","nombre":"Zona 02 (Ciudad Arce)","nombrePadre":"La Libertad"},
	SV07010805: {"codigo":"SV07010805","nombre":"Zona 05 (El Sauce)","nombrePadre":"La Uni\u00f3n"},
	SV03011107: {"codigo":"SV03011107","nombre":"Zona 07 (Chapeltique)","nombrePadre":"San Miguel"},
	SV13011105: {"codigo":"SV13011105","nombre":"Zona 05 (Santo Domingo)","nombrePadre":"San Vicente"},
	SV05010502: {"codigo":"SV05010502","nombre":"Zona 02 (Jucuapa)","nombrePadre":"Usulut\u00e1n"},
	SV11020122: {"codigo":"SV11020122","nombre":"Zona 22 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020303: {"codigo":"SV04020303","nombre":"Zona 03 (Ciudad Arce)","nombrePadre":"La Libertad"},
	SV07010901: {"codigo":"SV07010901","nombre":"Zona 01 (Lislique)","nombrePadre":"La Uni\u00f3n"},
	SV03010401: {"codigo":"SV03010401","nombre":"Zona 01 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13021001: {"codigo":"SV13021001","nombre":"Zona 01 (Guadalupe)","nombrePadre":"San Vicente"},
	SV05010503: {"codigo":"SV05010503","nombre":"Zona 03 (Jucuapa)","nombrePadre":"Usulut\u00e1n"},
	SV11020123: {"codigo":"SV11020123","nombre":"Zona 23 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020304: {"codigo":"SV04020304","nombre":"Zona 04 (Ciudad Arce)","nombrePadre":"La Libertad"},
	SV07010902: {"codigo":"SV07010902","nombre":"Zona 02 (Lislique)","nombrePadre":"La Uni\u00f3n"},
	SV03010402: {"codigo":"SV03010402","nombre":"Zona 02 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13021002: {"codigo":"SV13021002","nombre":"Zona 02 (Guadalupe)","nombrePadre":"San Vicente"},
	SV05010504: {"codigo":"SV05010504","nombre":"Zona 04 (Jucuapa)","nombrePadre":"Usulut\u00e1n"},
	SV11020124: {"codigo":"SV11020124","nombre":"Zona 24 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020305: {"codigo":"SV04020305","nombre":"Zona 05 (Ciudad Arce)","nombrePadre":"La Libertad"},
	SV07010903: {"codigo":"SV07010903","nombre":"Zona 03 (Lislique)","nombrePadre":"La Uni\u00f3n"},
	SV03010403: {"codigo":"SV03010403","nombre":"Zona 03 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13021003: {"codigo":"SV13021003","nombre":"Zona 03 (Guadalupe)","nombrePadre":"San Vicente"},
	SV05011101: {"codigo":"SV05011101","nombre":"Zona 01 (Mercedes Uma\u00f1a)","nombrePadre":"Usulut\u00e1n"},
	SV11020125: {"codigo":"SV11020125","nombre":"Zona 25 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020306: {"codigo":"SV04020306","nombre":"Zona 06 (Ciudad Arce)","nombrePadre":"La Libertad"},
	SV07010904: {"codigo":"SV07010904","nombre":"Zona 04 (Lislique)","nombrePadre":"La Uni\u00f3n"},
	SV03010404: {"codigo":"SV03010404","nombre":"Zona 04 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13021004: {"codigo":"SV13021004","nombre":"Zona 04 (Guadalupe)","nombrePadre":"San Vicente"},
	SV05011102: {"codigo":"SV05011102","nombre":"Zona 02 (Mercedes Uma\u00f1a)","nombrePadre":"Usulut\u00e1n"},
	SV11020126: {"codigo":"SV11020126","nombre":"Zona 26 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020307: {"codigo":"SV04020307","nombre":"Zona 07 (Ciudad Arce)","nombrePadre":"La Libertad"},
	SV07010905: {"codigo":"SV07010905","nombre":"Zona 05 (Lislique)","nombrePadre":"La Uni\u00f3n"},
	SV03010405: {"codigo":"SV03010405","nombre":"Zona 05 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13021005: {"codigo":"SV13021005","nombre":"Zona 05 (Guadalupe)","nombrePadre":"San Vicente"},
	SV05011103: {"codigo":"SV05011103","nombre":"Zona 03 (Mercedes Uma\u00f1a)","nombrePadre":"Usulut\u00e1n"},
	SV11020127: {"codigo":"SV11020127","nombre":"Zona 27 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020308: {"codigo":"SV04020308","nombre":"Zona 08 (Ciudad Arce)","nombrePadre":"La Libertad"},
	SV07011101: {"codigo":"SV07011101","nombre":"Zona 01 (Nueva Esparta)","nombrePadre":"La Uni\u00f3n"},
	SV03010406: {"codigo":"SV03010406","nombre":"Zona 06 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13021201: {"codigo":"SV13021201","nombre":"Zona 01 (San Cayetano Istepeque)","nombrePadre":"San Vicente"},
	SV05011104: {"codigo":"SV05011104","nombre":"Zona 04 (Mercedes Uma\u00f1a)","nombrePadre":"Usulut\u00e1n"},
	SV11020128: {"codigo":"SV11020128","nombre":"Zona 28 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020401: {"codigo":"SV04020401","nombre":"Zona 01 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07011102: {"codigo":"SV07011102","nombre":"Zona 02 (Nueva Esparta)","nombrePadre":"La Uni\u00f3n"},
	SV03010407: {"codigo":"SV03010407","nombre":"Zona 07 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13021202: {"codigo":"SV13021202","nombre":"Zona 02 (San Cayetano Istepeque)","nombrePadre":"San Vicente"},
	SV05011105: {"codigo":"SV05011105","nombre":"Zona 05 (Mercedes Uma\u00f1a)","nombrePadre":"Usulut\u00e1n"},
	SV11020129: {"codigo":"SV11020129","nombre":"Zona 29 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020402: {"codigo":"SV04020402","nombre":"Zona 02 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07011103: {"codigo":"SV07011103","nombre":"Zona 03 (Nueva Esparta)","nombrePadre":"La Uni\u00f3n"},
	SV03010408: {"codigo":"SV03010408","nombre":"Zona 08 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13020101: {"codigo":"SV13020101","nombre":"Zona 01 (San Vicente)","nombrePadre":"San Vicente"},
	SV05012001: {"codigo":"SV05012001","nombre":"Zona 01 (Nueva Granada)","nombrePadre":"Usulut\u00e1n"},
	SV11020130: {"codigo":"SV11020130","nombre":"Zona 30 (Ahuachap\u00e1n)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020403: {"codigo":"SV04020403","nombre":"Zona 03 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07011104: {"codigo":"SV07011104","nombre":"Zona 04 (Nueva Esparta)","nombrePadre":"La Uni\u00f3n"},
	SV03010409: {"codigo":"SV03010409","nombre":"Zona 09 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13020102: {"codigo":"SV13020102","nombre":"Zona 02 (San Vicente)","nombrePadre":"San Vicente"},
	SV05012002: {"codigo":"SV05012002","nombre":"Zona 02 (Nueva Granada)","nombrePadre":"Usulut\u00e1n"},
	SV11020801: {"codigo":"SV11020801","nombre":"Zona 01 (Apaneca)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020404: {"codigo":"SV04020404","nombre":"Zona 04 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07011105: {"codigo":"SV07011105","nombre":"Zona 05 (Nueva Esparta)","nombrePadre":"La Uni\u00f3n"},
	SV03010410: {"codigo":"SV03010410","nombre":"Zona 10 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13020103: {"codigo":"SV13020103","nombre":"Zona 03 (San Vicente)","nombrePadre":"San Vicente"},
	SV05012003: {"codigo":"SV05012003","nombre":"Zona 03 (Nueva Granada)","nombrePadre":"Usulut\u00e1n"},
	SV11020802: {"codigo":"SV11020802","nombre":"Zona 02 (Apaneca)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020405: {"codigo":"SV04020405","nombre":"Zona 05 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07010301: {"codigo":"SV07010301","nombre":"Zona 01 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03010411: {"codigo":"SV03010411","nombre":"Zona 11 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13020104: {"codigo":"SV13020104","nombre":"Zona 04 (San Vicente)","nombrePadre":"San Vicente"},
	SV05012004: {"codigo":"SV05012004","nombre":"Zona 04 (Nueva Granada)","nombrePadre":"Usulut\u00e1n"},
	SV11020803: {"codigo":"SV11020803","nombre":"Zona 03 (Apaneca)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020406: {"codigo":"SV04020406","nombre":"Zona 06 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07010302: {"codigo":"SV07010302","nombre":"Zona 02 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03010412: {"codigo":"SV03010412","nombre":"Zona 12 (Ciudad Barrios)","nombrePadre":"San Miguel"},
	SV13020105: {"codigo":"SV13020105","nombre":"Zona 05 (San Vicente)","nombrePadre":"San Vicente"},
	SV05012005: {"codigo":"SV05012005","nombre":"Zona 05 (Nueva Granada)","nombrePadre":"Usulut\u00e1n"},
	SV11020804: {"codigo":"SV11020804","nombre":"Zona 04 (Apaneca)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020407: {"codigo":"SV04020407","nombre":"Zona 07 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07010303: {"codigo":"SV07010303","nombre":"Zona 03 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03011601: {"codigo":"SV03011601","nombre":"Zona 01 (Nuevo Ed\u00e9n de San Juan)","nombrePadre":"San Miguel"},
	SV13020106: {"codigo":"SV13020106","nombre":"Zona 06 (San Vicente)","nombrePadre":"San Vicente"},
	SV05012201: {"codigo":"SV05012201","nombre":"Zona 01 (San Buenaventura)","nombrePadre":"Usulut\u00e1n"},
	SV11020805: {"codigo":"SV11020805","nombre":"Zona 05 (Apaneca)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020408: {"codigo":"SV04020408","nombre":"Zona 08 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07010304: {"codigo":"SV07010304","nombre":"Zona 04 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03011602: {"codigo":"SV03011602","nombre":"Zona 02 (Nuevo Ed\u00e9n de San Juan)","nombrePadre":"San Miguel"},
	SV13020107: {"codigo":"SV13020107","nombre":"Zona 07 (San Vicente)","nombrePadre":"San Vicente"},
	SV05012202: {"codigo":"SV05012202","nombre":"Zona 02 (San Buenaventura)","nombrePadre":"Usulut\u00e1n"},
	SV11020501: {"codigo":"SV11020501","nombre":"Zona 01 (Concepci\u00f3n de Ataco)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020409: {"codigo":"SV04020409","nombre":"Zona 09 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07010305: {"codigo":"SV07010305","nombre":"Zona 05 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03011603: {"codigo":"SV03011603","nombre":"Zona 03 (Nuevo Ed\u00e9n de San Juan)","nombrePadre":"San Miguel"},
	SV13020201: {"codigo":"SV13020201","nombre":"Zona 01 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05012203: {"codigo":"SV05012203","nombre":"Zona 03 (San Buenaventura)","nombrePadre":"Usulut\u00e1n"},
	SV11020502: {"codigo":"SV11020502","nombre":"Zona 02 (Concepci\u00f3n de Ataco)","nombrePadre":"Ahuachap\u00e1n"},
	SV04020410: {"codigo":"SV04020410","nombre":"Zona 10 (San Juan Opico)","nombrePadre":"La Libertad"},
	SV07010306: {"codigo":"SV07010306","nombre":"Zona 06 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03011604: {"codigo":"SV03011604","nombre":"Zona 04 (Nuevo Ed\u00e9n de San Juan)","nombrePadre":"San Miguel"},
	SV13020202: {"codigo":"SV13020202","nombre":"Zona 02 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05012204: {"codigo":"SV05012204","nombre":"Zona 04 (San Buenaventura)","nombrePadre":"Usulut\u00e1n"},
	SV11020503: {"codigo":"SV11020503","nombre":"Zona 03 (Concepci\u00f3n de Ataco)","nombrePadre":"Ahuachap\u00e1n"},
	SV04051401: {"codigo":"SV04051401","nombre":"Zona 01 (Chiltiup\u00e1n)","nombrePadre":"La Libertad"},
	SV07010307: {"codigo":"SV07010307","nombre":"Zona 07 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03011605: {"codigo":"SV03011605","nombre":"Zona 05 (Nuevo Ed\u00e9n de San Juan)","nombrePadre":"San Miguel"},
	SV13020203: {"codigo":"SV13020203","nombre":"Zona 03 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05010401: {"codigo":"SV05010401","nombre":"Zona 01 (Santiago de Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020504: {"codigo":"SV11020504","nombre":"Zona 04 (Concepci\u00f3n de Ataco)","nombrePadre":"Ahuachap\u00e1n"},
	SV04051402: {"codigo":"SV04051402","nombre":"Zona 02 (Chiltiup\u00e1n)","nombrePadre":"La Libertad"},
	SV07010308: {"codigo":"SV07010308","nombre":"Zona 08 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03011606: {"codigo":"SV03011606","nombre":"Zona 06 (Nuevo Ed\u00e9n de San Juan)","nombrePadre":"San Miguel"},
	SV13020204: {"codigo":"SV13020204","nombre":"Zona 04 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05010402: {"codigo":"SV05010402","nombre":"Zona 02 (Santiago de Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020401: {"codigo":"SV11020401","nombre":"Zona 01 (Tacuba)","nombrePadre":"Ahuachap\u00e1n"},
	SV04051403: {"codigo":"SV04051403","nombre":"Zona 03 (Chiltiup\u00e1n)","nombrePadre":"La Libertad"},
	SV07010309: {"codigo":"SV07010309","nombre":"Zona 09 (Pasaquina)","nombrePadre":"La Uni\u00f3n"},
	SV03011607: {"codigo":"SV03011607","nombre":"Zona 07 (Nuevo Ed\u00e9n de San Juan)","nombrePadre":"San Miguel"},
	SV13020205: {"codigo":"SV13020205","nombre":"Zona 05 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05010403: {"codigo":"SV05010403","nombre":"Zona 03 (Santiago de Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020402: {"codigo":"SV11020402","nombre":"Zona 02 (Tacuba)","nombrePadre":"Ahuachap\u00e1n"},
	SV04052201: {"codigo":"SV04052201","nombre":"Zona 01 (Jicalapa)","nombrePadre":"La Libertad"},
	SV07011201: {"codigo":"SV07011201","nombre":"Zona 01 (Polor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03011608: {"codigo":"SV03011608","nombre":"Zona 08 (Nuevo Ed\u00e9n de San Juan)","nombrePadre":"San Miguel"},
	SV13020206: {"codigo":"SV13020206","nombre":"Zona 06 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05010404: {"codigo":"SV05010404","nombre":"Zona 04 (Santiago de Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020403: {"codigo":"SV11020403","nombre":"Zona 03 (Tacuba)","nombrePadre":"Ahuachap\u00e1n"},
	SV04052202: {"codigo":"SV04052202","nombre":"Zona 02 (Jicalapa)","nombrePadre":"La Libertad"},
	SV07011202: {"codigo":"SV07011202","nombre":"Zona 02 (Polor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03012001: {"codigo":"SV03012001","nombre":"Zona 01 (San Antonio del Mosco)","nombrePadre":"San Miguel"},
	SV13020207: {"codigo":"SV13020207","nombre":"Zona 07 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05010405: {"codigo":"SV05010405","nombre":"Zona 05 (Santiago de Mar\u00eda)","nombrePadre":"Usulut\u00e1n"},
	SV11020404: {"codigo":"SV11020404","nombre":"Zona 04 (Tacuba)","nombrePadre":"Ahuachap\u00e1n"},
	SV04052203: {"codigo":"SV04052203","nombre":"Zona 03 (Jicalapa)","nombrePadre":"La Libertad"},
	SV07011203: {"codigo":"SV07011203","nombre":"Zona 03 (Polor\u00f3s)","nombrePadre":"La Uni\u00f3n"},
	SV03012002: {"codigo":"SV03012002","nombre":"Zona 02 (San Antonio del Mosco)","nombrePadre":"San Miguel"},
	SV13020208: {"codigo":"SV13020208","nombre":"Zona 08 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05030201: {"codigo":"SV05030201","nombre":"Zona 01 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV11020405: {"codigo":"SV11020405","nombre":"Zona 05 (Tacuba)","nombrePadre":"Ahuachap\u00e1n"},
	SV04050601: {"codigo":"SV04050601","nombre":"Zona 01 (La Libertad)","nombrePadre":"La Libertad"},
	SV07011601: {"codigo":"SV07011601","nombre":"Zona 01 (San Jos\u00e9 Las Fuentes)","nombrePadre":"La Uni\u00f3n"},
	SV03012003: {"codigo":"SV03012003","nombre":"Zona 03 (San Antonio del Mosco)","nombrePadre":"San Miguel"},
	SV13020209: {"codigo":"SV13020209","nombre":"Zona 09 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05030202: {"codigo":"SV05030202","nombre":"Zona 02 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV11020406: {"codigo":"SV11020406","nombre":"Zona 06 (Tacuba)","nombrePadre":"Ahuachap\u00e1n"},
	SV04050602: {"codigo":"SV04050602","nombre":"Zona 02 (La Libertad)","nombrePadre":"La Libertad"},
	SV07010201: {"codigo":"SV07010201","nombre":"Zona 01 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011201: {"codigo":"SV03011201","nombre":"Zona 01 (San Gerardo)","nombrePadre":"San Miguel"},
	SV13020210: {"codigo":"SV13020210","nombre":"Zona 10 (Tecoluca)","nombrePadre":"San Vicente"},
	SV05030203: {"codigo":"SV05030203","nombre":"Zona 03 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV11020407: {"codigo":"SV11020407","nombre":"Zona 07 (Tacuba)","nombrePadre":"Ahuachap\u00e1n"},
	SV04050603: {"codigo":"SV04050603","nombre":"Zona 03 (La Libertad)","nombrePadre":"La Libertad"},
	SV07010202: {"codigo":"SV07010202","nombre":"Zona 02 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011202: {"codigo":"SV03011202","nombre":"Zona 02 (San Gerardo)","nombrePadre":"San Miguel"},
	SV13021301: {"codigo":"SV13021301","nombre":"Zona 01 (Tepetit\u00e1n)","nombrePadre":"San Vicente"},
	SV05030204: {"codigo":"SV05030204","nombre":"Zona 04 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV11020408: {"codigo":"SV11020408","nombre":"Zona 08 (Tacuba)","nombrePadre":"Ahuachap\u00e1n"},
	SV04050604: {"codigo":"SV04050604","nombre":"Zona 04 (La Libertad)","nombrePadre":"La Libertad"},
	SV07010203: {"codigo":"SV07010203","nombre":"Zona 03 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011203: {"codigo":"SV03011203","nombre":"Zona 03 (San Gerardo)","nombrePadre":"San Miguel"},
	SV13021302: {"codigo":"SV13021302","nombre":"Zona 02 (Tepetit\u00e1n)","nombrePadre":"San Vicente"},
	SV05030205: {"codigo":"SV05030205","nombre":"Zona 05 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010701: {"codigo":"SV14010701","nombre":"Zona 01 (Dolores)","nombrePadre":"Caba\u00f1as"},
	SV04050605: {"codigo":"SV04050605","nombre":"Zona 05 (La Libertad)","nombrePadre":"La Libertad"},
	SV07010204: {"codigo":"SV07010204","nombre":"Zona 04 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011204: {"codigo":"SV03011204","nombre":"Zona 04 (San Gerardo)","nombrePadre":"San Miguel"},
	SV13021303: {"codigo":"SV13021303","nombre":"Zona 03 (Tepetit\u00e1n)","nombrePadre":"San Vicente"},
	SV05030206: {"codigo":"SV05030206","nombre":"Zona 06 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010702: {"codigo":"SV14010702","nombre":"Zona 02 (Dolores)","nombrePadre":"Caba\u00f1as"},
	SV04050606: {"codigo":"SV04050606","nombre":"Zona 06 (La Libertad)","nombrePadre":"La Libertad"},
	SV07010205: {"codigo":"SV07010205","nombre":"Zona 05 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011501: {"codigo":"SV03011501","nombre":"Zona 01 (San Luis de la Reina)","nombrePadre":"San Miguel"},
	SV13021304: {"codigo":"SV13021304","nombre":"Zona 04 (Tepetit\u00e1n)","nombrePadre":"San Vicente"},
	SV05030207: {"codigo":"SV05030207","nombre":"Zona 07 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010703: {"codigo":"SV14010703","nombre":"Zona 03 (Dolores)","nombrePadre":"Caba\u00f1as"},
	SV04050607: {"codigo":"SV04050607","nombre":"Zona 07 (La Libertad)","nombrePadre":"La Libertad"},
	SV07010206: {"codigo":"SV07010206","nombre":"Zona 06 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011502: {"codigo":"SV03011502","nombre":"Zona 02 (San Luis de la Reina)","nombrePadre":"San Miguel"},
	SV13020901: {"codigo":"SV13020901","nombre":"Zona 01 (Verapaz)","nombrePadre":"San Vicente"},
	SV05030208: {"codigo":"SV05030208","nombre":"Zona 08 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010704: {"codigo":"SV14010704","nombre":"Zona 04 (Dolores)","nombrePadre":"Caba\u00f1as"},
	SV04050608: {"codigo":"SV04050608","nombre":"Zona 08 (La Libertad)","nombrePadre":"La Libertad"},
	SV07010207: {"codigo":"SV07010207","nombre":"Zona 07 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011503: {"codigo":"SV03011503","nombre":"Zona 03 (San Luis de la Reina)","nombrePadre":"San Miguel"},
	SV13020902: {"codigo":"SV13020902","nombre":"Zona 02 (Verapaz)","nombrePadre":"San Vicente"},
	SV05030209: {"codigo":"SV05030209","nombre":"Zona 09 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010901: {"codigo":"SV14010901","nombre":"Zona 01 (Guacotecti)","nombrePadre":"Caba\u00f1as"},
	SV04051601: {"codigo":"SV04051601","nombre":"Zona 01 (Tamanique)","nombrePadre":"La Libertad"},
	SV07010208: {"codigo":"SV07010208","nombre":"Zona 08 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011504: {"codigo":"SV03011504","nombre":"Zona 04 (San Luis de la Reina)","nombrePadre":"San Miguel"},
	SV13020903: {"codigo":"SV13020903","nombre":"Zona 03 (Verapaz)","nombrePadre":"San Vicente"},
	SV05030210: {"codigo":"SV05030210","nombre":"Zona 10 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010902: {"codigo":"SV14010902","nombre":"Zona 02 (Guacotecti)","nombrePadre":"Caba\u00f1as"},
	SV04051602: {"codigo":"SV04051602","nombre":"Zona 02 (Tamanique)","nombrePadre":"La Libertad"},
	SV07010209: {"codigo":"SV07010209","nombre":"Zona 09 (Santa Rosa de Lima)","nombrePadre":"La Uni\u00f3n"},
	SV03011505: {"codigo":"SV03011505","nombre":"Zona 05 (San Luis de la Reina)","nombrePadre":"San Miguel"},
	SV02020101: {"codigo":"SV02020101","nombre":"Zona 01 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05030211: {"codigo":"SV05030211","nombre":"Zona 11 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010903: {"codigo":"SV14010903","nombre":"Zona 03 (Guacotecti)","nombrePadre":"Caba\u00f1as"},
	SV04051603: {"codigo":"SV04051603","nombre":"Zona 03 (Tamanique)","nombrePadre":"La Libertad"},
	SV07020701: {"codigo":"SV07020701","nombre":"Zona 01 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03010601: {"codigo":"SV03010601","nombre":"Zona 01 (Sesori)","nombrePadre":"San Miguel"},
	SV02020102: {"codigo":"SV02020102","nombre":"Zona 02 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05030212: {"codigo":"SV05030212","nombre":"Zona 12 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010401: {"codigo":"SV14010401","nombre":"Zona 01 (San Isidro)","nombrePadre":"Caba\u00f1as"},
	SV04051604: {"codigo":"SV04051604","nombre":"Zona 04 (Tamanique)","nombrePadre":"La Libertad"},
	SV07020702: {"codigo":"SV07020702","nombre":"Zona 02 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03010602: {"codigo":"SV03010602","nombre":"Zona 02 (Sesori)","nombrePadre":"San Miguel"},
	SV02020103: {"codigo":"SV02020103","nombre":"Zona 03 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05030213: {"codigo":"SV05030213","nombre":"Zona 13 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010402: {"codigo":"SV14010402","nombre":"Zona 02 (San Isidro)","nombrePadre":"Caba\u00f1as"},
	SV04051605: {"codigo":"SV04051605","nombre":"Zona 05 (Tamanique)","nombrePadre":"La Libertad"},
	SV07020703: {"codigo":"SV07020703","nombre":"Zona 03 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03010603: {"codigo":"SV03010603","nombre":"Zona 03 (Sesori)","nombrePadre":"San Miguel"},
	SV02020104: {"codigo":"SV02020104","nombre":"Zona 04 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05030214: {"codigo":"SV05030214","nombre":"Zona 14 (Jiquilisco)","nombrePadre":"Usulut\u00e1n"},
	SV14010403: {"codigo":"SV14010403","nombre":"Zona 03 (San Isidro)","nombrePadre":"Caba\u00f1as"},
	SV04051301: {"codigo":"SV04051301","nombre":"Zona 01 (Teotepeque)","nombrePadre":"La Libertad"},
	SV07020704: {"codigo":"SV07020704","nombre":"Zona 04 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03010604: {"codigo":"SV03010604","nombre":"Zona 04 (Sesori)","nombrePadre":"San Miguel"},
	SV02020105: {"codigo":"SV02020105","nombre":"Zona 05 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05031501: {"codigo":"SV05031501","nombre":"Zona 01 (Puerto El Triunfo)","nombrePadre":"Usulut\u00e1n"},
	SV14010101: {"codigo":"SV14010101","nombre":"Zona 01 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04051302: {"codigo":"SV04051302","nombre":"Zona 02 (Teotepeque)","nombrePadre":"La Libertad"},
	SV07020705: {"codigo":"SV07020705","nombre":"Zona 05 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03010605: {"codigo":"SV03010605","nombre":"Zona 05 (Sesori)","nombrePadre":"San Miguel"},
	SV02020106: {"codigo":"SV02020106","nombre":"Zona 06 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05031502: {"codigo":"SV05031502","nombre":"Zona 02 (Puerto El Triunfo)","nombrePadre":"Usulut\u00e1n"},
	SV14010102: {"codigo":"SV14010102","nombre":"Zona 02 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04051303: {"codigo":"SV04051303","nombre":"Zona 03 (Teotepeque)","nombrePadre":"La Libertad"},
	SV07020706: {"codigo":"SV07020706","nombre":"Zona 06 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03010606: {"codigo":"SV03010606","nombre":"Zona 06 (Sesori)","nombrePadre":"San Miguel"},
	SV02020107: {"codigo":"SV02020107","nombre":"Zona 07 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05031503: {"codigo":"SV05031503","nombre":"Zona 03 (Puerto El Triunfo)","nombrePadre":"Usulut\u00e1n"},
	SV14010103: {"codigo":"SV14010103","nombre":"Zona 03 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04040701: {"codigo":"SV04040701","nombre":"Zona 01 (Antiguo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07020707: {"codigo":"SV07020707","nombre":"Zona 07 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03030201: {"codigo":"SV03030201","nombre":"Zona 01 (Chinameca)","nombrePadre":"San Miguel"},
	SV02020108: {"codigo":"SV02020108","nombre":"Zona 08 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05031504: {"codigo":"SV05031504","nombre":"Zona 04 (Puerto El Triunfo)","nombrePadre":"Usulut\u00e1n"},
	SV14010104: {"codigo":"SV14010104","nombre":"Zona 04 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04040702: {"codigo":"SV04040702","nombre":"Zona 02 (Antiguo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07020708: {"codigo":"SV07020708","nombre":"Zona 08 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03030202: {"codigo":"SV03030202","nombre":"Zona 02 (Chinameca)","nombrePadre":"San Miguel"},
	SV02020109: {"codigo":"SV02020109","nombre":"Zona 09 (Santa Ana)","nombrePadre":"Santa Ana"},
	SV05030801: {"codigo":"SV05030801","nombre":"Zona 01 (San Agust\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV14010105: {"codigo":"SV14010105","nombre":"Zona 05 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04040703: {"codigo":"SV04040703","nombre":"Zona 03 (Antiguo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07020709: {"codigo":"SV07020709","nombre":"Zona 09 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03030203: {"codigo":"SV03030203","nombre":"Zona 03 (Chinameca)","nombrePadre":"San Miguel"},
	SV02030401: {"codigo":"SV02030401","nombre":"Zona 01 (Coatepeque)","nombrePadre":"Santa Ana"},
	SV05030802: {"codigo":"SV05030802","nombre":"Zona 02 (San Agust\u00edn)","nombrePadre":"Usulut\u00e1n"},
	SV14010106: {"codigo":"SV14010106","nombre":"Zona 06 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04040704: {"codigo":"SV04040704","nombre":"Zona 04 (Antiguo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07020710: {"codigo":"SV07020710","nombre":"Zona 10 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03030204: {"codigo":"SV03030204","nombre":"Zona 04 (Chinameca)","nombrePadre":"San Miguel"},
	SV02030402: {"codigo":"SV02030402","nombre":"Zona 02 (Coatepeque)","nombrePadre":"Santa Ana"},
	SV05031401: {"codigo":"SV05031401","nombre":"Zona 01 (San Francisco Javier)","nombrePadre":"Usulut\u00e1n"},
	SV14010107: {"codigo":"SV14010107","nombre":"Zona 07 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04040705: {"codigo":"SV04040705","nombre":"Zona 05 (Antiguo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07020711: {"codigo":"SV07020711","nombre":"Zona 11 (Conchagua)","nombrePadre":"La Uni\u00f3n"},
	SV03030205: {"codigo":"SV03030205","nombre":"Zona 05 (Chinameca)","nombrePadre":"San Miguel"},
	SV02030403: {"codigo":"SV02030403","nombre":"Zona 03 (Coatepeque)","nombrePadre":"Santa Ana"},
	SV05031402: {"codigo":"SV05031402","nombre":"Zona 02 (San Francisco Javier)","nombrePadre":"Usulut\u00e1n"},
	SV14010108: {"codigo":"SV14010108","nombre":"Zona 08 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04040706: {"codigo":"SV04040706","nombre":"Zona 06 (Antiguo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07020601: {"codigo":"SV07020601","nombre":"Zona 01 (El Carmen)","nombrePadre":"La Uni\u00f3n"},
	SV03030206: {"codigo":"SV03030206","nombre":"Zona 06 (Chinameca)","nombrePadre":"San Miguel"},
	SV02030404: {"codigo":"SV02030404","nombre":"Zona 04 (Coatepeque)","nombrePadre":"Santa Ana"},
	SV05031403: {"codigo":"SV05031403","nombre":"Zona 03 (San Francisco Javier)","nombrePadre":"Usulut\u00e1n"},
	SV14010109: {"codigo":"SV14010109","nombre":"Zona 09 (Sensuntepeque)","nombrePadre":"Caba\u00f1as"},
	SV04040707: {"codigo":"SV04040707","nombre":"Zona 07 (Antiguo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07020602: {"codigo":"SV07020602","nombre":"Zona 02 (El Carmen)","nombrePadre":"La Uni\u00f3n"},
	SV03030207: {"codigo":"SV03030207","nombre":"Zona 07 (Chinameca)","nombrePadre":"San Miguel"},
	SV02030405: {"codigo":"SV02030405","nombre":"Zona 05 (Coatepeque)","nombrePadre":"Santa Ana"},
	SV05031404: {"codigo":"SV05031404","nombre":"Zona 04 (San Francisco Javier)","nombrePadre":"Usulut\u00e1n"},
	SV14010301: {"codigo":"SV14010301","nombre":"Zona 01 (Victoria)","nombrePadre":"Caba\u00f1as"},
	SV04041101: {"codigo":"SV04041101","nombre":"Zona 01 (Huiz\u00facar)","nombrePadre":"La Libertad"},
	SV07020603: {"codigo":"SV07020603","nombre":"Zona 03 (El Carmen)","nombrePadre":"La Uni\u00f3n"},
	SV03030208: {"codigo":"SV03030208","nombre":"Zona 08 (Chinameca)","nombrePadre":"San Miguel"},
	SV02030406: {"codigo":"SV02030406","nombre":"Zona 06 (Coatepeque)","nombrePadre":"Santa Ana"},
	SV14010302: {"codigo":"SV14010302","nombre":"Zona 02 (Victoria)","nombrePadre":"Caba\u00f1as"},
	SV04041102: {"codigo":"SV04041102","nombre":"Zona 02 (Huiz\u00facar)","nombrePadre":"La Libertad"},
	SV07020604: {"codigo":"SV07020604","nombre":"Zona 04 (El Carmen)","nombrePadre":"La Uni\u00f3n"},
	SV03030301: {"codigo":"SV03030301","nombre":"Zona 01 (El Tr\u00e1nsito)","nombrePadre":"San Miguel"},
	SV02030407: {"codigo":"SV02030407","nombre":"Zona 07 (Coatepeque)","nombrePadre":"Santa Ana"},
	SV14010303: {"codigo":"SV14010303","nombre":"Zona 03 (Victoria)","nombrePadre":"Caba\u00f1as"},
	SV04041103: {"codigo":"SV04041103","nombre":"Zona 03 (Huiz\u00facar)","nombrePadre":"La Libertad"},
	SV07020605: {"codigo":"SV07020605","nombre":"Zona 05 (El Carmen)","nombrePadre":"La Uni\u00f3n"},
	SV03030302: {"codigo":"SV03030302","nombre":"Zona 02 (El Tr\u00e1nsito)","nombrePadre":"San Miguel"},
	SV02030408: {"codigo":"SV02030408","nombre":"Zona 08 (Coatepeque)","nombrePadre":"Santa Ana"},
	SV14010304: {"codigo":"SV14010304","nombre":"Zona 04 (Victoria)","nombrePadre":"Caba\u00f1as"},
	SV04041104: {"codigo":"SV04041104","nombre":"Zona 04 (Huiz\u00facar)","nombrePadre":"La Libertad"},
	SV07020606: {"codigo":"SV07020606","nombre":"Zona 06 (El Carmen)","nombrePadre":"La Uni\u00f3n"},
	SV03030303: {"codigo":"SV03030303","nombre":"Zona 03 (El Tr\u00e1nsito)","nombrePadre":"San Miguel"},
	SV02030501: {"codigo":"SV02030501","nombre":"Zona 01 (El Congo)","nombrePadre":"Santa Ana"},
	SV14010305: {"codigo":"SV14010305","nombre":"Zona 05 (Victoria)","nombrePadre":"Caba\u00f1as"},
	SV04041105: {"codigo":"SV04041105","nombre":"Zona 05 (Huiz\u00facar)","nombrePadre":"La Libertad"},
	SV07021501: {"codigo":"SV07021501","nombre":"Zona 01 (Intipuc\u00e1)","nombrePadre":"La Uni\u00f3n"},
	SV03030304: {"codigo":"SV03030304","nombre":"Zona 04 (El Tr\u00e1nsito)","nombrePadre":"San Miguel"},
	SV02030502: {"codigo":"SV02030502","nombre":"Zona 02 (El Congo)","nombrePadre":"Santa Ana"},
	SV14020801: {"codigo":"SV14020801","nombre":"Zona 01 (Cinquera)","nombrePadre":"Caba\u00f1as"},
	SV04041501: {"codigo":"SV04041501","nombre":"Zona 01 (Nuevo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07021502: {"codigo":"SV07021502","nombre":"Zona 02 (Intipuc\u00e1)","nombrePadre":"La Uni\u00f3n"},
	SV03030305: {"codigo":"SV03030305","nombre":"Zona 05 (El Tr\u00e1nsito)","nombrePadre":"San Miguel"},
	SV02030503: {"codigo":"SV02030503","nombre":"Zona 03 (El Congo)","nombrePadre":"Santa Ana"},
	SV14020201: {"codigo":"SV14020201","nombre":"Zona 01 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04041502: {"codigo":"SV04041502","nombre":"Zona 02 (Nuevo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07021503: {"codigo":"SV07021503","nombre":"Zona 03 (Intipuc\u00e1)","nombrePadre":"La Uni\u00f3n"},
	SV03030306: {"codigo":"SV03030306","nombre":"Zona 06 (El Tr\u00e1nsito)","nombrePadre":"San Miguel"},
	SV02030504: {"codigo":"SV02030504","nombre":"Zona 04 (El Congo)","nombrePadre":"Santa Ana"},
	SV14020202: {"codigo":"SV14020202","nombre":"Zona 02 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04041503: {"codigo":"SV04041503","nombre":"Zona 03 (Nuevo Cuscatl\u00e1n)","nombrePadre":"La Libertad"},
	SV07020101: {"codigo":"SV07020101","nombre":"Zona 01 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03030307: {"codigo":"SV03030307","nombre":"Zona 07 (El Tr\u00e1nsito)","nombrePadre":"San Miguel"},
	SV02030505: {"codigo":"SV02030505","nombre":"Zona 05 (El Congo)","nombrePadre":"Santa Ana"},
	SV14020203: {"codigo":"SV14020203","nombre":"Zona 03 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04041201: {"codigo":"SV04041201","nombre":"Zona 01 (San Jos\u00e9 Villanueva)","nombrePadre":"La Libertad"},
	SV07020102: {"codigo":"SV07020102","nombre":"Zona 02 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03030901: {"codigo":"SV03030901","nombre":"Zona 01 (Lolotique)","nombrePadre":"San Miguel"},
	SV02030506: {"codigo":"SV02030506","nombre":"Zona 06 (El Congo)","nombrePadre":"Santa Ana"},
	SV14020204: {"codigo":"SV14020204","nombre":"Zona 04 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04041202: {"codigo":"SV04041202","nombre":"Zona 02 (San Jos\u00e9 Villanueva)","nombrePadre":"La Libertad"},
	SV07020103: {"codigo":"SV07020103","nombre":"Zona 03 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03030902: {"codigo":"SV03030902","nombre":"Zona 02 (Lolotique)","nombrePadre":"San Miguel"},
	SV02030507: {"codigo":"SV02030507","nombre":"Zona 07 (El Congo)","nombrePadre":"Santa Ana"},
	SV14020205: {"codigo":"SV14020205","nombre":"Zona 05 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04041203: {"codigo":"SV04041203","nombre":"Zona 03 (San Jos\u00e9 Villanueva)","nombrePadre":"La Libertad"},
	SV07020104: {"codigo":"SV07020104","nombre":"Zona 04 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03030903: {"codigo":"SV03030903","nombre":"Zona 03 (Lolotique)","nombrePadre":"San Miguel"},
	SV02011201: {"codigo":"SV02011201","nombre":"Zona 01 (Masahuat)","nombrePadre":"Santa Ana"},
	SV14020206: {"codigo":"SV14020206","nombre":"Zona 06 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04041901: {"codigo":"SV04041901","nombre":"Zona 01 (Zaragoza)","nombrePadre":"La Libertad"},
	SV07020105: {"codigo":"SV07020105","nombre":"Zona 05 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03030904: {"codigo":"SV03030904","nombre":"Zona 04 (Lolotique)","nombrePadre":"San Miguel"},
	SV02011202: {"codigo":"SV02011202","nombre":"Zona 02 (Masahuat)","nombrePadre":"Santa Ana"},
	SV14020207: {"codigo":"SV14020207","nombre":"Zona 07 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04041902: {"codigo":"SV04041902","nombre":"Zona 02 (Zaragoza)","nombrePadre":"La Libertad"},
	SV07020106: {"codigo":"SV07020106","nombre":"Zona 06 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03030905: {"codigo":"SV03030905","nombre":"Zona 05 (Lolotique)","nombrePadre":"San Miguel"},
	SV02011203: {"codigo":"SV02011203","nombre":"Zona 03 (Masahuat)","nombrePadre":"Santa Ana"},
	SV14020208: {"codigo":"SV14020208","nombre":"Zona 08 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04041903: {"codigo":"SV04041903","nombre":"Zona 03 (Zaragoza)","nombrePadre":"La Libertad"},
	SV07020107: {"codigo":"SV07020107","nombre":"Zona 07 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03031701: {"codigo":"SV03031701","nombre":"Zona 01 (Nueva Guadalupe)","nombrePadre":"San Miguel"},
	SV02011204: {"codigo":"SV02011204","nombre":"Zona 04 (Masahuat)","nombrePadre":"Santa Ana"},
	SV14020209: {"codigo":"SV14020209","nombre":"Zona 09 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04010201: {"codigo":"SV04010201","nombre":"Zona 01 (Quezaltepeque)","nombrePadre":"La Libertad"},
	SV07020108: {"codigo":"SV07020108","nombre":"Zona 08 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03031702: {"codigo":"SV03031702","nombre":"Zona 02 (Nueva Guadalupe)","nombrePadre":"San Miguel"},
	SV02010301: {"codigo":"SV02010301","nombre":"Zona 01 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV14020210: {"codigo":"SV14020210","nombre":"Zona 10 (Ilobasco)","nombrePadre":"Caba\u00f1as"},
	SV04010202: {"codigo":"SV04010202","nombre":"Zona 02 (Quezaltepeque)","nombrePadre":"La Libertad"},
	SV07020109: {"codigo":"SV07020109","nombre":"Zona 09 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03031703: {"codigo":"SV03031703","nombre":"Zona 03 (Nueva Guadalupe)","nombrePadre":"San Miguel"},
	SV02010302: {"codigo":"SV02010302","nombre":"Zona 02 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV14020501: {"codigo":"SV14020501","nombre":"Zona 01 (Jutiapa)","nombrePadre":"Caba\u00f1as"},
	SV04010203: {"codigo":"SV04010203","nombre":"Zona 03 (Quezaltepeque)","nombrePadre":"La Libertad"},
	SV07020110: {"codigo":"SV07020110","nombre":"Zona 10 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03031001: {"codigo":"SV03031001","nombre":"Zona 01 (San Jorge)","nombrePadre":"San Miguel"},
	SV02010303: {"codigo":"SV02010303","nombre":"Zona 03 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV14020502: {"codigo":"SV14020502","nombre":"Zona 02 (Jutiapa)","nombrePadre":"Caba\u00f1as"},
	SV04010204: {"codigo":"SV04010204","nombre":"Zona 04 (Quezaltepeque)","nombrePadre":"La Libertad"},
	SV07020111: {"codigo":"SV07020111","nombre":"Zona 11 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03031002: {"codigo":"SV03031002","nombre":"Zona 02 (San Jorge)","nombrePadre":"San Miguel"},
	SV02010304: {"codigo":"SV02010304","nombre":"Zona 04 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV14020503: {"codigo":"SV14020503","nombre":"Zona 03 (Jutiapa)","nombrePadre":"Caba\u00f1as"},
	SV04010205: {"codigo":"SV04010205","nombre":"Zona 05 (Quezaltepeque)","nombrePadre":"La Libertad"},
	SV07020112: {"codigo":"SV07020112","nombre":"Zona 12 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03031003: {"codigo":"SV03031003","nombre":"Zona 03 (San Jorge)","nombrePadre":"San Miguel"},
	SV02010305: {"codigo":"SV02010305","nombre":"Zona 05 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV14020504: {"codigo":"SV14020504","nombre":"Zona 04 (Jutiapa)","nombrePadre":"Caba\u00f1as"},
	SV04010206: {"codigo":"SV04010206","nombre":"Zona 06 (Quezaltepeque)","nombrePadre":"La Libertad"},
	SV07020113: {"codigo":"SV07020113","nombre":"Zona 13 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03031004: {"codigo":"SV03031004","nombre":"Zona 04 (San Jorge)","nombrePadre":"San Miguel"},
	SV02010306: {"codigo":"SV02010306","nombre":"Zona 06 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV14020601: {"codigo":"SV14020601","nombre":"Zona 01 (Tejutepeque)","nombrePadre":"Caba\u00f1as"},
	SV04010207: {"codigo":"SV04010207","nombre":"Zona 07 (Quezaltepeque)","nombrePadre":"La Libertad"},
	SV07020114: {"codigo":"SV07020114","nombre":"Zona 14 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03031005: {"codigo":"SV03031005","nombre":"Zona 05 (San Jorge)","nombrePadre":"San Miguel"},
	SV02010307: {"codigo":"SV02010307","nombre":"Zona 07 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV14020602: {"codigo":"SV14020602","nombre":"Zona 02 (Tejutepeque)","nombrePadre":"Caba\u00f1as"},
	SV04010208: {"codigo":"SV04010208","nombre":"Zona 08 (Quezaltepeque)","nombrePadre":"La Libertad"},
	SV07020115: {"codigo":"SV07020115","nombre":"Zona 15 (La Uni\u00f3n)","nombrePadre":"La Uni\u00f3n"},
	SV03030701: {"codigo":"SV03030701","nombre":"Zona 01 (San Rafael Oriente)","nombrePadre":"San Miguel"},
	SV02010308: {"codigo":"SV02010308","nombre":"Zona 08 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV09021001: {"codigo":"SV09021001","nombre":"Zona 01 (Agua Caliente)","nombrePadre":"Chalatenango"},
	SV04012101: {"codigo":"SV04012101","nombre":"Zona 01 (San Mat\u00edas)","nombrePadre":"La Libertad"},
	SV07021801: {"codigo":"SV07021801","nombre":"Zona 01 (Meanguera del Golfo)","nombrePadre":"La Uni\u00f3n"},
	SV03030702: {"codigo":"SV03030702","nombre":"Zona 02 (San Rafael Oriente)","nombrePadre":"San Miguel"},
	SV02010309: {"codigo":"SV02010309","nombre":"Zona 09 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV09021002: {"codigo":"SV09021002","nombre":"Zona 02 (Agua Caliente)","nombrePadre":"Chalatenango"},
	SV04012102: {"codigo":"SV04012102","nombre":"Zona 02 (San Mat\u00edas)","nombrePadre":"La Libertad"},
	SV07021802: {"codigo":"SV07021802","nombre":"Zona 02 (Meanguera del Golfo)","nombrePadre":"La Uni\u00f3n"},
	SV03030703: {"codigo":"SV03030703","nombre":"Zona 03 (San Rafael Oriente)","nombrePadre":"San Miguel"},
	SV02010310: {"codigo":"SV02010310","nombre":"Zona 10 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV09021003: {"codigo":"SV09021003","nombre":"Zona 03 (Agua Caliente)","nombrePadre":"Chalatenango"},
	SV04012103: {"codigo":"SV04012103","nombre":"Zona 03 (San Mat\u00edas)","nombrePadre":"La Libertad"},
	SV07021803: {"codigo":"SV07021803","nombre":"Zona 03 (Meanguera del Golfo)","nombrePadre":"La Uni\u00f3n"},
	SV03030704: {"codigo":"SV03030704","nombre":"Zona 04 (San Rafael Oriente)","nombrePadre":"San Miguel"},
	SV02010311: {"codigo":"SV02010311","nombre":"Zona 11 (Metap\u00e1n)","nombrePadre":"Santa Ana"},
	SV09021004: {"codigo":"SV09021004","nombre":"Zona 04 (Agua Caliente)","nombrePadre":"Chalatenango"},
	SV04010901: {"codigo":"SV04010901","nombre":"Zona 01 (San Pablo Tacachico)","nombrePadre":"La Libertad"},
	SV07020401: {"codigo":"SV07020401","nombre":"Zona 01 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV03030705: {"codigo":"SV03030705","nombre":"Zona 05 (San Rafael Oriente)","nombrePadre":"San Miguel"},
	SV02010901: {"codigo":"SV02010901","nombre":"Zona 01 (Santa Rosa Guachipil\u00edn)","nombrePadre":"Santa Ana"},
	SV09021005: {"codigo":"SV09021005","nombre":"Zona 05 (Agua Caliente)","nombrePadre":"Chalatenango"},
	SV04010902: {"codigo":"SV04010902","nombre":"Zona 02 (San Pablo Tacachico)","nombrePadre":"La Libertad"},
	SV07020402: {"codigo":"SV07020402","nombre":"Zona 02 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV01041801: {"codigo":"SV01041801","nombre":"Zona 01 (Ayutuxtepeque)","nombrePadre":"San Salvador"},
	SV02010902: {"codigo":"SV02010902","nombre":"Zona 02 (Santa Rosa Guachipil\u00edn)","nombrePadre":"Santa Ana"},
	SV09021006: {"codigo":"SV09021006","nombre":"Zona 06 (Agua Caliente)","nombrePadre":"Chalatenango"},
	SV04010903: {"codigo":"SV04010903","nombre":"Zona 03 (San Pablo Tacachico)","nombrePadre":"La Libertad"},
	SV07020403: {"codigo":"SV07020403","nombre":"Zona 03 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV01041802: {"codigo":"SV01041802","nombre":"Zona 02 (Ayutuxtepeque)","nombrePadre":"San Salvador"},
	SV02010903: {"codigo":"SV02010903","nombre":"Zona 03 (Santa Rosa Guachipil\u00edn)","nombrePadre":"Santa Ana"},
	SV09020801: {"codigo":"SV09020801","nombre":"Zona 01 (Dulce Nombre de Mar\u00eda)","nombrePadre":"Chalatenango"},
	SV04010904: {"codigo":"SV04010904","nombre":"Zona 04 (San Pablo Tacachico)","nombrePadre":"La Libertad"},
	SV07020404: {"codigo":"SV07020404","nombre":"Zona 04 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV01041803: {"codigo":"SV01041803","nombre":"Zona 03 (Ayutuxtepeque)","nombrePadre":"San Salvador"},
	SV02010904: {"codigo":"SV02010904","nombre":"Zona 04 (Santa Rosa Guachipil\u00edn)","nombrePadre":"Santa Ana"},
	SV09020802: {"codigo":"SV09020802","nombre":"Zona 02 (Dulce Nombre de Mar\u00eda)","nombrePadre":"Chalatenango"},
	SV04010905: {"codigo":"SV04010905","nombre":"Zona 05 (San Pablo Tacachico)","nombrePadre":"La Libertad"},
	SV07020405: {"codigo":"SV07020405","nombre":"Zona 05 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV01041804: {"codigo":"SV01041804","nombre":"Zona 04 (Ayutuxtepeque)","nombrePadre":"San Salvador"},
	SV02010905: {"codigo":"SV02010905","nombre":"Zona 05 (Santa Rosa Guachipil\u00edn)","nombrePadre":"Santa Ana"},
	SV09022501: {"codigo":"SV09022501","nombre":"Zona 01 (El Para\u00edso)","nombrePadre":"Chalatenango"},
	SV04030501: {"codigo":"SV04030501","nombre":"Zona 01 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07020406: {"codigo":"SV07020406","nombre":"Zona 06 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV01041805: {"codigo":"SV01041805","nombre":"Zona 05 (Ayutuxtepeque)","nombrePadre":"San Salvador"},
	SV02010601: {"codigo":"SV02010601","nombre":"Zona 01 (Texistepeque)","nombrePadre":"Santa Ana"},
	SV09022502: {"codigo":"SV09022502","nombre":"Zona 02 (El Para\u00edso)","nombrePadre":"Chalatenango"},
	SV04030502: {"codigo":"SV04030502","nombre":"Zona 02 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07020407: {"codigo":"SV07020407","nombre":"Zona 07 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV01041806: {"codigo":"SV01041806","nombre":"Zona 06 (Ayutuxtepeque)","nombrePadre":"San Salvador"},
	SV02010602: {"codigo":"SV02010602","nombre":"Zona 02 (Texistepeque)","nombrePadre":"Santa Ana"},
	SV09022503: {"codigo":"SV09022503","nombre":"Zona 03 (El Para\u00edso)","nombrePadre":"Chalatenango"},
	SV04030503: {"codigo":"SV04030503","nombre":"Zona 03 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07020408: {"codigo":"SV07020408","nombre":"Zona 08 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV01040301: {"codigo":"SV01040301","nombre":"Zona 01 (Mejicanos)","nombrePadre":"San Salvador"},
	SV02010603: {"codigo":"SV02010603","nombre":"Zona 03 (Texistepeque)","nombrePadre":"Santa Ana"},
	SV09020501: {"codigo":"SV09020501","nombre":"Zona 01 (La Reina)","nombrePadre":"Chalatenango"},
	SV04030504: {"codigo":"SV04030504","nombre":"Zona 04 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07020409: {"codigo":"SV07020409","nombre":"Zona 09 (San Alejo)","nombrePadre":"La Uni\u00f3n"},
	SV01040302: {"codigo":"SV01040302","nombre":"Zona 02 (Mejicanos)","nombrePadre":"San Salvador"},
	SV02010604: {"codigo":"SV02010604","nombre":"Zona 04 (Texistepeque)","nombrePadre":"Santa Ana"},
	SV09020502: {"codigo":"SV09020502","nombre":"Zona 02 (La Reina)","nombrePadre":"Chalatenango"},
	SV04030505: {"codigo":"SV04030505","nombre":"Zona 05 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07021701: {"codigo":"SV07021701","nombre":"Zona 01 (Yayantique)","nombrePadre":"La Uni\u00f3n"},
	SV01040303: {"codigo":"SV01040303","nombre":"Zona 03 (Mejicanos)","nombrePadre":"San Salvador"},
	SV02010605: {"codigo":"SV02010605","nombre":"Zona 05 (Texistepeque)","nombrePadre":"Santa Ana"},
	SV09020503: {"codigo":"SV09020503","nombre":"Zona 03 (La Reina)","nombrePadre":"Chalatenango"},
	SV04030506: {"codigo":"SV04030506","nombre":"Zona 06 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07021702: {"codigo":"SV07021702","nombre":"Zona 02 (Yayantique)","nombrePadre":"La Uni\u00f3n"},
	SV01040304: {"codigo":"SV01040304","nombre":"Zona 04 (Mejicanos)","nombrePadre":"San Salvador"},
	SV02010606: {"codigo":"SV02010606","nombre":"Zona 06 (Texistepeque)","nombrePadre":"Santa Ana"},
	SV09020201: {"codigo":"SV09020201","nombre":"Zona 01 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030507: {"codigo":"SV04030507","nombre":"Zona 07 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07021703: {"codigo":"SV07021703","nombre":"Zona 03 (Yayantique)","nombrePadre":"La Uni\u00f3n"},
	SV01040305: {"codigo":"SV01040305","nombre":"Zona 05 (Mejicanos)","nombrePadre":"San Salvador"},
	SV02010607: {"codigo":"SV02010607","nombre":"Zona 07 (Texistepeque)","nombrePadre":"Santa Ana"},
	SV09020202: {"codigo":"SV09020202","nombre":"Zona 02 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030508: {"codigo":"SV04030508","nombre":"Zona 08 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07021001: {"codigo":"SV07021001","nombre":"Zona 01 (Yucuaiqu\u00edn)","nombrePadre":"La Uni\u00f3n"},
	SV01040306: {"codigo":"SV01040306","nombre":"Zona 06 (Mejicanos)","nombrePadre":"San Salvador"},
	SV02040701: {"codigo":"SV02040701","nombre":"Zona 01 (Candelaria de la Frontera)","nombrePadre":"Santa Ana"},
	SV09020203: {"codigo":"SV09020203","nombre":"Zona 03 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030509: {"codigo":"SV04030509","nombre":"Zona 09 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07021002: {"codigo":"SV07021002","nombre":"Zona 02 (Yucuaiqu\u00edn)","nombrePadre":"La Uni\u00f3n"},
	SV01050601: {"codigo":"SV01050601","nombre":"Zona 01 (San Marcos)","nombrePadre":"San Salvador"},
	SV02040702: {"codigo":"SV02040702","nombre":"Zona 02 (Candelaria de la Frontera)","nombrePadre":"Santa Ana"},
	SV09020204: {"codigo":"SV09020204","nombre":"Zona 04 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030510: {"codigo":"SV04030510","nombre":"Zona 10 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV07021003: {"codigo":"SV07021003","nombre":"Zona 03 (Yucuaiqu\u00edn)","nombrePadre":"La Uni\u00f3n"},
	SV01050602: {"codigo":"SV01050602","nombre":"Zona 02 (San Marcos)","nombrePadre":"San Salvador"},
	SV02040703: {"codigo":"SV02040703","nombre":"Zona 03 (Candelaria de la Frontera)","nombrePadre":"Santa Ana"},
	SV09020205: {"codigo":"SV09020205","nombre":"Zona 05 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030511: {"codigo":"SV04030511","nombre":"Zona 11 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV12011601: {"codigo":"SV12011601","nombre":"Zona 01 (Arambala)","nombrePadre":"Moraz\u00e1n"},
	SV01050603: {"codigo":"SV01050603","nombre":"Zona 03 (San Marcos)","nombrePadre":"San Salvador"},
	SV02040201: {"codigo":"SV02040201","nombre":"Zona 01 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09020206: {"codigo":"SV09020206","nombre":"Zona 06 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030512: {"codigo":"SV04030512","nombre":"Zona 12 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV12011602: {"codigo":"SV12011602","nombre":"Zona 02 (Arambala)","nombrePadre":"Moraz\u00e1n"},
	SV01050604: {"codigo":"SV01050604","nombre":"Zona 04 (San Marcos)","nombrePadre":"San Salvador"},
	SV02040202: {"codigo":"SV02040202","nombre":"Zona 02 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09020207: {"codigo":"SV09020207","nombre":"Zona 07 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030513: {"codigo":"SV04030513","nombre":"Zona 13 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV12010501: {"codigo":"SV12010501","nombre":"Zona 01 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040101: {"codigo":"SV01040101","nombre":"Zona 01 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040203: {"codigo":"SV02040203","nombre":"Zona 03 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09020208: {"codigo":"SV09020208","nombre":"Zona 08 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030514: {"codigo":"SV04030514","nombre":"Zona 14 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV12010502: {"codigo":"SV12010502","nombre":"Zona 02 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040102: {"codigo":"SV01040102","nombre":"Zona 02 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040204: {"codigo":"SV02040204","nombre":"Zona 04 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09020209: {"codigo":"SV09020209","nombre":"Zona 09 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030515: {"codigo":"SV04030515","nombre":"Zona 15 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV12010503: {"codigo":"SV12010503","nombre":"Zona 03 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040103: {"codigo":"SV01040103","nombre":"Zona 03 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040205: {"codigo":"SV02040205","nombre":"Zona 05 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09020210: {"codigo":"SV09020210","nombre":"Zona 10 (Nueva Concepci\u00f3n)","nombrePadre":"Chalatenango"},
	SV04030516: {"codigo":"SV04030516","nombre":"Zona 16 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV12010504: {"codigo":"SV12010504","nombre":"Zona 04 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040104: {"codigo":"SV01040104","nombre":"Zona 04 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040206: {"codigo":"SV02040206","nombre":"Zona 06 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09023101: {"codigo":"SV09023101","nombre":"Zona 01 (San Fernando)","nombrePadre":"Chalatenango"},
	SV04030517: {"codigo":"SV04030517","nombre":"Zona 17 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV12010505: {"codigo":"SV12010505","nombre":"Zona 05 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040105: {"codigo":"SV01040105","nombre":"Zona 05 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040207: {"codigo":"SV02040207","nombre":"Zona 07 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09023102: {"codigo":"SV09023102","nombre":"Zona 02 (San Fernando)","nombrePadre":"Chalatenango"},
	SV04030518: {"codigo":"SV04030518","nombre":"Zona 18 (Col\u00f3n)","nombrePadre":"La Libertad"},
	SV12010506: {"codigo":"SV12010506","nombre":"Zona 06 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040106: {"codigo":"SV01040106","nombre":"Zona 06 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040208: {"codigo":"SV02040208","nombre":"Zona 08 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09023103: {"codigo":"SV09023103","nombre":"Zona 03 (San Fernando)","nombrePadre":"Chalatenango"},
	SV04031001: {"codigo":"SV04031001","nombre":"Zona 01 (Jayaque)","nombrePadre":"La Libertad"},
	SV12010507: {"codigo":"SV12010507","nombre":"Zona 07 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040107: {"codigo":"SV01040107","nombre":"Zona 07 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040209: {"codigo":"SV02040209","nombre":"Zona 09 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09023104: {"codigo":"SV09023104","nombre":"Zona 04 (San Fernando)","nombrePadre":"Chalatenango"},
	SV04031002: {"codigo":"SV04031002","nombre":"Zona 02 (Jayaque)","nombrePadre":"La Libertad"},
	SV12010508: {"codigo":"SV12010508","nombre":"Zona 08 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040108: {"codigo":"SV01040108","nombre":"Zona 08 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040210: {"codigo":"SV02040210","nombre":"Zona 10 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09022001: {"codigo":"SV09022001","nombre":"Zona 01 (San Francisco Moraz\u00e1n)","nombrePadre":"Chalatenango"},
	SV04031003: {"codigo":"SV04031003","nombre":"Zona 03 (Jayaque)","nombrePadre":"La Libertad"},
	SV12010509: {"codigo":"SV12010509","nombre":"Zona 09 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040109: {"codigo":"SV01040109","nombre":"Zona 09 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040211: {"codigo":"SV02040211","nombre":"Zona 11 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09022002: {"codigo":"SV09022002","nombre":"Zona 02 (San Francisco Moraz\u00e1n)","nombrePadre":"Chalatenango"},
	SV04031004: {"codigo":"SV04031004","nombre":"Zona 04 (Jayaque)","nombrePadre":"La Libertad"},
	SV12010510: {"codigo":"SV12010510","nombre":"Zona 10 (Cacaopera)","nombrePadre":"Moraz\u00e1n"},
	SV01040110: {"codigo":"SV01040110","nombre":"Zona 10 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040212: {"codigo":"SV02040212","nombre":"Zona 12 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09021501: {"codigo":"SV09021501","nombre":"Zona 01 (San Rafael)","nombrePadre":"Chalatenango"},
	SV04031701: {"codigo":"SV04031701","nombre":"Zona 01 (Sacacoyo)","nombrePadre":"La Libertad"},
	SV12010301: {"codigo":"SV12010301","nombre":"Zona 01 (Corinto)","nombrePadre":"Moraz\u00e1n"},
	SV01040111: {"codigo":"SV01040111","nombre":"Zona 11 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040213: {"codigo":"SV02040213","nombre":"Zona 13 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09021502: {"codigo":"SV09021502","nombre":"Zona 02 (San Rafael)","nombrePadre":"Chalatenango"},
	SV04031702: {"codigo":"SV04031702","nombre":"Zona 02 (Sacacoyo)","nombrePadre":"La Libertad"},
	SV12010302: {"codigo":"SV12010302","nombre":"Zona 02 (Corinto)","nombrePadre":"Moraz\u00e1n"},
	SV01040112: {"codigo":"SV01040112","nombre":"Zona 12 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040214: {"codigo":"SV02040214","nombre":"Zona 14 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09021503: {"codigo":"SV09021503","nombre":"Zona 03 (San Rafael)","nombrePadre":"Chalatenango"},
	SV04031703: {"codigo":"SV04031703","nombre":"Zona 03 (Sacacoyo)","nombrePadre":"La Libertad"},
	SV12010303: {"codigo":"SV12010303","nombre":"Zona 03 (Corinto)","nombrePadre":"Moraz\u00e1n"},
	SV01040113: {"codigo":"SV01040113","nombre":"Zona 13 (San Salvador)","nombrePadre":"San Salvador"},
	SV02040215: {"codigo":"SV02040215","nombre":"Zona 15 (Chalchuapa)","nombrePadre":"Santa Ana"},
	SV09022101: {"codigo":"SV09022101","nombre":"Zona 01 (Santa Rita)","nombrePadre":"Chalatenango"},
	SV04031704: {"codigo":"SV04031704","nombre":"Zona 04 (Sacacoyo)","nombrePadre":"La Libertad"},
	SV12010304: {"codigo":"SV12010304","nombre":"Zona 04 (Corinto)","nombrePadre":"Moraz\u00e1n"},
	SV01040114: {"codigo":"SV01040114","nombre":"Zona 14 (San Salvador)","nombrePadre":"San Salvador"},
	SV02041101: {"codigo":"SV02041101","nombre":"Zona 01 (El Porvenir)","nombrePadre":"Santa Ana"},
	SV09022102: {"codigo":"SV09022102","nombre":"Zona 02 (Santa Rita)","nombrePadre":"Chalatenango"},
	SV04032001: {"codigo":"SV04032001","nombre":"Zona 01 (Talnique)","nombrePadre":"La Libertad"},
	SV12010305: {"codigo":"SV12010305","nombre":"Zona 05 (Corinto)","nombrePadre":"Moraz\u00e1n"},
	SV01040115: {"codigo":"SV01040115","nombre":"Zona 15 (San Salvador)","nombrePadre":"San Salvador"},
	SV02041102: {"codigo":"SV02041102","nombre":"Zona 02 (El Porvenir)","nombrePadre":"Santa Ana"},
	SV09020401: {"codigo":"SV09020401","nombre":"Zona 01 (Tejutla)","nombrePadre":"Chalatenango"},
	SV04032002: {"codigo":"SV04032002","nombre":"Zona 02 (Talnique)","nombrePadre":"La Libertad"},
	SV12010306: {"codigo":"SV12010306","nombre":"Zona 06 (Corinto)","nombrePadre":"Moraz\u00e1n"},
	SV01040116: {"codigo":"SV01040116","nombre":"Zona 16 (San Salvador)","nombrePadre":"San Salvador"},
	SV02041103: {"codigo":"SV02041103","nombre":"Zona 03 (El Porvenir)","nombrePadre":"Santa Ana"},
	SV09020402: {"codigo":"SV09020402","nombre":"Zona 02 (Tejutla)","nombrePadre":"Chalatenango"},
	SV04031801: {"codigo":"SV04031801","nombre":"Zona 01 (Tepecoyo)","nombrePadre":"La Libertad"},
	SV12010307: {"codigo":"SV12010307","nombre":"Zona 07 (Corinto)","nombrePadre":"Moraz\u00e1n"},
	SV01040117: {"codigo":"SV01040117","nombre":"Zona 17 (San Salvador)","nombrePadre":"San Salvador"},
	SV02041104: {"codigo":"SV02041104","nombre":"Zona 04 (El Porvenir)","nombrePadre":"Santa Ana"},
	SV09020403: {"codigo":"SV09020403","nombre":"Zona 03 (Tejutla)","nombrePadre":"Chalatenango"},
	SV04031802: {"codigo":"SV04031802","nombre":"Zona 02 (Tepecoyo)","nombrePadre":"La Libertad"},
	SV12012101: {"codigo":"SV12012101","nombre":"Zona 01 (El Rosario)","nombrePadre":"Moraz\u00e1n"},
	SV01040118: {"codigo":"SV01040118","nombre":"Zona 18 (San Salvador)","nombrePadre":"San Salvador"},
	SV02041301: {"codigo":"SV02041301","nombre":"Zona 01 (San Antonio Pajonal)","nombrePadre":"Santa Ana"},
	SV09020404: {"codigo":"SV09020404","nombre":"Zona 04 (Tejutla)","nombrePadre":"Chalatenango"},
	SV04031803: {"codigo":"SV04031803","nombre":"Zona 03 (Tepecoyo)","nombrePadre":"La Libertad"},
	SV12012102: {"codigo":"SV12012102","nombre":"Zona 02 (El Rosario)","nombrePadre":"Moraz\u00e1n"},
	SV01040119: {"codigo":"SV01040119","nombre":"Zona 19 (San Salvador)","nombrePadre":"San Salvador"},
	SV02041302: {"codigo":"SV02041302","nombre":"Zona 02 (San Antonio Pajonal)","nombrePadre":"Santa Ana"},
	SV09020405: {"codigo":"SV09020405","nombre":"Zona 05 (Tejutla)","nombrePadre":"Chalatenango"},
	SV04060801: {"codigo":"SV04060801","nombre":"Zona 01 (Comasagua)","nombrePadre":"La Libertad"},
	SV12011501: {"codigo":"SV12011501","nombre":"Zona 01 (Joateca)","nombrePadre":"Moraz\u00e1n"},
	SV01040120: {"codigo":"SV01040120","nombre":"Zona 20 (San Salvador)","nombrePadre":"San Salvador"},
	SV02041303: {"codigo":"SV02041303","nombre":"Zona 03 (San Antonio Pajonal)","nombrePadre":"Santa Ana"},
	SV09020406: {"codigo":"SV09020406","nombre":"Zona 06 (Tejutla)","nombrePadre":"Chalatenango"},
	SV04060802: {"codigo":"SV04060802","nombre":"Zona 02 (Comasagua)","nombrePadre":"La Libertad"},
	SV12011502: {"codigo":"SV12011502","nombre":"Zona 02 (Joateca)","nombrePadre":"Moraz\u00e1n"},
	SV01040121: {"codigo":"SV01040121","nombre":"Zona 21 (San Salvador)","nombrePadre":"San Salvador"},
	SV02041304: {"codigo":"SV02041304","nombre":"Zona 04 (San Antonio Pajonal)","nombrePadre":"Santa Ana"},
	SV09020407: {"codigo":"SV09020407","nombre":"Zona 07 (Tejutla)","nombrePadre":"Chalatenango"},
	SV04060803: {"codigo":"SV04060803","nombre":"Zona 03 (Comasagua)","nombrePadre":"La Libertad"},
	SV12011503: {"codigo":"SV12011503","nombre":"Zona 03 (Joateca)","nombrePadre":"Moraz\u00e1n"},
	SV01051501: {"codigo":"SV01051501","nombre":"Zona 01 (Santiago Texacuangos)","nombrePadre":"San Salvador"},
	SV02040801: {"codigo":"SV02040801","nombre":"Zona 01 (San Sebasti\u00e1n Salitrillo)","nombrePadre":"Santa Ana"},
	SV09020408: {"codigo":"SV09020408","nombre":"Zona 08 (Tejutla)","nombrePadre":"Chalatenango"},
	SV04060804: {"codigo":"SV04060804","nombre":"Zona 04 (Comasagua)","nombrePadre":"La Libertad"},
	SV12011504: {"codigo":"SV12011504","nombre":"Zona 04 (Joateca)","nombrePadre":"Moraz\u00e1n"},
	SV01051502: {"codigo":"SV01051502","nombre":"Zona 02 (Santiago Texacuangos)","nombrePadre":"San Salvador"},
	SV02040802: {"codigo":"SV02040802","nombre":"Zona 02 (San Sebasti\u00e1n Salitrillo)","nombrePadre":"Santa Ana"},
	SV09010901: {"codigo":"SV09010901","nombre":"Zona 01 (Cital\u00e1)","nombrePadre":"Chalatenango"},
	SV04060805: {"codigo":"SV04060805","nombre":"Zona 05 (Comasagua)","nombrePadre":"La Libertad"},
	SV12010801: {"codigo":"SV12010801","nombre":"Zona 01 (Jocoaitique)","nombrePadre":"Moraz\u00e1n"},
	SV01051503: {"codigo":"SV01051503","nombre":"Zona 03 (Santiago Texacuangos)","nombrePadre":"San Salvador"},
	SV02040803: {"codigo":"SV02040803","nombre":"Zona 03 (San Sebasti\u00e1n Salitrillo)","nombrePadre":"Santa Ana"},
	SV09010902: {"codigo":"SV09010902","nombre":"Zona 02 (Cital\u00e1)","nombrePadre":"Chalatenango"},
	SV04060101: {"codigo":"SV04060101","nombre":"Zona 01 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12010802: {"codigo":"SV12010802","nombre":"Zona 02 (Jocoaitique)","nombrePadre":"Moraz\u00e1n"},
	SV01051504: {"codigo":"SV01051504","nombre":"Zona 04 (Santiago Texacuangos)","nombrePadre":"San Salvador"},
	SV02040804: {"codigo":"SV02040804","nombre":"Zona 04 (San Sebasti\u00e1n Salitrillo)","nombrePadre":"Santa Ana"},
	SV09010903: {"codigo":"SV09010903","nombre":"Zona 03 (Cital\u00e1)","nombrePadre":"Chalatenango"},
	SV04060102: {"codigo":"SV04060102","nombre":"Zona 02 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12010803: {"codigo":"SV12010803","nombre":"Zona 03 (Jocoaitique)","nombrePadre":"Moraz\u00e1n"},
	SV01051505: {"codigo":"SV01051505","nombre":"Zona 05 (Santiago Texacuangos)","nombrePadre":"San Salvador"},
	SV02040805: {"codigo":"SV02040805","nombre":"Zona 05 (San Sebasti\u00e1n Salitrillo)","nombrePadre":"Santa Ana"},
	SV09010301: {"codigo":"SV09010301","nombre":"Zona 01 (La Palma)","nombrePadre":"Chalatenango"},
	SV04060103: {"codigo":"SV04060103","nombre":"Zona 03 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12010804: {"codigo":"SV12010804","nombre":"Zona 04 (Jocoaitique)","nombrePadre":"Moraz\u00e1n"},
	SV01051506: {"codigo":"SV01051506","nombre":"Zona 06 (Santiago Texacuangos)","nombrePadre":"San Salvador"},
	SV02040806: {"codigo":"SV02040806","nombre":"Zona 06 (San Sebasti\u00e1n Salitrillo)","nombrePadre":"Santa Ana"},
	SV09010302: {"codigo":"SV09010302","nombre":"Zona 02 (La Palma)","nombrePadre":"Chalatenango"},
	SV04060104: {"codigo":"SV04060104","nombre":"Zona 04 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12010805: {"codigo":"SV12010805","nombre":"Zona 05 (Jocoaitique)","nombrePadre":"Moraz\u00e1n"},
	SV01051401: {"codigo":"SV01051401","nombre":"Zona 01 (Santo Tom\u00e1s)","nombrePadre":"San Salvador"},
	SV02040807: {"codigo":"SV02040807","nombre":"Zona 07 (San Sebasti\u00e1n Salitrillo)","nombrePadre":"Santa Ana"},
	SV09010303: {"codigo":"SV09010303","nombre":"Zona 03 (La Palma)","nombrePadre":"Chalatenango"},
	SV04060105: {"codigo":"SV04060105","nombre":"Zona 05 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12010806: {"codigo":"SV12010806","nombre":"Zona 06 (Jocoaitique)","nombrePadre":"Moraz\u00e1n"},
	SV01051402: {"codigo":"SV01051402","nombre":"Zona 02 (Santo Tom\u00e1s)","nombrePadre":"San Salvador"},
	SV02041001: {"codigo":"SV02041001","nombre":"Zona 01 (Santiago de la Frontera)","nombrePadre":"Santa Ana"},
	SV09010304: {"codigo":"SV09010304","nombre":"Zona 04 (La Palma)","nombrePadre":"Chalatenango"},
	SV04060106: {"codigo":"SV04060106","nombre":"Zona 06 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12011101: {"codigo":"SV12011101","nombre":"Zona 01 (Meanguera)","nombrePadre":"Moraz\u00e1n"},
	SV01051403: {"codigo":"SV01051403","nombre":"Zona 03 (Santo Tom\u00e1s)","nombrePadre":"San Salvador"},
	SV02041002: {"codigo":"SV02041002","nombre":"Zona 02 (Santiago de la Frontera)","nombrePadre":"Santa Ana"},
	SV09010305: {"codigo":"SV09010305","nombre":"Zona 05 (La Palma)","nombrePadre":"Chalatenango"},
	SV04060107: {"codigo":"SV04060107","nombre":"Zona 07 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12011102: {"codigo":"SV12011102","nombre":"Zona 02 (Meanguera)","nombrePadre":"Moraz\u00e1n"},
	SV01051404: {"codigo":"SV01051404","nombre":"Zona 04 (Santo Tom\u00e1s)","nombrePadre":"San Salvador"},
	SV02041003: {"codigo":"SV02041003","nombre":"Zona 03 (Santiago de la Frontera)","nombrePadre":"Santa Ana"},
	SV09010306: {"codigo":"SV09010306","nombre":"Zona 06 (La Palma)","nombrePadre":"Chalatenango"},
	SV04060108: {"codigo":"SV04060108","nombre":"Zona 08 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12011103: {"codigo":"SV12011103","nombre":"Zona 03 (Meanguera)","nombrePadre":"Moraz\u00e1n"},
	SV01051405: {"codigo":"SV01051405","nombre":"Zona 05 (Santo Tom\u00e1s)","nombrePadre":"San Salvador"},
	SV06021001: {"codigo":"SV06021001","nombre":"Zona 01 (Nahulingo)","nombrePadre":"Sonsonate"},
	SV09010307: {"codigo":"SV09010307","nombre":"Zona 07 (La Palma)","nombrePadre":"Chalatenango"},
	SV04060109: {"codigo":"SV04060109","nombre":"Zona 09 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12011104: {"codigo":"SV12011104","nombre":"Zona 04 (Meanguera)","nombrePadre":"Moraz\u00e1n"},
	SV01051406: {"codigo":"SV01051406","nombre":"Zona 06 (Santo Tom\u00e1s)","nombrePadre":"San Salvador"},
	SV06021002: {"codigo":"SV06021002","nombre":"Zona 02 (Nahulingo)","nombrePadre":"Sonsonate"},
	SV09010308: {"codigo":"SV09010308","nombre":"Zona 08 (La Palma)","nombrePadre":"Chalatenango"},
	SV04060110: {"codigo":"SV04060110","nombre":"Zona 10 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12012201: {"codigo":"SV12012201","nombre":"Zona 01 (Perqu\u00edn)","nombrePadre":"Moraz\u00e1n"},
	SV01051407: {"codigo":"SV01051407","nombre":"Zona 07 (Santo Tom\u00e1s)","nombrePadre":"San Salvador"},
	SV06021003: {"codigo":"SV06021003","nombre":"Zona 03 (Nahulingo)","nombrePadre":"Sonsonate"},
	SV09010701: {"codigo":"SV09010701","nombre":"Zona 01 (San Ignacio)","nombrePadre":"Chalatenango"},
	SV04060111: {"codigo":"SV04060111","nombre":"Zona 11 (Santa Tecla)","nombrePadre":"La Libertad"},
	SV12012202: {"codigo":"SV12012202","nombre":"Zona 02 (Perqu\u00edn)","nombrePadre":"Moraz\u00e1n"},
	SV01051408: {"codigo":"SV01051408","nombre":"Zona 08 (Santo Tom\u00e1s)","nombrePadre":"San Salvador"},
	SV06021004: {"codigo":"SV06021004","nombre":"Zona 04 (Nahulingo)","nombrePadre":"Sonsonate"}
}

const base = 'SV';
const largoFinal = 10;
const largos: number[] = [ 2, 4, 6, 8, 10 ];
const divisiones: Record<string, DatosDivision> = {
    "2": {
        id: 1,
        largo: 2,
        nombre: "pa\u00eds",
        plural: "pa\u00edses",
        largoPadre: null,
        largoHijos: 4
    },
    "4": {
        id: 2,
        largo: 4,
        nombre: "departamento",
        plural: "departamentos",
        largoPadre: 2,
        largoHijos: 6
    },
    "6": {
        id: 3,
        largo: 6,
        nombre: "municipio",
        plural: "municipios",
        largoPadre: 4,
        largoHijos: 8
    },
    "8": {
        id: 4,
        largo: 8,
        nombre: "distrito",
        plural: "distritos",
        largoPadre: 6,
        largoHijos: 10
    },
    "10": {
        id: 5,
        largo: 10,
        nombre: "zona",
        plural: "zonas",
        largoPadre: 8,
        largoHijos: null
    }
};

const cyanRegions = {
	mapa,
	territorios,
	base,
	largoFinal,
	largos,
	divisiones
};

export { 
	mapa,
	territorios,
	base,
	largoFinal,
	largos,
	divisiones
 };
export default cyanRegions;

